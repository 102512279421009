import React from "react";

export const ShareX: React.FC<IconProps> = ({
	height = "40px",
	width = "40px",
	fillPrimary = "#FFC800",
	fillSecondary = "#222",
	invert,
	...props
}) => {
	return (
		// biome-ignore lint/a11y/noSvgWithoutTitle: Aria hidden is used to hide the svg from screen readers
		<svg
			aria-hidden="true"
			focusable="false"
			width={width}
			height={height}
			viewBox="0 0 40 40"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<g fill="none" fillRule="evenodd">
				<path
					d="M19.247.093C32.708-1.111 41.26 9.549 39.848 22.339c-.763 8.97-8.305 17.51-17.661 17.657-5.779.074-11.955-.96-16.257-5.122C.96 29.785-1.432 21.957.897 15.12 2.912 6.424 10.385.636 19.247.093"
					fill={invert ? fillSecondary : fillPrimary}
				/>
				<path
					d="m21.77 18.54 6.93-8.05h-1.64l-6.01 6.99-4.8-6.99h-5.54l7.26 10.57-7.26 8.44h1.64l6.35-7.38 5.07 7.38h5.54l-7.53-10.96Zm-2.25 2.61-.74-1.05-5.86-8.38h2.52l4.73 6.76.74 1.05 6.14 8.79h-2.52l-5.01-7.17Z"
					fill={invert ? fillPrimary : fillSecondary}
					fillRule="nonzero"
				/>
			</g>
		</svg>
	);
};

export default ShareX;

import React from "react";
import { DogSearchContextState } from "./DogSearchApi.types";

export const DogSearchApiContext = React.createContext<DogSearchContextState>({
	pagination: 0,
	filtersCount: 0,
	previousResultsPages: [],
	isListingPage: false,
	showFilters: "Initial",
	lastView: "None",
	resultsCount: 0,
	resultsUrls: [],
	selectedBreeds: [],
	userLocation: null,
	centreNames: {},
	centreLocations: [],
	centresInRange: [],
	selectedCentres: [],
	tempSelectedBreeds: [],
	selectedAges: [],
	selectedCounty: "",
	selectedSizes: [],
	selectedGenders: [],
	selectedLivesWith: [],
	selectedDayRange: "Any",
	currentDistance: 10,
	locationSearchTerm: "",
	addressSuggestions: [],
	postcodeSuggestions: [],
	breedList: [],
	results: [],
	breedSearchTerm: "",
	maintainQueryParams: false,
	dogApiLoading: false,
	queryStrings: "",
	isUnderdog: false,
	noReserved: false,
	useDogSearchQuery: () => { },
	safelyChangeDistance: () => { },
	getLivesWithDisabled: () => true,
	isFilterDisabled: () => true,

	onChangeFilterView: () => { },
	onResetFilters: () => { },
	onInitFilters: () => { },
	onSelectBreed: () => { },
	onSelectBreeds: () => { },
	onConfirmSelectedBreeds: () => { },
	onCancelSelectedBreeds: () => { },
	onUpdateSelectedCounty: () => { },
	onUpdateSelectedCentres: () => { },
	onUpdateCentre: () => { },
	onUpdateCounty: () => { },
	onUpdateAge: () => { },
	onUpdateSizes: () => { },
	onUpdateGenders: () => { },
	onUpdateLivesWith: () => { },
	onUpdateDayRange: () => { },
	onSearchBreedList: () => { },
	onShowDogs: () => () => { },
	onUpdateSort: () => { },
	onLoadMore: () => { },
	onUpdateTravelDistance: () => { },
	onUpdateLocation: () => { },
	onToggleIsUnderdog: () => { },
	onToggleNoReserved: () => { },
	sortBy: "NEW",
	searchListingsUrl: "/rehoming/dogs",
});

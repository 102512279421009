import React from "react";
import { Colours } from "../../../utils/colours";

export const Cross: React.FC<IconProps> = ({
	height = "20px",
	width = "20px",
	fill = Colours.MIDNIGHT,
	...props
}) => {
	return (
		// biome-ignore lint/a11y/noSvgWithoutTitle: Aria hidden is used to hide the svg from screen readers
		<svg
			aria-hidden="true"
			focusable="false"
			viewBox="0 0 490 490"
			height={height}
			width={width}
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				fill={fill}
				d="M456.851 0L245 212.564 33.149 0 .708 32.337l211.961 212.667L.708 457.678 33.149 490 245 277.443 456.851 490l32.441-32.322-211.961-212.674L489.292 32.337z"
			/>
		</svg>
	);
};

export default Cross;

import React from "react";

export const DropDown: React.FC<IconProps> = ({
	height = "32px",
	width = "32px",
	fill,
	...props
}) => {
	return (
		// biome-ignore lint/a11y/noSvgWithoutTitle: Aria hidden is used to hide the svg from screen readers
		<svg
			aria-hidden="true"
			focusable="false"
			width={width}
			height={height}
			viewBox="0 0 26 26"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<defs>
				<path
					d="M12.65 16.281a.6.6 0 00.437-.187l4.031-4.032c.188-.187.23-.411.125-.671-.104-.26-.291-.391-.562-.391H8.618c-.27 0-.458.13-.562.39-.104.261-.063.485.125.672l4.031 4.032a.6.6 0 00.438.187z"
					id="icon_dropdown_svg__a"
				/>
			</defs>
			<g fill="none" fillRule="evenodd">
				<circle stroke="#DDD" cx={13} cy={13} r={12} />
				<use fill={fill} fillRule="nonzero" xlinkHref="#icon_dropdown_svg__a" />
			</g>
		</svg>
	);
};

export default DropDown;

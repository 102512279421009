import cx from "classnames";
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/unbound-method */
import React, { HTMLAttributes, useEffect, useState } from "react";

import { useFormContext, useFormState } from "react-hook-form";
import * as styles from "./FormTextArea.module.scss";

interface Props<T = HTMLInputElement> extends HTMLAttributes<T> {
	label?: string;
	name: string;
}

export interface FormTextAreaProps extends Props<HTMLTextAreaElement> {
	errorMessage?: string;
}

export const FormTextArea: React.FC<FormTextAreaProps> = ({
	className,
	label,
	name,
	placeholder,
	...rest
}) => {
	const {
		register,
		formState: { isDirty, dirtyFields },
	} = useFormContext();
	const { errors } = useFormState();
	register(name);
	const [error, setError] = useState(dirtyFields[name]);
	useEffect(() => {
		if (isDirty) setError(errors[name]);
	}, [isDirty, errors, name]);
	return (
		<>
			<div className={cx([styles.textareawrapper, className])}>
				{label && (
					<label htmlFor={name} className={styles.textarealabel}>
						{label}
					</label>
				)}
				<textarea
					id={name}
					name={name}
					className={styles.textarea}
					placeholder={placeholder}
					{...register(name)}
					{...rest}
					aria-invalid={!!error}
					aria-describedby={`text-area-${name}`}
				/>
			</div>
			{!!error && (
				<p id={`text-area-${name}`} className={styles.errormessage}>
					{error.message}
				</p>
			)}
		</>
	);
};

export default FormTextArea;

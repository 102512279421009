import React from "react";

export const HeartLarge: React.FC<IconProps> = ({
	height = "26px",
	width = "26px",
	...props
}) => {
	return (
		// biome-ignore lint/a11y/noSvgWithoutTitle: Aria hidden is used to hide the svg from screen readers
		<svg
			aria-hidden="true"
			focusable="false"
			width={width}
			height={height}
			viewBox="0 0 32 29"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<style>{".icon_heart_large_svg__st0{fill:none}"}</style>
			<path
				d="m27.2 14.4 1.6 1.5-1.1-1zM14.5 4l.8-.7-.8.7zM15.9 26.4h.1-.1zM15.9 26.4h-.1.1zM27.2 4.8c-2.5-2.6-6.6-2.6-9.1 0l-1.4 1.5c-.2.2-.5.3-.8.3s-.6-.1-.8-.3l-1.4-1.5c-1.3-1.3-2.9-2-4.6-2-1.7 0-3.3.6-4.6 2-2.5 2.7-2.5 7 0 9.6l11.3 11.9c3.8-4 7.5-7.9 11.3-11.9 2.6-2.7 2.6-7 .1-9.6zM3.8 15.1l.8-.7-.8.7z"
				className="icon_heart_large_svg__st0"
			/>
			<path
				d="m16.7 6.2-.8-.7-.8.7.8-.7zM15.9 26.4l.7.7zM15.9 26.4zM15.9 26.4z"
				className="icon_heart_large_svg__st0"
			/>
			<path
				d="M15.9 26.4h0"
				style={{
					fill: "none",
					stroke: "#000",
					strokeWidth: 0,
					strokeLinecap: "round",
					strokeLinejoin: "round",
				}}
			/>
			<path
				d="M27.2 4.8c-2.5-2.6-6.6-2.6-9.1 0l-1.4 1.5c-.2.2-.5.3-.8.3s-.6-.1-.8-.3l-1.4-1.5c-1.3-1.3-2.9-2-4.6-2-1.7 0-3.3.6-4.6 2-2.5 2.7-2.5 7 0 9.6l11.3 11.9c3.8-4 7.8-8.3 11.6-12.2 2.3-2.7 2.2-6.8-.2-9.3zM14.5 4l.8-.7-.8.7z"
				className="icon_heart_large_svg__st0"
			/>
			<path d="M15.3 3.3C13.6 1.5 11.4.7 9.2.7 6.9.6 4.7 1.5 3 3.3-.3 6.8-.3 12.4 3 15.9l11.4 12c.4.4.9.6 1.5.6s1.1-.2 1.5-.6l11.4-12c3.3-3.5 3.3-9.1 0-12.6-3.4-3.5-8.9-3.5-12.3 0l-.6.6-.6-.6m12.2 10.8c-3.8 4-7.8 8.3-11.6 12.2L4.6 14.4c-2.5-2.7-2.5-7 0-9.6 1.3-1.3 2.9-2 4.6-2 1.7 0 3.3.6 4.6 2l1.4 1.5c.2.2.5.3.8.3s.6-.1.8-.3l1.4-1.5c2.5-2.6 6.6-2.6 9.1 0 2.3 2.5 2.4 6.6.2 9.3zM15.9 26.4z" />
		</svg>
	);
};

export default HeartLarge;

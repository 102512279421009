import { useCallback } from "react";
import { useMyAccount } from "../context";

export const useFavouriteDog = (dog: ESDocDog | null) => {
	const { handleAddToFavourites, handleRemoveFromFavourites, profile } =
		useMyAccount();
	const onClick = useCallback(
		// biome-ignore  lint/suspicious/noExplicitAny:
		async (data: any) => {
			const isFav = profile?.favourites
				?.map((favDog) => favDog?.id)
				.includes(dog?.id);
			if (isFav) {
				handleRemoveFromFavourites(dog, data);
			} else {
				handleAddToFavourites(dog, data);
				window.dispatchEvent(new Event("favourite"));
			}
		},
		[
			dog,
			handleAddToFavourites,
			handleRemoveFromFavourites,
			profile?.favourites,
		],
	);

	return {
		onClick,
		isFav:
			profile.favourites.findIndex((favDog) => favDog?.id === dog?.id) > -1,
	};
};

import React from "react";
import { withSafeRotateSvg } from "../../hoc/withSafeRotateSvg";

const FuzzyBorderFullWidth: React.FC<CurveProps> = ({ fill = "#FFC800" }) => {
	return (
		// biome-ignore lint/a11y/noSvgWithoutTitle: aria-hidden="true" is used to hide the svg from screen readers
		<svg
			aria-hidden="true"
			focusable="false"
			viewBox={"0 0 1920 230"}
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M787.226 175.732c-2.623 4.686-6.11 8.717-10.571 12.578-7.058 6.105-22.252 16.85-29.104 19.93a3403.81 3403.81 0 0 0 20.049 2.443c41.636 4.393 81.1 6.48 119.831 6.169 116.189-.936 311.21-29.728 363.626-43.868-4.63 15.145-14.387 29.638-32.703 46.186 61.493-13.194 180.688-60.883 195.607-74.032-1.313 20.606-7.583 33.505-9.077 36.316-.105.196.138.383.362.283 8.751-3.825 99.02-43.61 123.62-67.74 2.195 13.982.245 25.132-.189 27.31-.029.165.174.29.355.22 61.214-23.463 117.346-52.191 153.693-80.044.007-.006.018-.003.021.003 1.777 5.377 2.594 11.102 2.923 17.004 12.214-8.396 21.435-15.809 25.92-21.485.117-.145.366-.148.5-.012a46.584 46.584 0 0 1 5.835 7.177c2.703-5.996 5.923-14.728 4.54-19.852l.004-.003c6.686.236 13.078 7.319 16.012 11.658.738-4.913 1.324-9.804 1.172-12.162-.007-.103.112-.172.228-.139 3.625 1.005 6.345 4.571 8.342 8.683C1816.025 24.466 1873.408 8.286 1920 2v228H0V73.75c107.358 13.089 392.9 78.71 742.96 99.533 17.289 1.108 32.089 1.933 44.266 2.449Z"
				fill={fill}
				fillRule="evenodd"
			/>
		</svg>
	);
};

export default withSafeRotateSvg<CurveProps>(FuzzyBorderFullWidth);

export type ImageSize =
	| "1440x1080"
	| "1080x810"
	| "800x600"
	| "640x360"
	| "600x450"
	| "400x300"
	| "160x120"
	| "120x90";

export type IconSize = "200x200" | "80x80" | "40x40";

export const getImagePath = (
	dimensions: ImageSize | IconSize,
	imagePath: string,
) => {
	// Some niche cases (e.g. sending images to HMD via cookie) require the
	// dimensions to be prepended to the image path early.
	// So if that's the case, all this function needs to add is the baseUrl
	if (imagePath?.startsWith(`/${dimensions}`)) {
		return process.env.GATSBY_IMAGES_BASE_URL + imagePath;
	}

	return `${process.env.GATSBY_IMAGES_BASE_URL}/${dimensions}/assets${encodeURI(
		imagePath,
	)}`;
};

export const getDogImagePath = (
	dimensions: ImageSize | IconSize,
	imagePath: string,
) =>
	`${process.env.GATSBY_IMAGES_BASE_URL}/${dimensions}/${encodeURI(imagePath)}`;

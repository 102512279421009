import React from "react";
import { Colours } from "../../../utils/colours";

export const Breed: React.FC<IconProps> = ({
	height = "30px",
	width = "30px",
	fill = Colours.MIDNIGHT,
	...props
}) => {
	return (
		// biome-ignore lint/a11y/noSvgWithoutTitle: Aria hidden is used to hide the svg from screen readers
		<svg
			aria-hidden="true"
			focusable="false"
			viewBox="0 0 30 30"
			height={height}
			width={width}
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M29.063 4.75h-3.75l-.42-.838c-.284-.573-1.038-1.037-1.677-1.037h-4.08l-1.598-1.598a.938.938 0 00-1.6.66V12.25H9.375a5.597 5.597 0 00-3.102.938H4.688a2.815 2.815 0 01-2.813-2.813.937.937 0 00-1.875 0 4.684 4.684 0 004.54 4.672 5.571 5.571 0 00-.79 2.828v9.375c0 1.036.84 1.875 1.875 1.875h3.75c1.036 0 1.875-.84 1.875-1.875v-5.625h5.625v5.625c0 1.036.84 1.875 1.875 1.875h3.75c1.036 0 1.875-.84 1.875-1.875V13.188h1.875A3.75 3.75 0 0030 9.438v-3.75a.937.937 0 00-.938-.938zM22.5 27.25h-3.75v-7.5H9.375v7.5h-3.75v-9.375a3.754 3.754 0 013.75-3.75h6.918l6.207 1.552V27.25zm5.625-17.812c0 1.035-.84 1.875-1.875 1.875H22.5v2.432l-4.688-1.172v-8.37l.548.547h4.856l.938 1.875h3.971v2.813zM22.5 6.625a.937.937 0 11-1.875 0 .937.937 0 011.875 0z"
				fill={fill}
				fillRule="nonzero"
			/>
		</svg>
	);
};

export default Breed;

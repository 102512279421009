import React from "react";

export const StepDog: React.FC<IconProps> = ({
	height = "48px",
	width = "48px",
	fillPrimary = "#fff",
	fillSecondary = "#222",
	invert,
	...props
}) => {
	return (
		// biome-ignore lint/a11y/noSvgWithoutTitle: Aria hidden is used to hide the svg from screen readers
		<svg
			aria-hidden="true"
			focusable="false"
			width={width}
			height={height}
			viewBox="0 0 48 48"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				fill={invert ? fillSecondary : fillPrimary}
				fillRule="evenodd"
				d="M23.097.112C39.25-1.333 49.51 11.459 47.818 26.806c-.916 10.764-9.966 21.013-21.194 21.19-6.934.088-14.346-1.153-19.508-6.148-5.965-6.106-8.834-15.5-6.04-23.704C3.494 7.708 12.462.764 23.096.111"
				clipRule="evenodd"
			/>
			<path
				fill={invert ? fillPrimary : fillSecondary}
				fillRule="evenodd"
				d="M12.81 11.171 24 19.535v1.215h-2.46l-10.227-7.655a.813.813 0 0 1-.142-1.14l.499-.641a.813.813 0 0 1 1.14-.143Zm3.065 11.204H24V30.5h-4.875v5.688a.813.813 0 0 1-.812.812h-3.25a.813.813 0 0 1-.813-.813V25.325c-1.887-.672-3.25-2.46-3.25-4.575a1.625 1.625 0 1 1 3.25 0c0 .897.728 1.625 1.625 1.625Zm17.875-.813a3.25 3.25 0 0 0 3.25-3.25v-1.625a.813.813 0 0 0-.813-.812h-3.25l-.363-.727c-.248-.496-.899-.898-1.453-.898h-2.723l-1.386-1.387a.813.813 0 0 0-1.387.574v7.611l6.5 2.321v-1.806h1.625Zm-8.125 1.211 6.5 2.321v11.093a.812.812 0 0 1-.813.813h-3.25a.813.813 0 0 1-.812-.813V30.5h-1.625v-7.727Zm4.875-6.898a.812.812 0 1 1 0 1.625.812.812 0 0 1 0-1.625Z"
				clipRule="evenodd"
			/>
		</svg>
	);
};

export default StepDog;

import React from "react";
export const ResetPassword: React.FC<IconProps> = ({
	height = "26px",
	width = "26px",
	fill = "#ffc800",
	...props
}) => {
	return (
		// biome-ignore lint/a11y/noSvgWithoutTitle: Aria hidden is used to hide the svg from screen readers
		<svg
			aria-hidden="true"
			focusable="false"
			width={width}
			height={height}
			viewBox="0 0 100 100"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<style>
				{`.icon_reset_password_svg__st0{fill:${fill}}.icon_reset_password_svg__st2{fill:#222}`}
			</style>
			<path
				d="M58.1 40.4H26.7v27.8H55v-.7c0-5.2 2.5-9.9 6.3-12.9v-11c0-1.8-1.4-3.2-3.2-3.2z"
				className="icon_reset_password_svg__st0"
			/>
			<path
				d="M45 49.9c-.1 0-.1 0-.1.1s.2.1.2 0 0-.1-.1-.1z"
				style={{
					fill: "none",
				}}
			/>
			<path
				d="M47.2 50c0-1.2-1-2.2-2.2-2.2s-2.2 1-2.2 2.2c0 .8.5 1.5 1.1 1.9v-.8c0-.6.5-1 1-1s1 .5 1 1v.8c.8-.4 1.3-1.1 1.3-1.9z"
				className="icon_reset_password_svg__st2"
			/>
			<path
				d="M45 50.1c-.6 0-1 .5-1 1V58c0 .6.5 1 1 1s1-.5 1-1V51.1c.1-.6-.4-1-1-1zM57.5 38.5h-25c-.6 0-1-.5-1-1v-8C31.5 22.1 37.6 16 45 16c7.5 0 13.5 6.1 13.5 13.5v8c.1.5-.4 1-1 1zm-23.9-2.1h22.9v-6.9C56.5 23.2 51.4 18 45 18c-6.3 0-11.5 5.1-11.5 11.5v6.9z"
				className="icon_reset_password_svg__st2"
			/>
			<path
				d="M25.7 70.3c-.6 0-1-.5-1-1V40.8c-1 .5-1.6 1.5-1.6 2.7v25c0 1.7 1.4 3.1 3.1 3.1h29.3c-.1-.4-.2-.9-.3-1.4H25.7z"
				className="icon_reset_password_svg__st0"
			/>
			<path
				d="M25.7 70.3h29.5c-.1-.7-.2-1.3-.2-2H26.7V38.5h36.6v14.7c.7-.4 1.3-.7 2.1-1V37.5c0-.6-.5-1-1-1H25.7c-.6 0-1 .5-1 1V69.3c0 .6.5 1 1 1z"
				className="icon_reset_password_svg__st2"
			/>
			<path
				d="M61 71.6c-.4 0-.7-.2-.9-.5L56.7 66c-.3-.5-.2-1.1.3-1.4.5-.3 1.1-.2 1.4.3l2.7 4 3.1-3.7c.4-.4 1-.5 1.4-.1.4.4.5 1 .1 1.4l-3.9 4.7c-.2.3-.5.4-.8.4zM85.2 71.8c-.3 0-.7-.2-.9-.5l-2.7-3.9-3.1 3.7c-.4.4-1 .5-1.4.1-.4-.4-.5-1-.1-1.4l3.9-4.7c.2-.2.5-.4.8-.4.3 0 .6.2.8.5l3.4 5.1c.3.5.2 1.1-.3 1.4 0 .1-.2.1-.4.1z"
				className="icon_reset_password_svg__st2"
			/>
			<path
				d="M71.4 79c-3 0-5.8-1.2-8-3.3-.4-.4-.4-1 0-1.5.4-.4 1-.4 1.5 0 2.3 2.3 5.6 3.2 8.8 2.5 4.8-1.1 8.1-6 7.2-10.7-.1-.6.3-1.1.8-1.2.6-.1 1.1.3 1.2.8 1.1 5.8-2.8 11.7-8.7 13.1-1 .2-1.9.3-2.8.3zM61 71.6c-.5 0-.9-.3-1-.8-.8-3-.4-6.2 1.3-8.9 1.7-2.8 4.4-4.8 7.5-5.6 4.1-1 8.4.4 11.2 3.6.4.4.3 1.1-.1 1.4-.4.4-1.1.3-1.4-.1-2.3-2.6-5.8-3.7-9.2-2.9-2.6.7-4.9 2.4-6.3 4.7-1.3 2.2-1.7 4.8-1 7.3.1.5-.2 1.1-.7 1.3H61z"
				className="icon_reset_password_svg__st2"
			/>
			<circle
				cx={45}
				cy={50}
				r={2.2}
				className="icon_reset_password_svg__st2"
			/>
		</svg>
	);
};

export default ResetPassword;

import cx from "classnames";
/* eslint-disable @typescript-eslint/unbound-method */
import React, { HTMLProps } from "react";

import { useFormContext } from "react-hook-form";
import * as styles from "./FormCheckbox.module.scss";

export interface FormCheckboxProps extends HTMLProps<HTMLInputElement> {
	label: string;
	value: string;
	name: string;
	defaultChecked?: boolean;
	labelClassName?: string;
}

export const FormCheckbox: React.FC<FormCheckboxProps> = ({
	className,
	labelClassName,
	label,
	name,
	value,
	...rest
}) => {
	const { register } = useFormContext();

	return (
		<div key={value} className={cx([styles.checkboxwrapper, className])}>
			<input
				ref={register}
				id={value}
				name={name}
				type="checkbox"
				value={value}
				{...register(name)}
				{...rest}
			/>
			<label htmlFor={value} className={cx(styles.checklabel, labelClassName)}>
				{label}
			</label>
		</div>
	);
};

export default FormCheckbox;

import React from "react";

export const StepLiving: React.FC<IconProps> = ({
	height = "48px",
	width = "48px",
	fillPrimary = "#fff",
	fillSecondary = "#222",
	invert,
	...props
}) => {
	return (
		// biome-ignore lint/a11y/noSvgWithoutTitle: Aria hidden is used to hide the svg from screen readers
		<svg
			aria-hidden="true"
			focusable="false"
			width={width}
			height={height}
			viewBox="0 0 48 48"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				fill={invert ? fillSecondary : fillPrimary}
				fillRule="evenodd"
				d="M23.097.112C39.25-1.333 49.51 11.459 47.818 26.806c-.916 10.764-9.966 21.013-21.194 21.19-6.934.088-14.346-1.153-19.508-6.148-5.965-6.106-8.834-15.5-6.04-23.704C3.494 7.708 12.462.764 23.096.111"
				clipRule="evenodd"
			/>
			<path
				fill={invert ? fillPrimary : fillSecondary}
				d="M36.893 24.375c0 .809-.674 1.438-1.438 1.438h-1.437L34.062 33c0 1.617-1.257 2.92-2.874 2.92H16.813a2.866 2.866 0 0 1-2.875-2.875v-7.233H12.5a1.42 1.42 0 0 1-1.438-1.437c0-.404.135-.764.45-1.078l11.5-10.063c.314-.314.674-.359.988-.359.314 0 .674.09.943.315l11.455 10.107c.36.314.54.674.495 1.078Zm-10.018-1.438c0-.988-.584-1.931-1.438-2.47-.898-.494-2.021-.494-2.875 0-.898.539-1.437 1.482-1.437 2.47 0 1.034.54 1.977 1.438 2.516.853.494 1.976.494 2.875 0 .853-.539 1.437-1.482 1.437-2.515Zm-4.313 4.313a3.576 3.576 0 0 0-3.593 3.594.71.71 0 0 0 .718.718h8.625c.36 0 .72-.314.72-.718a3.604 3.604 0 0 0-3.595-3.594h-2.875Z"
			/>
		</svg>
	);
};

export default StepLiving;

import React from "react";

export const SocialTikTok: React.FC<IconProps> = ({
	height = "45px",
	width = "45px",
	fillPrimary = "#FFC800",
	fillSecondary = "#222",
	...props
}) => {
	return (
		// biome-ignore lint/a11y/noSvgWithoutTitle: Aria hidden is used to hide the svg from screen readers
		<svg
			aria-hidden="true"
			focusable="false"
			width={width}
			height={height}
			viewBox="0 0 100 100"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<defs>
				<clipPath id="icon_tiktok_svg__a">
					<circle
						cx={50}
						cy={50}
						r={45}
						style={{
							fill: "none",
						}}
					/>
				</clipPath>
			</defs>
			<g
				style={{
					clipPath: "url(#icon_tiktok_svg__a)",
				}}
			>
				<path
					style={{
						fill: fillPrimary,
					}}
					d="M2.75 2.75h94.5v94.5H2.75z"
				/>
			</g>
			<path
				d="m51.02 22.45 9.1.2c-.05 3.85 1.03 7.31 3.59 10.11 2.65 2.9 6.27 3.43 10.01 3.53v9.54c-4.87.2-9.37-1.05-13.59-3.55-.03.13-.07.23-.07.33.02 5.49.03 10.98.08 16.48.04 4.04-1.02 7.75-3.42 11.01-2.5 3.4-5.89 5.49-9.99 6.34-5.48 1.13-10.57.16-15.03-3.33-3.37-2.64-5.24-6.19-6.04-10.37-.62-3.26-.44-6.46.86-9.52 2.49-5.85 6.89-9.43 13.12-10.73 1.84-.38 3.71-.37 5.57-.09.16.02.4.25.4.39.02 2.9.01 5.8 0 8.7 0 .05-.03.11-.05.16-.7-.12-1.38-.27-2.07-.34-4.22-.45-8.33 2.66-8.91 6.86-.54 3.88 1.07 7.44 4.79 9 3.52 1.48 7.75.46 10.07-2.48 1.26-1.6 1.58-3.49 1.58-5.48V22.45Z"
				style={{
					fill: fillSecondary,
				}}
			/>
		</svg>
	);
};

export default SocialTikTok;

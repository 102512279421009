import React from "react";
import { Colours } from "../../../utils/colours";

export const Age: React.FC<IconProps> = ({
	height = "30px",
	width = "30px",
	fill = Colours.MIDNIGHT,
	...props
}) => {
	return (
		// biome-ignore lint/a11y/noSvgWithoutTitle: Aria hidden is used to hide the svg from screen readers
		<svg
			aria-hidden="true"
			focusable="false"
			viewBox="0 0 30 30"
			height={height}
			width={width}
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M7.571 5.625A1.86 1.86 0 015.714 3.75c0-1.816 1.857-1.348 1.857-3.75.697 0 1.858 1.729 1.858 3.281 0 1.553-.827 2.344-1.858 2.344zm7.429 0a1.86 1.86 0 01-1.857-1.875C13.143 1.934 15 2.402 15 0c.696 0 1.857 1.729 1.857 3.281 0 1.553-.827 2.344-1.857 2.344zm7.429 0A1.86 1.86 0 0120.57 3.75c0-1.816 1.858-1.348 1.858-3.75.696 0 1.857 1.729 1.857 3.281 0 1.553-.827 2.344-1.857 2.344zM25.214 15h-1.857V6.562H21.5V15h-5.571V6.562H14.07V15H8.5V6.562H6.643V15H4.786C3.248 15 2 16.26 2 17.813V30h26V17.812C28 16.26 26.752 15 25.214 15zm.929 13.125H3.857v-4.221C4.8 23.348 5.246 22.5 6.338 22.5c1.622 0 1.814 1.875 4.338 1.875 2.487 0 2.74-1.875 4.324-1.875 1.634 0 1.81 1.875 4.338 1.875 2.516 0 2.714-1.875 4.338-1.875 1.073 0 1.523.848 2.467 1.404v4.221zm0-6.584c-.559-.462-1.231-.916-2.467-.916-2.52 0-2.717 1.875-4.338 1.875-1.607 0-1.825-1.875-4.338-1.875-2.486 0-2.74 1.875-4.324 1.875-1.633 0-1.81-1.875-4.338-1.875-1.245 0-1.92.456-2.48.917v-3.73c0-.516.416-.937.928-.937h20.428c.512 0 .929.42.929.938v3.728z"
				fill={fill}
				fillRule="nonzero"
			/>
		</svg>
	);
};

export default Age;

import { isInRangeFilter } from "@dogstrust/src/utils/distance";
import type { CmsMapAction } from "./CmsMap.actions";
import { CmsMapState } from "./CmsMap.types";

export const cmsMapLocationsDefaultState: CmsMapState = {
	cmsMapLocationsLoaded: false,
	selectedCmsMapName: "",
	cmsMapLocationsLocations: [],
	cmsMapLocationsInRange: [],
	addressSuggestions: [],
	sortBy: "A",
	pagination: 0,
	currentDistance: 1000,
	userLocation: null,
	locationSearchTerm: "",
	distanceToCmsMap: {},
};
export function cmsMapLocationsReducer(
	state: CmsMapState,
	action: CmsMapAction,
): CmsMapState {
	switch (action.type) {
		case "LOAD_ALL_CMS_MAP_LOCATIONS":
			if (state.cmsMapLocationsLoaded) return state;
			return {
				...state,
				cmsMapLocationsLoaded: true,
				pagination: 0,
				cmsMapLocationsLocations: [...action.allCmsMap],
				cmsMapLocationsInRange: [
					...action.allCmsMap.filter((card) => {
						if (card.field_date) {
							const cardDate = new Date(card.field_date);
							const today = new Date();
							return (
								cardDate >= today ||
								cardDate.toDateString() === today.toDateString()
							);
						}
						// If field_date doesn't exist, include the card
						return true;
					}),
				],
			};
		case "SELECT_CMS_MAP_LOCATION":
			return {
				...state,
				selectedCmsMapName:
					state.selectedCmsMapName === action.cmsMapLocationName
						? ""
						: action.cmsMapLocationName,
			};
		case "UPDATE_SORT_OPTION":
			return {
				...state,
				pagination: 0,
				sortBy: action.selectedOption,
			};

		case "LOAD_MORE":
			return {
				...state,
				pagination: state.pagination + 1,
			};

		case "UPDATE_TRAVEL_DISTANCE": {
			const shopsInRange1 = state.cmsMapLocationsLocations.filter(
				(shop) =>
					!state.userLocation ||
					isInRangeFilter(
						{
							latitude: parseFloat(shop.field_latitude),
							longitude: parseFloat(shop.field_longitude),
							address: "",
						},
						state.userLocation,
						action.distance,
					),
			);

			return {
				...state,
				pagination: 0, // Reset pagination as results will change
				cmsMapLocationsInRange: [...shopsInRange1],
				currentDistance: action.distance,
				userLocation: {
					...state.userLocation,
				},
			};
		}

		case "UPDATE_LOCATION": {
			const shopsInRange2 = state.cmsMapLocationsLocations.filter(
				(shop) =>
					!action.userLocation ||
					isInRangeFilter(
						{
							latitude: parseFloat(shop.field_latitude),
							longitude: parseFloat(shop.field_longitude),
							address: "",
						},
						action.userLocation,
						action.distance,
					),
			);
			return {
				...state,
				pagination: 0, // Reset pagination as results will change
				cmsMapLocationsInRange: [...shopsInRange2],
				userLocation: action.userLocation,
				currentDistance: action.distance,
			};
		}

		case "UPDATE_LOCATION_SUGGESTIONS":
			return {
				...state,
				addressSuggestions: action.locations || [],
			};

		case "UPDATE_CMS_MAP_LOCATIONS_IN_RANGE":
			return {
				...state,
				cmsMapLocationsInRange: action.cmsMapLocationsInRange,
				distanceToCmsMap: action.distanceToCmsMap,
				selectedCmsMapName: action.cmsMapLocationsInRange
					.map((cmsMapLocation) => cmsMapLocation.title)
					.includes(state.selectedCmsMapName)
					? state.selectedCmsMapName
					: "",
			};
		default:
			return state;
	}
}

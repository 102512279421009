import React from "react";

export const ShareEmail: React.FC<IconProps> = ({
	height = "40px",
	width = "40px",
	fillPrimary = "#FFC800",
	fillSecondary = "#222",
	invert,
	...props
}) => {
	return (
		// biome-ignore lint/a11y/noSvgWithoutTitle: Aria hidden is used to hide the svg from screen readers
		<svg
			aria-hidden="true"
			focusable="false"
			width={width}
			height={height}
			viewBox="0 0 40 40"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<g fill="none" fillRule="evenodd">
				<path
					d="M19.247.093C32.708-1.111 41.26 9.549 39.848 22.339c-.763 8.97-8.305 17.51-17.661 17.657-5.779.074-11.955-.96-16.257-5.122C.96 29.785-1.432 21.957.897 15.12 2.912 6.424 10.385.636 19.247.093"
					fill={invert ? fillSecondary : fillPrimary}
				/>
				<path
					d="M20 23c.339 0 .755-.156 1.25-.469.286-.156.69-.442 1.21-.86l.392-.273c3.28-2.37 5.481-3.997 6.601-4.882l.195-.157a.89.89 0 00.352-.742v-.742c0-.52-.182-.964-.547-1.328A1.808 1.808 0 0028.125 13h-16.25c-.52 0-.964.182-1.328.547A1.808 1.808 0 0010 14.875v.742c0 .313.117.56.352.742l.273.196c1.146.885 3.32 2.5 6.523 4.843l.391.274c.52.417.925.703 1.211.86.495.312.911.468 1.25.468zm8.125 5c.52 0 .963-.182 1.328-.547.365-.364.547-.807.547-1.328v-7.969a.253.253 0 00-.137-.234c-.09-.052-.176-.04-.254.039-.937.73-2.942 2.2-6.015 4.414l-.39.313c-.6.468-1.095.807-1.485 1.015-.625.365-1.198.547-1.719.547-.52 0-1.094-.17-1.719-.508-.364-.208-.86-.547-1.484-1.015l-.39-.352c-3.152-2.292-5.157-3.763-6.016-4.414a.252.252 0 00-.254-.02c-.091.04-.137.111-.137.215v7.969c0 .52.182.964.547 1.328.364.365.807.547 1.328.547h16.25z"
					fill={invert ? fillPrimary : fillSecondary}
					fillRule="nonzero"
				/>
			</g>
		</svg>
	);
};

export default ShareEmail;

import React from "react";
import { Colours } from "../../../utils/colours";

export const Shedding: React.FC<IconProps> = ({
	height = "30px",
	width = "30px",
	fill = Colours.MIDNIGHT,
	...props
}) => {
	return (
		// biome-ignore lint/a11y/noSvgWithoutTitle: Aria hidden is used to hide the svg from screen readers
		<svg
			aria-hidden="true"
			focusable="false"
			viewBox="0 0 30 30"
			height={height}
			width={width}
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M10.16.603C8.412.701 5.9 2.473 4.188 4.185c-.952.952-3.144 3.384-2.96 5.434.085.956 1.765 2.174 2.483 2.653a.567.567 0 0 0 .715-.074L10.025 6.6l1.365 1.366-5.598 5.599a.566.566 0 0 0-.004.8c.22.22.578.217.8-.004l5.598-5.6 1.374 1.374-5.6 5.599a.566.566 0 0 0-.003.8c.22.22.578.218.8-.004l5.599-5.599 1.373 1.374-5.599 5.598a.566.566 0 0 0-.003.8c.22.22.578.218.8-.004l5.598-5.599 1.374 1.374-5.599 5.6a.566.566 0 0 0-.004.799c.22.22.578.218.8-.004l5.599-5.599 1.374 1.374-5.6 5.599a.566.566 0 0 0-.003.8c.22.22.578.217.8-.004l5.598-5.6 1.373 1.374-5.598 5.599a.566.566 0 0 0-.004.8c.22.22.578.217.8-.004l5.598-5.6 1.366 1.367-5.599 5.598a.567.567 0 0 0-.073.716c.48.718 1.698 2.398 2.653 2.483 2.05.184 4.484-2.009 5.436-2.96 1.712-1.713 3.483-4.224 3.58-5.973.017-.303.04-.718-2.99-4.062-1.681-1.856-3.974-4.245-6.457-6.727-2.482-2.483-4.871-4.776-6.727-6.457C10.878.563 10.463.586 10.16.603Zm19.102 20.248c-.11 1.227-1.46 3.382-3.242 5.165-1.718 1.718-3.453 2.725-4.523 2.63-.26-.05-.954-.777-1.563-1.614l5.663-5.663a.566.566 0 0 0 .004-.8L10.43 5.398a.566.566 0 0 0-.8.004l-5.663 5.664c-.838-.61-1.564-1.303-1.614-1.558-.096-1.074.911-2.809 2.63-4.526V4.98c1.783-1.783 3.937-3.133 5.164-3.243C12.088 2.5 28.5 18.91 29.262 20.85Z"
				fill={fill}
				fillRule="nonzero"
				stroke="#000"
				strokeWidth={0.5}
			/>
		</svg>
	);
};

export default Shedding;

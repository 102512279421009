import {
	EventDogData,
	FavouriteOption,
} from "@dogstrust/src/components/FavouriteIcon/FavouriteIcon";
import FavouritesModal from "@dogstrust/src/components/FavouritesModal/FavouritesModal";
import { useFavouriteDog } from "@dogstrust/src/hooks/useFavouriteDog";
import { setFavDogCreateAccCookie } from "@dogstrust/src/utils/cookies";
import React, { useState } from "react";
import { FavouritesManagerContext } from "./FavouritesManager.context";

const FavouritesManagerProvider: React.FC<PropsWithChildren> = ({
	children,
}) => {
	const [modalState, setModalState] = useState<FavouriteOption | "closed">(
		"closed",
	);
	const [favouriteDog, setFavouriteDog] = useState<ESDocDog | null>(null);
	const [eventDogData, setEventDogData] = useState<EventDogData | null>(null);

	const { onClick } = useFavouriteDog(favouriteDog);

	const onFaveClick = (
		eventDog: EventDogData,
		option: FavouriteOption,
		favouriteDog: ESDocDog,
	) => {
		setModalState(option);
		setEventDogData(eventDog);
		setFavouriteDog(favouriteDog);
	};
	return (
		<FavouritesManagerContext.Provider value={{ onFaveClick }}>
			{children}
			<FavouritesModal
				modalState={modalState}
				favouriteDog={favouriteDog}
				onClickCancelRemoveDog={() => {
					// Close the modal
					setModalState("closed");

					// Clear the current dog we are dealing with
					setFavouriteDog(null);
					setEventDogData(null);
				}}
				onClickRemoveDog={() => {
					setModalState("closed");

					// clear the current dog we are dealing with
					setFavouriteDog(null);
					setEventDogData(null);

					// Remove the dog from the favourites list
					onClick(eventDogData);
				}}
				onClickAddDog={() => {
					setFavDogCreateAccCookie();
					setModalState("closed");
					setFavouriteDog(null);
					setEventDogData(null);
					onClick(eventDogData);
				}}
			/>
		</FavouritesManagerContext.Provider>
	);
};

export default FavouritesManagerProvider;

import React, {
	useCallback,
	useEffect,
	useMemo,
	useReducer,
	useRef,
	useState,
} from "react";
import {
	onCancelSelectedBreedsAction,
	onChangeFilterViewAction,
	onConfirmSelectedBreedsAction,
	onInitFiltersAction,
	onLoadMoreAction,
	onResetFiltersAction,
	onSearchBreedListAction,
	onSelectBreedAction,
	onSelectBreedsAction,
	onToggleIsUnderdogAction,
	onToggleNoReservedAction,
	onUpdateFilterOptionAction,
	onUpdateLocationAction,
	onUpdateSelectedCountyAction,
	onUpdateSortOptionAction,
	onUpdateTravelDistanceAction,
} from "./DogSearchApi.actions";
import {
	dogSearchDefaultState,
	dogSearchReducer,
} from "./DogSearchApi.reducer";
import {
	FilterDisplayState,
	FilterType,
	SortOption,
} from "./DogSearchApi.types";

import { useLazyQuery } from "@apollo/client";
import { dogSearchClient } from "@dogstrust/src/apollo/client";
import { manuallyCleanParamsFromUrl } from "@dogstrust/src/utils/google";
import qs from "qs";
import { SEARCH_DOG_QUERY } from "../../apollo/queries";
import { DogSearchApiContext } from "./DogSearchApi.context";
import { DISTANCE_OPTIONS } from "./DogSearchApi.fixtures";
import { encryptData } from "./DogSearchApi.utils";

export const DogSearchApiProvider: React.FC<PropsWithChildren> = ({
	children,
}) => {
	const [usingDogSearchQuery, setUsingDogSearchQuery] = useState(false);
	const useDogSearchQuery = () => setUsingDogSearchQuery(true);
	const [
		{
			pagination,
			previousResultsPages,
			selectedBreeds,
			selectedCentres,
			selectedCounty,
			tempSelectedBreeds,
			selectedAges,
			selectedSizes,
			selectedGenders,
			selectedLivesWith,
			selectedDayRange,
			centreNames,
			sortBy,
			userLocation,
			currentDistance,
			centreLocations,
			maintainQueryParams,
			noReserved,
			isUnderdog,
			...rest
		},
		dispatch,
	] = useReducer(dogSearchReducer, dogSearchDefaultState);

	/**
	 * Parses the state values into the dog-finder API query schema
	 */
	const buildSearchObject = useMemo(
		() => ({
			page: pagination,
			sort: sortBy,
			breed: selectedBreeds,
			age: selectedAges,
			size: selectedSizes,
			gender: selectedGenders,
			centres: selectedCentres,
			county: selectedCounty,
			daysSinceAdded: parseInt(selectedDayRange) || undefined,
			liveWithCats: selectedLivesWith.includes("Cats"),
			liveWithDogs: selectedLivesWith.includes("Dogs"),
			liveWithPreschool: selectedLivesWith.includes("Pre-school children"),
			liveWithPrimary: selectedLivesWith.includes("Primary school children"),
			liveWithSecondary: selectedLivesWith.includes(
				"Secondary school children",
			),
			searchFrom: userLocation
				? {
						latitude: userLocation?.latitude,
						longitude: userLocation?.longitude,
				  }
				: undefined,
			noReserved,
			isUnderdog,
		}),
		[
			userLocation,
			pagination,
			sortBy,
			selectedBreeds,
			selectedAges,
			selectedSizes,
			selectedGenders,
			selectedCentres,
			selectedCounty,
			selectedDayRange,
			selectedLivesWith,
			noReserved,
			isUnderdog,
		],
	);
	const encryptedUserlocation = useMemo(
		() =>
			userLocation ? encryptData(JSON.stringify(userLocation)) : undefined,
		[userLocation],
	);

	const queryStrings = useMemo(
		() =>
			`?${qs.stringify({
				...buildSearchObject,
				currentDistance,
				searchFrom: encryptedUserlocation,
			})}`,
		[buildSearchObject, encryptedUserlocation, currentDistance],
	);
	useEffect(() => {
		if (maintainQueryParams) {
			window.history.replaceState({}, null, queryStrings);

			// As we are changing the URL here - it's important to remove the query params from the URL that gets sent to GA
			manuallyCleanParamsFromUrl(window.location?.href, [
				"userLocation",
				"searchFrom",
			]);
		}
	}, [queryStrings, maintainQueryParams]);

	// ============================================ GQL QUERY HOOK ==========================================================

	const [getDogs, { data, loading: dogApiLoading }] = useLazyQuery(
		SEARCH_DOG_QUERY,
		{
			client: dogSearchClient,
			fetchPolicy: "cache-first",
			variables: buildSearchObject,
		},
	);

	useEffect(() => {
		if (usingDogSearchQuery) {
			getDogs();
		}
	}, [getDogs, usingDogSearchQuery]);
	// ============================================ REDUCER ACTIONS =====================================================
	const onChangeFilterView = useCallback(
		(newView: FilterDisplayState) =>
			dispatch(onChangeFilterViewAction(newView)),
		[],
	);

	const onResetFilters = useCallback(
		(dontShowFilters?: boolean) =>
			dispatch(onResetFiltersAction(dontShowFilters)),
		[],
	);

	const onInitFilters = useCallback(
		(
			isListingPage: boolean,
			shouldMaintainQueryParams: boolean,
			allCentres: RehomingCentreLocation[],
			centreCode?: string,
		) =>
			dispatch(
				onInitFiltersAction(
					isListingPage,
					shouldMaintainQueryParams,
					allCentres,
					centreCode,
				),
			),
		[],
	);

	const onSelectBreed = useCallback(
		(breed: string) => dispatch(onSelectBreedAction(breed)),
		[],
	);

	const onSelectBreeds = useCallback(
		(breeds: string[]) => dispatch(onSelectBreedsAction(breeds)),
		[],
	);

	const onConfirmSelectedBreeds = useCallback(
		() => dispatch(onConfirmSelectedBreedsAction()),
		[],
	);

	const onCancelSelectedBreeds = useCallback(
		() => dispatch(onCancelSelectedBreedsAction()),
		[],
	);

	const onUpdateTravelDistance = useCallback(
		(distance: number) => dispatch(onUpdateTravelDistanceAction(distance)),
		[],
	);

	const onUpdateLocation = useCallback(
		(userLocation: Place, distance: number) =>
			dispatch(onUpdateLocationAction(userLocation, distance)),
		[],
	);

	const onUpdateSort = useCallback(
		(selectedOption: SortOption) =>
			dispatch(onUpdateSortOptionAction(selectedOption)),
		[],
	);

	const onUpdateCentre = useCallback(
		(selectedCentre?: string) =>
			dispatch(onUpdateFilterOptionAction({ selectedCentre })),
		[],
	);

	const onUpdateCounty = useCallback(
		(selectedCounty?: string) =>
			dispatch(onUpdateFilterOptionAction({ selectedCounty })),
		[],
	);

	const onUpdateAge = useCallback(
		(selectedAge?: string) =>
			dispatch(onUpdateFilterOptionAction({ selectedAge })),
		[],
	);
	const onUpdateSizes = useCallback(
		(selectedSize?: string) =>
			dispatch(onUpdateFilterOptionAction({ selectedSize })),
		[],
	);
	const onUpdateGenders = useCallback(
		(selectedGender?: string) =>
			dispatch(onUpdateFilterOptionAction({ selectedGender })),
		[],
	);
	const onUpdateLivesWith = useCallback(
		(selectedLivesWithOption?: string) =>
			dispatch(onUpdateFilterOptionAction({ selectedLivesWithOption })),
		[],
	);

	const onUpdateDayRange = useCallback(
		(selectedDayRange?: string) =>
			dispatch(onUpdateFilterOptionAction({ selectedDayRange })),
		[],
	);

	const onSearchBreedList = useCallback(
		(breedSearchTerm: string) =>
			dispatch(onSearchBreedListAction(breedSearchTerm)),
		[],
	);

	const onUpdateSelectedCounty = useCallback(
		(newCountyInRange: string, selectedCentres: string[]) =>
			dispatch(onUpdateSelectedCountyAction(newCountyInRange, selectedCentres)),
		[],
	);

	const onToggleIsUnderdog = useCallback(
		(isUnderdog: boolean) => dispatch(onToggleIsUnderdogAction(isUnderdog)),
		[],
	);

	const onToggleNoReserved = useCallback(
		(isNoReserved: boolean) => dispatch(onToggleNoReservedAction(isNoReserved)),
		[],
	);

	const onShowDogs = () => onChangeFilterView("None");
	/**
	 * Returns a boolean evaluating if the given "liveWith" option button should be disabled.
	 * @param liveWith the title of the button to check disabled state.
	 * @returns {boolean} = If the provided liveWith is disabled
	 */
	const getLivesWithDisabled = useCallback(
		(liveWith: string): boolean => {
			switch (liveWith) {
				case "Cats":
					return !data?.results.filterData.liveWithCats;
				case "Dogs":
					return !data?.results.filterData.liveWithDogs;
				case "Pre-school children":
					return !data?.results.filterData.liveWithPreschool;
				case "Primary school children":
					return !data?.results.filterData.liveWithPrimary;
				case "Secondary school children":
					return !data?.results.filterData.liveWithSecondary;
			}
		},
		[
			data?.results.filterData.liveWithCats,
			data?.results.filterData.liveWithDogs,
			data?.results.filterData.liveWithPreschool,
			data?.results.filterData.liveWithPrimary,
			data?.results.filterData.liveWithSecondary,
		],
	);

	const isFilterDisabled = useCallback(
		(filter: FilterType, option: string): boolean => {
			switch (filter) {
				case "AGE":
					return !data?.results.filterData.age.includes(option);
				case "SIZE":
					return !data?.results.filterData.size.includes(option);
				case "GENDER":
					return !data?.results.filterData.gender.includes(option.charAt(0));
				case "LIVE_WITH":
					return getLivesWithDisabled(option);
				case "IS_UNDERDOGS":
					return !data?.results.filterData.isUnderdogs;
				case "IS_NO_RESERVED":
					return data?.results.filterData.noAvailableDogs;
			}
		},
		[data?.results.filterData, getLivesWithDisabled],
	);
	// ============================================ FUNCTIONS =====================================================
	const distanceThrottle = useRef(null);
	/**
	 * On Change event for the distance slider. Only sets state when it's landed on expected value.
	 * Stops state from changing during slide event
	 * @param value The new value of the slider
	 */
	const safelyChangeDistance = useCallback(
		(value: string) => {
			if (DISTANCE_OPTIONS.includes(parseInt(value))) {
				// Stop changes on inbetween vals
				distanceThrottle.current = setTimeout(() => {
					onUpdateTravelDistance(parseFloat(value));
				}, 300);
			}
		},
		[onUpdateTravelDistance],
	);

	/**
	 * Returns the current number of results
	 */
	const resultsCount = useMemo(() => {
		return data?.results.totalResults || 0;
	}, [data]);

	/**
	 * Memoised version of a breed list, mapped from the dog-finder filterData
	 */
	const breedList = useMemo(() => {
		return (
			data?.results?.filterData?.breedList.map(
				(breed: { title: string; count: number }) => ({
					...breed,
					selected: tempSelectedBreeds.includes(breed.title),
					onClick: onSelectBreed,
				}),
			) || []
		);
	}, [data, tempSelectedBreeds, onSelectBreed]);

	const results: ESDocDog[] = useMemo(
		() => [
			...previousResultsPages,
			...(data?.results?.results?.map((dog: ESDocDog) => ({
				...dog,
				centreName: centreNames[dog.centreCode],
			})) || []),
		],
		[data, previousResultsPages, centreNames],
	);

	const onLoadMore = useCallback(
		() => dispatch(onLoadMoreAction(results)),
		[results],
	);
	const getFiltersCount = useCallback(() => {
		return [
			selectedBreeds?.length || 0,
			selectedAges?.length || 0,
			selectedSizes?.length || 0,
			selectedGenders?.length || 0,
			selectedCounty?.length - 1 || 0,
			selectedDayRange === "Any" ? 0 : 1,
			selectedLivesWith?.length || 0,
			isUnderdog ? 1 : 0,
			noReserved ? 1 : 0,
		].filter((x) => x > 0).length;
	}, [
		selectedBreeds,
		selectedAges,
		selectedSizes,
		selectedGenders,
		selectedCounty,
		selectedDayRange,
		selectedLivesWith,
		isUnderdog,
		noReserved,
	]);

	// This ensures that if the page has reloaded and the results are already in the store, we don't add duplicate results as a new page
	const uniqueResults = useMemo(() => {
		return results.reduce((acc: ESDocDog[], dog: ESDocDog) => {
			if (!acc.find((x) => x.id === dog.id)) {
				acc.push(dog);
			}
			return acc;
		}, []);
	}, [results]);
	return (
		<DogSearchApiContext.Provider
			value={{
				...rest,
				pagination,
				previousResultsPages,
				selectedBreeds,
				selectedCentres,
				selectedCounty,
				tempSelectedBreeds,
				selectedAges,
				selectedSizes,
				selectedGenders,
				selectedLivesWith,
				selectedDayRange,
				centreLocations,
				userLocation,
				currentDistance,
				resultsCount,
				results: uniqueResults,
				centreNames,
				sortBy,
				searchListingsUrl: `/rehoming/dogs?${qs.stringify({
					...buildSearchObject,
					searchFrom: userLocation,
					currentDistance,
				})}`,
				resultsUrls:
					data?.results?.resultsUrls?.map(
						(url: string) => `/rehoming/dogs/${url}`,
					) || [],
				breedList,
				filtersCount: getFiltersCount(),
				dogApiLoading,
				maintainQueryParams,
				queryStrings,
				isUnderdog,
				noReserved,
				useDogSearchQuery,
				safelyChangeDistance,
				getLivesWithDisabled,

				onChangeFilterView,
				onResetFilters,
				onInitFilters,
				onSelectBreed,
				onSelectBreeds,
				onConfirmSelectedBreeds,
				onCancelSelectedBreeds,
				onUpdateCentre,
				onUpdateCounty,
				onUpdateAge,
				onUpdateSizes,
				onUpdateGenders,
				onUpdateLivesWith,
				onUpdateDayRange,
				onSearchBreedList,
				isFilterDisabled,
				onShowDogs,
				onUpdateSort,
				onLoadMore,
				onUpdateTravelDistance,
				onUpdateLocation,
				onToggleIsUnderdog,
				onToggleNoReserved,
				onUpdateSelectedCounty,
			}}
		>
			{children}
		</DogSearchApiContext.Provider>
	);
};

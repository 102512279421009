import React from "react";
import { Colours } from "../../../utils/colours";

export const Measurements: React.FC<IconProps> = ({
	height = "30px",
	width = "30px",
	fill = Colours.MIDNIGHT,
	...props
}) => {
	return (
		// biome-ignore lint/a11y/noSvgWithoutTitle: Aria hidden is used to hide the svg from screen readers
		<svg
			aria-hidden="true"
			focusable="false"
			viewBox="0 0 30 30"
			height={height}
			width={width}
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M27.156 18.438H11.463V2.743C11.463 1.78 10.681 1 9.719 1H2.744C1.78 1 1 1.78 1 2.744v24.412c0 .963.78 1.744 1.744 1.744h24.412c.963 0 1.744-.78 1.744-1.744v-6.975c0-.963-.78-1.744-1.744-1.744zM2.744 2.743h6.975V6.23H6.667a.436.436 0 00-.436.436v.872c0 .24.195.436.436.436H9.72v2.616H6.667a.436.436 0 00-.436.436v.871c0 .241.195.436.436.436H9.72v2.616H6.667a.436.436 0 00-.436.436v.872c0 .24.195.436.436.436H9.72v2.255l-6.975 6.975V2.744zm24.412 24.412H3.976l6.975-6.975h2.255v3.052c0 .24.195.436.436.436h.872c.24 0 .436-.195.436-.436V20.18h2.616v3.052c0 .24.195.436.436.436h.871c.241 0 .436-.195.436-.436V20.18h2.616v3.052c0 .24.195.436.436.436h.872c.24 0 .436-.195.436-.436V20.18h3.487v6.975z"
				fill={fill}
				fillRule="nonzero"
			/>
		</svg>
	);
};

export default Measurements;

import * as styles from "./ListWithCountItem.module.scss";

import React from "react";

export interface ListWithCountItemProps {
	onClick: (title: string) => void;
	title: string;
	count: number;
	selected?: boolean;
	disabled?: boolean;
}

export const ListWithCountItem: React.FC<ListWithCountItemProps> = ({
	onClick: onItemClick,
	title,
	count,
	selected,
	disabled = false,
}) => {
	const onClick = disabled ? () => {} : onItemClick;
	return (
		<>
			{selected ? (
				<button
					type="button"
					className={styles.listwithcountitemselected}
					onClick={() => onClick(title)}
					tabIndex={0}
					onKeyDown={(e) => e.key === "Enter" && onClick(title)}
				>
					<div className={styles.listwithcountitemtitleselected}>{title}</div>
					<div className={styles.listwithcountitemcountselected}>✔</div>
				</button>
			) : (
				<button
					type="button"
					className={
						disabled
							? styles.listwithcountitemdisabled
							: styles.listwithcountitem
					}
					onClick={() => onClick(title)}
					tabIndex={0}
					onKeyDown={(e) => e.key === "Enter" && onClick(title)}
				>
					<div className={styles.listwithcountitemtitle}>{title}</div>
					<div className={styles.listwithcountitemcount}>{count}</div>
				</button>
			)}
		</>
	);
};
export default ListWithCountItem;

import { TypedDocumentNode, gql } from "@apollo/client";

export const SEARCH_DOG_QUERY_TYPES = gql`
    input PlaceInput {
        latitude: Float
        longitude: Float
    }
    input DogClassGeolocationInput {
        distance: String
        lat: Float
        lon: Float
    }
` as TypedDocumentNode;

export const SEARCH_DOG_QUERY = gql`
    query SearchFilterDogs(
        $page: Int
        $sort: String
        $breed: [String]
        $age: [String]
        $size: [String]
        $gender: [String]
        $centres: [String]
        $daysSinceAdded: Int
        $liveWithCats: Boolean
        $liveWithDogs: Boolean
        $liveWithPreschool: Boolean
        $liveWithPrimary: Boolean
        $liveWithSecondary: Boolean
        $isUnderdog: Boolean
        $noReserved: Boolean
        $searchFrom: PlaceInput
    ) {
        results: searchFilterDogs(
            where: {
                page: $page
                sort: $sort
                breed: $breed
                age: $age
                size: $size
                gender: $gender
                centres: $centres
                daysSinceAdded: $daysSinceAdded
                liveWithCats: $liveWithCats
                liveWithDogs: $liveWithDogs
                liveWithPreschool: $liveWithPreschool
                liveWithPrimary: $liveWithPrimary
                liveWithSecondary: $liveWithSecondary
                isUnderdog: $isUnderdog
                noReserved: $noReserved
                searchFrom: $searchFrom
            }
        ) {
            totalResults
            results {
                apiKey
                key
                id
                name
                url
                created
                isPublished
                gender
                dob
                breed
                isCrossBreed
                size
                centreCode
                status
                liveWithCats
                liveWithDogs
                liveWithPreschool
                liveWithPrimary
                liveWithSecondary
                needsMedicalCare
                frontEndBreedName
                isReserved
                isUnderdog
                isHomeFromHome
                media {
                    images {
                        src
                        alt
                        weight
                        isHero
                        caption
                    }
                    videos {
                        url
                        title
                    }
                }
            }
            filterData {
                breedList {
                    title
                    count
                }
                centresList {
                    title
                    count
                }
                age
                size
                gender
                liveWithCats
                liveWithDogs
                liveWithPreschool
                liveWithPrimary
                liveWithSecondary
                isUnderdogs
                noAvailableDogs
            }
            resultsUrls
        }
    }
` as TypedDocumentNode;

import React from "react";

export const DownloadMP3: React.FC<IconProps> = ({
	height = "26px",
	width = "26px",
	fill = "#FFF",
	...props
}) => {
	return (
		// biome-ignore lint/a11y/noSvgWithoutTitle: Aria hidden is used to hide the svg from screen readers
		<svg
			aria-hidden="true"
			focusable="false"
			width={width}
			height={height}
			viewBox="0 0 100 100"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<defs>
				<style>
					{`.icon_download_mp3_svg__b{fill:${fill}}}.icon_download_mp3_svg__c{fill:#272727}`}
				</style>
			</defs>
			<path
				className="icon_download_mp3_svg__c"
				d="M49.01 53.65h-.75v1.1h.75c.35 0 .61-.18.61-.56 0-.32-.23-.53-.61-.53ZM54.87 40.51c0 .28.22.5.5.5h5.76a2.01 2.01 0 0 0-.36-.48l-5.42-5.42c-.15-.15-.31-.26-.48-.36v5.75Z"
			/>
			<path
				className="icon_download_mp3_svg__c"
				d="M57.12 58.82v-8.15c0-.27-.22-.5-.5-.5h-18v9.15h18c.27 0 .5-.22.5-.5ZM46.13 56.9h-1.1v-2.51l-.84 2.51h-.88l-.84-2.51v2.51h-1.1v-4.2h1.54l.85 2.7.84-2.7h1.54v4.2Zm2.91-1.19h-.77v1.19h-1.11v-4.2h1.95c1.14 0 1.63.69 1.63 1.48 0 .91-.55 1.52-1.7 1.52Zm3.87 1.26c-.84 0-1.69-.46-1.69-1.4h1.03c.04.35.34.51.61.51.3 0 .59-.12.59-.5 0-.49-.64-.49-1.27-.49v-.6l.91-.86h-.14c-.73 0-1.12-.02-1.59-.17l.24-.91c.43.14.82.15 1.31.15h1.52v.7l-1.16 1.07c.92 0 1.32.58 1.32 1.17 0 .64-.56 1.33-1.67 1.33Z"
			/>
			<path
				className="icon_download_mp3_svg__c"
				d="M55.37 43.01a2.5 2.5 0 0 1-2.5-2.5V34.5H40.72c-.6 0-1.07.19-1.48.61s-.61.89-.61 1.48v11.58h18a2.5 2.5 0 0 1 2.5 2.5v8.15a2.5 2.5 0 0 1-2.5 2.5h-18v2.09c0 .6.19 1.07.61 1.48.42.42.89.61 1.49.61h18.56c.6 0 1.07-.19 1.48-.61.42-.42.61-.89.61-1.48v-20.4h-6.01Z"
			/>
			<path
				className="icon_download_mp3_svg__c"
				d="M66.5 22.5h-33c-6.08 0-11 5.13-11 11.46v32.08c0 6.33 4.92 11.46 11 11.46h33c6.08 0 11-5.13 11-11.46V33.96c0-6.33-4.92-11.46-11-11.46Zm-3.13 40.9c0 1.13-.4 2.11-1.2 2.9-.79.79-1.77 1.19-2.9 1.19H40.71c-1.13 0-2.11-.4-2.9-1.2a3.98 3.98 0 0 1-1.2-2.9V36.58c0-1.13.4-2.11 1.2-2.9s1.77-1.2 2.9-1.2h13.15c1.13 0 2.1.4 2.9 1.2l5.41 5.41c.79.79 1.2 1.77 1.2 2.9v21.4Z"
			/>
			<path
				className="icon_download_mp3_svg__b"
				d="M56.76 33.7a3.98 3.98 0 0 0-2.9-1.2H40.71c-1.13 0-2.11.4-2.9 1.2s-1.2 1.77-1.2 2.9v26.81c0 1.13.4 2.11 1.2 2.9.79.79 1.77 1.2 2.9 1.2h18.56c1.13 0 2.11-.4 2.9-1.19a3.98 3.98 0 0 0 1.2-2.9v-21.4c0-1.13-.4-2.11-1.2-2.9l-5.41-5.41Zm4 6.83c.15.15.26.31.36.48h-5.76c-.28 0-.5-.22-.5-.5v-5.75c.17.09.33.21.48.36l5.42 5.42Zm-22.14 9.64h18c.27 0 .5.22.5.5v8.15c0 .28-.22.5-.5.5h-18v-9.15Zm22.14 14.72c-.42.42-.89.61-1.48.61H40.72c-.6 0-1.07-.19-1.49-.61-.42-.41-.61-.89-.61-1.48v-2.09h18a2.5 2.5 0 0 0 2.5-2.5v-8.15a2.5 2.5 0 0 0-2.5-2.5h-18V36.59c0-.6.19-1.07.61-1.48s.89-.61 1.48-.61h12.15v6.01a2.5 2.5 0 0 0 2.5 2.5h6.01v20.4c0 .6-.19 1.07-.61 1.48Z"
			/>
			<path
				className="icon_download_mp3_svg__b"
				d="m43.75 55.4-.84-2.7h-1.54v4.2h1.1v-2.51l.84 2.51h.88l.84-2.51v2.51h1.1v-4.2h-1.54l-.84 2.7zM49.1 52.7h-1.95v4.2h1.11v-1.19h.77c1.14 0 1.7-.61 1.7-1.52 0-.79-.49-1.48-1.63-1.48Zm-.09 2.05h-.75v-1.1h.75c.38 0 .61.21.61.53 0 .38-.25.56-.61.56ZM53.25 54.47l1.16-1.07v-.7h-1.52c-.49 0-.88-.01-1.31-.15l-.24.91c.48.15.86.17 1.59.17h.14l-.91.86v.6c.64 0 1.27 0 1.27.49 0 .38-.29.5-.59.5-.28 0-.57-.16-.61-.51H51.2c0 .93.85 1.4 1.69 1.4 1.11 0 1.67-.69 1.67-1.33 0-.59-.4-1.17-1.32-1.17Z"
			/>
		</svg>
	);
};

export default DownloadMP3;

export const GOOGLE_API_LIBRARIES = [
	"places" as "places",
	"geometry" as "geometry",
];
export const pushToDataLayer = (obj: DataLayerObject) => {
	(window as GoogleWindow).dataLayer = (window as GoogleWindow).dataLayer || [];
	(window as GoogleWindow).dataLayer.push(obj);
};

// A script to manually remove the qParams from the url sent to GA.
// This was necessary as the queryString keys have been parsed from an object
// and contain special characters that URLSearchParams does not recognise when trying to delete
export const manuallyCleanParamsFromUrl = (
	url: string,
	keysToRemove: string[],
) => {
	const qString = url.split("?")?.[1];
	const params = qString?.split("&");
	const newParams = params?.filter(
		(param) =>
			!keysToRemove
				.map((keyToRemove) => param.includes(keyToRemove))
				.includes(true),
	);
	window.ga?.(
		"set",
		"location",
		`${url.split("?")[0]}?${newParams?.join("&") || ""}`,
	);
};

import { useContext } from "react";
import { CmsMapContext } from "./CmsMap/CmsMap.context";
import type { CmsMapContextState } from "./CmsMap/CmsMap.types";
import { DogSearchApiContext } from "./DogSearchApi/DogSearchApi.context";
import type { DogSearchContextState } from "./DogSearchApi/DogSearchApi.types";
import { FavouritesManagerContext } from "./FavouritesManager/FavouritesManager.context";
import { MyAccountContext } from "./MyAccount/MyAccount.context";
import { PageManagerContext } from "./PageManager/PageManager.context";

export const useDogSearchApi = (): DogSearchContextState =>
	useContext(DogSearchApiContext);

export const usePageManager = (): PageManagerContextState =>
	useContext(PageManagerContext);

export const useFavouritesManager = (): FavouritesManagerContext =>
	useContext(FavouritesManagerContext);

export const useCmsMap = (): CmsMapContextState => useContext(CmsMapContext);
export const useMyAccount = () => useContext(MyAccountContext);

import React from "react";

export const UserLoggedOut: React.FC<IconProps> = ({
	height = "40px",
	width = "40px",
	...props
}) => {
	return (
		// biome-ignore lint/a11y/noSvgWithoutTitle: Aria hidden is used to hide the svg from screen readers
		<svg
			aria-hidden="true"
			focusable="false"
			width={width}
			height={height}
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 32 32"
			{...props}
		>
			<path
				d="M16 32c2.882 0 5.548-.72 8-2.161A16.167 16.167 0 0029.839 24C31.279 21.548 32 18.882 32 16c0-2.882-.72-5.548-2.161-8A16.167 16.167 0 0024 2.161C21.548.721 18.882 0 16 0c-2.882 0-5.548.72-8 2.161A16.167 16.167 0 002.161 8C.721 10.452 0 13.118 0 16c0 2.882.72 5.548 2.161 8A16.167 16.167 0 008 29.839C10.452 31.279 13.118 32 16 32zm10.258-6.58a6.336 6.336 0 00-2.097-3.775c-1.14-1.01-2.484-1.516-4.032-1.516-.387 0-.903.108-1.548.323-.904.258-1.764.387-2.581.387s-1.677-.13-2.58-.387c-.646-.215-1.162-.323-1.549-.323-1.548 0-2.893.505-4.032 1.516a6.336 6.336 0 00-2.097 3.774 13.614 13.614 0 01-2.71-4.322A13.76 13.76 0 012.065 16c0-2.538.623-4.871 1.87-7A13.947 13.947 0 019 3.935c2.129-1.247 4.462-1.87 7-1.87 2.538 0 4.871.623 7 1.87A13.947 13.947 0 0128.065 9c1.247 2.129 1.87 4.462 1.87 7a13.76 13.76 0 01-.967 5.097 13.614 13.614 0 01-2.71 4.322zM16 18.58c1.548 0 2.882-.558 4-1.677 1.118-1.118 1.677-2.451 1.677-4 0-1.548-.559-2.881-1.677-4-1.118-1.118-2.452-1.677-4-1.677s-2.882.559-4 1.677c-1.118 1.119-1.677 2.452-1.677 4 0 1.549.559 2.882 1.677 4 1.118 1.119 2.452 1.678 4 1.678zm0-2.064c-.99 0-1.839-.355-2.548-1.064-.71-.71-1.065-1.56-1.065-2.549 0-.989.355-1.838 1.065-2.548S15.01 9.29 16 9.29c.99 0 1.839.355 2.548 1.065.71.71 1.065 1.559 1.065 2.548 0 .99-.355 1.839-1.065 2.549S16.99 16.516 16 16.516zm0 13.42c-3.054 0-5.806-.904-8.258-2.71v-.903c0-1.119.409-2.086 1.226-2.904.817-.817 1.785-1.225 2.903-1.225.172 0 .495.064.968.193 1.075.344 2.129.516 3.161.516s2.086-.172 3.161-.516c.473-.129.796-.193.968-.193 1.118 0 2.086.408 2.903 1.225.817.818 1.226 1.785 1.226 2.904v.903c-2.452 1.806-5.204 2.71-8.258 2.71z"
				fill="#222"
				fillRule="nonzero"
			/>
		</svg>
	);
};

export default UserLoggedOut;

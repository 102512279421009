/**
 * Gets a cookie of the given name
 * @param name The name of the cookie to retrieve
 * @returns The cookie data
 */
export const getCookie = (name: string): string => {
	if (!name || typeof window === "undefined" || !window.document) return null;
	const nameLenPlus = name.length + 1;
	return (
		document.cookie
			.split(";")
			.map((c) => c.trim())
			.filter((cookie) => {
				return cookie.substring(0, nameLenPlus) === `${name}=`;
			})
			.map((cookie) => {
				return decodeURIComponent(cookie.substring(nameLenPlus));
			})[0] || null
	);
};

export const setFavDogCreateAccCookie = () => {
	if (typeof window === "undefined" || !window.document) return;
	const expire = new Date();
	expire.setTime(
		expire.getTime() +
			parseInt(process.env.GATSBY_FAVDOG_CREATE_ACC_COOKIE_EXPIRY) * 60 * 1000,
	);
	const cookieValue = `${encodeURIComponent(
		true,
	)};expires=${expire.toUTCString()}`;
	document.cookie = `${process.env.GATSBY_FAVDOG_CREATE_ACC_COOKIE_NAME}=${cookieValue};domain=${process.env.GATSBY_COOKIE_DOMAIN};path=/`;
};

import React from "react";

export const DownloadPDF: React.FC<IconProps> = ({
	height = "26px",
	width = "26px",
	fill = "#FFF",
	...props
}) => {
	return (
		// biome-ignore lint/a11y/noSvgWithoutTitle: Aria hidden is used to hide the svg from screen readers
		<svg
			aria-hidden="true"
			focusable="false"
			width={width}
			height={height}
			viewBox="0 0 100 100"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<defs>
				<style>
					{`.icon_download_pdf_svg__b{fill:${fill}}.icon_download_pdf_svg__c{fill:#272727}`}
				</style>
			</defs>
			<path
				className="icon_download_pdf_svg__c"
				d="M44.07 59.15s.09 0 .26-.13.39-.34.64-.64c.34-.39.71-.88 1.1-1.48l-.26.19c-.99.86-1.57 1.55-1.74 2.06ZM54.87 40.51c0 .28.22.5.5.5h5.76a2.01 2.01 0 0 0-.36-.48l-5.41-5.41c-.15-.15-.31-.26-.48-.36v5.75ZM49.68 47.94c0-.73-.11-1.1-.32-1.1-.09 0-.13.34-.13 1.03s.09 1.22.26 1.61c.13-.3.19-.82.19-1.55Z"
			/>
			<path
				className="icon_download_pdf_svg__c"
				d="M55.37 43.01a2.5 2.5 0 0 1-2.5-2.5V34.5H40.72c-.6 0-1.07.19-1.48.61-.42.42-.61.89-.61 1.48V63.4c0 .6.19 1.07.61 1.48.42.42.89.61 1.48.61h18.56c.6 0 1.07-.19 1.48-.61s.61-.89.61-1.48V43h-6.01Zm1.5 13.18c-.24.3-.6.45-1.1.45s-1.07-.1-1.74-.29c-.67-.19-1.3-.46-1.9-.81-.77.13-1.98.47-3.61 1.03l-.77.26c-.77 1.29-1.5 2.23-2.19 2.84-.56.47-1.06.69-1.51.64s-.78-.23-1-.55c-.21-.32-.3-.63-.26-.93.21-.95 1.31-1.93 3.29-2.96.69-1.2 1.46-2.79 2.32-4.77-.56-2.06-.71-3.5-.45-4.32.09-.39.34-.66.77-.81.43-.15.84-.13 1.22.06.39.19.62.57.71 1.13.21.6.11 1.76-.32 3.48v.06c.52 1.37 1.31 2.38 2.38 3.03 2.19-.3 3.57-.17 4.12.39.26.26.39.59.39 1s-.12.76-.35 1.06Z"
			/>
			<path
				className="icon_download_pdf_svg__c"
				d="M49.36 52.13c-.56 1.5-1.1 2.69-1.61 3.54l.77-.32c1.16-.47 2.08-.77 2.77-.9-.77-.56-1.42-1.33-1.93-2.32Z"
			/>
			<path
				className="icon_download_pdf_svg__c"
				d="M66.5 22.5h-33c-6.08 0-11 5.13-11 11.46v32.08c0 6.33 4.92 11.46 11 11.46h33c6.08 0 11-5.13 11-11.46V33.96c0-6.33-4.92-11.46-11-11.46Zm-3.13 40.9c0 1.13-.4 2.11-1.2 2.9s-1.77 1.2-2.9 1.2H40.71c-1.13 0-2.1-.4-2.9-1.2s-1.2-1.77-1.2-2.9V36.59c0-1.13.4-2.11 1.2-2.9s1.77-1.2 2.9-1.2h13.15c1.13 0 2.1.4 2.9 1.2l5.41 5.41c.79.79 1.2 1.77 1.2 2.9v21.4Z"
			/>
			<path
				className="icon_download_pdf_svg__c"
				d="M55.48 54.87c-.39-.06-.92-.08-1.61-.03.6.26 1.12.43 1.55.52.3.09.52.11.64.06.09 0 .15-.02.19-.06.09-.04.08-.12-.03-.23-.11-.11-.35-.19-.74-.26Z"
			/>
			<path
				className="icon_download_pdf_svg__b"
				d="M52.71 53.74c-1.07-.64-1.87-1.65-2.38-3.03v-.06c.43-1.72.54-2.88.32-3.48-.09-.56-.32-.93-.71-1.13a1.52 1.52 0 0 0-1.22-.06c-.43.15-.69.42-.77.81-.26.82-.11 2.26.45 4.32-.86 1.98-1.63 3.57-2.32 4.77-1.98 1.03-3.07 2.02-3.29 2.96-.04.3.04.61.26.93.21.32.55.5 1 .55s.96-.17 1.51-.64c.69-.6 1.42-1.55 2.19-2.84l.77-.26c1.63-.56 2.84-.9 3.61-1.03.6.34 1.24.61 1.9.81.67.19 1.25.29 1.74.29s.86-.15 1.1-.45c.24-.3.35-.66.35-1.06s-.13-.74-.39-1c-.56-.56-1.93-.69-4.12-.39Zm-7.73 4.64c-.26.3-.47.52-.64.64-.17.13-.26.17-.26.13.17-.52.75-1.2 1.74-2.06l.26-.19c-.39.6-.75 1.1-1.1 1.48Zm4.38-11.54c.21 0 .32.37.32 1.1s-.06 1.25-.19 1.55c-.17-.39-.26-.92-.26-1.61s.04-1.03.13-1.03Zm-.84 8.51-.77.32c.52-.86 1.05-2.04 1.61-3.54.52.99 1.16 1.76 1.93 2.32-.69.13-1.61.43-2.77.9Zm7.73 0s-.11.06-.19.06c-.13.04-.34.02-.64-.06-.43-.09-.95-.26-1.55-.52.69-.04 1.22-.03 1.61.03.39.06.63.15.74.26.11.11.12.18.03.23Z"
			/>
			<path
				className="icon_download_pdf_svg__b"
				d="M56.76 33.7a3.98 3.98 0 0 0-2.9-1.2H40.71c-1.13 0-2.1.4-2.9 1.2s-1.2 1.77-1.2 2.9v26.81c0 1.13.4 2.11 1.2 2.9s1.77 1.2 2.9 1.2h18.56c1.13 0 2.11-.4 2.9-1.2s1.2-1.77 1.2-2.9v-21.4c0-1.13-.4-2.11-1.2-2.9l-5.41-5.41Zm4 6.83c.15.15.26.31.36.48h-5.76c-.28 0-.5-.22-.5-.5v-5.75c.17.09.33.21.48.36l5.41 5.41Zm0 24.36c-.42.42-.89.61-1.48.61H40.72c-.6 0-1.07-.19-1.48-.61a1.97 1.97 0 0 1-.61-1.48V36.6c0-.6.19-1.07.61-1.48.42-.42.89-.61 1.48-.61h12.15v6.01a2.5 2.5 0 0 0 2.5 2.5h6.01v20.4c0 .6-.19 1.07-.61 1.48Z"
			/>
		</svg>
	);
};

export default DownloadPDF;

import {
	IconSize,
	ImageSize,
	getDogImagePath,
	getImagePath,
} from "@dogstrust/src/utils/images";
import React from "react";
import { Helmet } from "react-helmet-async";

interface ProcessedImageProps
	extends React.ImgHTMLAttributes<HTMLImageElement> {
	dimensions: ImageSize | IconSize;
	isDogImage?: boolean;
	noProcess?: boolean;
	preload?: boolean;
}
const ProcessedImage: React.FC<ProcessedImageProps> = ({
	src,
	alt,
	dimensions,
	isDogImage = false,
	noProcess = false,
	preload = false,
	...rest
}) => {
	if (!src) return null;
	const getImage = isDogImage ? getDogImagePath : getImagePath;
	const webpSrc = `${src}.webp`; // e.g /images/dogs/1234.jpg.webp

	const getOriginalFormat = () => {
		const extention = src.split(".")[src.split(".").length - 1];
		switch (extention) {
			case "jpg":
				return "jpeg";
			default:
				return extention;
		}
	};
	return (
		<>
			{preload && (
				<Helmet>
					<link rel="preload" href={getImage(dimensions, webpSrc)} as="image" />
				</Helmet>
			)}
			<picture>
				<source
					type="image/webp"
					srcSet={noProcess ? src : getImage(dimensions, webpSrc)}
					media="(min-width: 1px)"
				/>
				<source
					srcSet={noProcess ? src : getImage(dimensions, src)}
					type={`image/${getOriginalFormat()}`}
					media="(min-width: 1px)"
				/>

				<img
					{...rest}
					src={noProcess ? src : getImage(dimensions, src)}
					alt={alt}
				/>
			</picture>
		</>
	);
};

export default ProcessedImage;

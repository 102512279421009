import React from "react";
import { Colours } from "../../../utils/colours";

export const Sex: React.FC<IconProps> = ({
	height = "30px",
	width = "30px",
	fill = Colours.MIDNIGHT,
	...props
}) => {
	return (
		// biome-ignore lint/a11y/noSvgWithoutTitle: Aria hidden is used to hide the svg from screen readers
		<svg
			aria-hidden="true"
			focusable="false"
			viewBox="0 0 30 30"
			height={height}
			width={width}
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M15 13.156c0-3.882-3.135-7.031-7-7.031s-7 3.15-7 7.031c0 3.618 2.722 6.602 6.222 6.988v3.169H4.694a.586.586 0 00-.583.585v.391c0 .322.263.586.583.586h2.528v2.54c0 .321.263.585.584.585h.388c.321 0 .584-.264.584-.586v-2.539h2.528c.32 0 .583-.264.583-.586v-.39a.586.586 0 00-.583-.587H8.778v-3.168c3.5-.386 6.222-3.37 6.222-6.988zm-12.444 0c0-3.022 2.43-5.469 5.444-5.469 3.009 0 5.444 2.442 5.444 5.47 0 3.022-2.43 5.468-5.444 5.468-3.009 0-5.444-2.441-5.444-5.469zM29 3.586v4.297a.586.586 0 01-.583.586h-.39a.586.586 0 01-.583-.586V5.666l-3.086 3.1a7.032 7.032 0 011.53 4.39c0 3.882-3.135 7.031-7 7.031a6.978 6.978 0 01-4.335-1.508c.335-.4.632-.83.89-1.284a5.393 5.393 0 003.441 1.23c3.014 0 5.444-2.446 5.444-5.469 0-3.027-2.435-5.469-5.444-5.469a5.393 5.393 0 00-3.442 1.231 8.379 8.379 0 00-.89-1.284 6.978 6.978 0 018.707.03l3.087-3.101h-2.207a.586.586 0 01-.583-.586v-.391c0-.322.262-.586.583-.586h4.278c.32 0 .583.264.583.586z"
				stroke="#000"
				strokeWidth={0.5}
				fill={fill}
				fillRule="nonzero"
			/>
		</svg>
	);
};

export default Sex;

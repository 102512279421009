import React from "react";

export const StepPrivacy: React.FC<IconProps> = ({
	height = "48px",
	width = "48px",
	fillPrimary = "#fff",
	fillSecondary = "#222",
	invert,
	...props
}) => {
	return (
		// biome-ignore lint/a11y/noSvgWithoutTitle: Aria hidden is used to hide the svg from screen readers
		<svg
			aria-hidden="true"
			focusable="false"
			width={width}
			height={height}
			viewBox="0 0 48 48"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				fill={invert ? fillSecondary : fillPrimary}
				fillRule="evenodd"
				d="M23.097.112C39.25-1.333 49.51 11.459 47.818 26.806c-.916 10.764-9.966 21.013-21.194 21.19-6.934.088-14.346-1.153-19.508-6.148-5.965-6.106-8.834-15.5-6.04-23.704C3.494 7.708 12.462.764 23.096.111Z"
				clipRule="evenodd"
			/>
			<path
				fill={invert ? fillPrimary : fillSecondary}
				d="M20.406 19.344V21.5h7.188v-2.156A3.604 3.604 0 0 0 24 15.75a3.576 3.576 0 0 0-3.594 3.594ZM17.531 21.5v-2.156c0-3.55 2.875-6.469 6.469-6.469 3.549 0 6.469 2.92 6.469 6.469V21.5h.718a2.895 2.895 0 0 1 2.875 2.875V33a2.866 2.866 0 0 1-2.874 2.875H16.813A2.838 2.838 0 0 1 13.938 33v-8.625a2.866 2.866 0 0 1 2.874-2.875h.72Z"
			/>
		</svg>
	);
};

export default StepPrivacy;

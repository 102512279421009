import React from "react";

export const RotateDevice: React.FC<IconProps> = ({
	height = "26px",
	width = "26px",
	fill = "#FFF",
	...props
}) => {
	return (
		// biome-ignore lint/a11y/noSvgWithoutTitle: Aria hidden is used to hide the svg from screen readers
		<svg
			aria-hidden="true"
			focusable="false"
			width={width}
			height={height}
			viewBox="0 0 44 37"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M27.692 3.445A5.204 5.204 0 0 0 25.016.488a5.114 5.114 0 0 0-3.96-.168L9.98 4.395a5.193 5.193 0 0 0-2.924 2.707 5.284 5.284 0 0 0-.169 4.006l7.116 19.738.954 2.649a5.197 5.197 0 0 0 2.676 2.96 5.107 5.107 0 0 0 3.962.165l11.073-4.075a5.193 5.193 0 0 0 2.924-2.707c.58-1.26.64-2.7.169-4.005L27.692 3.445Zm-11.774 25.36L9.235 10.239a2.74 2.74 0 0 1 .089-2.075c.3-.652.845-1.157 1.514-1.403l11.076-4.075a2.654 2.654 0 0 1 2.052.09 2.701 2.701 0 0 1 1.387 1.531l6.683 18.568-16.118 5.93Zm17.408-.025a2.68 2.68 0 0 1-1.514 1.403l-11.076 4.075a2.654 2.654 0 0 1-2.052-.09 2.701 2.701 0 0 1-1.387-1.531l-.529-1.468 16.118-5.932.529 1.468a2.72 2.72 0 0 1-.09 2.08v-.005Zm10.163-5.225a1.247 1.247 0 0 1-1.21.964 1.245 1.245 0 0 1-1.063-.605 1.27 1.27 0 0 1-.148-.951c1.3-5.456-.267-11.204-4.148-15.216l-.025 3.266a1.251 1.251 0 0 1-1.244 1.248 1.252 1.252 0 0 1-1.245-1.258l.044-6.006c.007-.077.022-.154.043-.228 0-.032 0-.063.019-.095a1.19 1.19 0 0 1 .176-.381c0-.011 0-.026.017-.036l.017-.015c.078-.1.172-.185.277-.254.04-.031.083-.06.127-.086.067-.03.136-.056.207-.075.094-.032.192-.053.29-.061.028 0 .046-.015.073-.015l5.938.044c.688 0 1.245.564 1.245 1.259s-.557 1.258-1.245 1.258l-2.7-.031c4.312 4.607 6.023 11.109 4.544 17.274h.01Zm-33.864 6.2L9.58 35.76a1.197 1.197 0 0 1-.043.21 1.176 1.176 0 0 1-.027.128 1.212 1.212 0 0 1-.15.323c-.016.025-.018.055-.035.078-.016.023-.027.019-.04.033-.096.12-.214.22-.348.296-.03.017-.054.044-.085.059A1.22 1.22 0 0 1 8.37 37H8.3l-5.912-.034a1.252 1.252 0 0 1-1.244-1.258c0-.695.557-1.259 1.244-1.259l2.678.02C.754 29.861-.957 23.358.522 17.194a1.244 1.244 0 0 1 1.501-.93 1.26 1.26 0 0 1 .92 1.52C1.642 23.24 3.208 28.988 7.09 33l.025-3.266a1.251 1.251 0 0 1 1.245-1.248c.335-.006.659.126.897.365s.37.565.368.904Z"
				fill={fill}
				fillRule="nonzero"
			/>
		</svg>
	);
};

export default RotateDevice;

import { useClientSide } from "@dogstrust/src/hooks/useClientSide";
import React, { Suspense, SuspenseProps } from "react";

type Props = PropsWithChildren & SuspenseProps;
const SSRSuspense: React.FC<Props> = ({ children, fallback }) => {
	const isClientSide = useClientSide();
	if (!isClientSide) return null;
	return <Suspense fallback={fallback || null}>{children}</Suspense>;
};

export default SSRSuspense;

import React from "react";

const HeartOffsetRight: React.FC<IconProps> = ({ fill = "#FFF", ...props }) => {
	return (
		// biome-ignore lint/a11y/noSvgWithoutTitle: Aria hidden is used to hide the svg from screen readers
		<svg
			aria-hidden="true"
			focusable="false"
			viewBox={"0 0 35.28 36"}
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<defs>
				<clipPath id="border_hearts_horizontal_svg__a">
					<path
						d="M8.72 0H7.6c-.05.04-.1.12-.16.12C6.03.17 4.78.7 3.58 1.37c-.17.12-.34.25-.52.35C1.73 2.45.85 3.52.5 5.01c-.53 2.23-.68 4.4-.25 6.71.68 3.61 1.79 7.06 3.16 10.46 1.03 2.55 2.03 5.11 3.09 7.65.76 1.81 1.58 3.6 2.63 5.27.58.92.79 1.02 1.82.79 1.35-.3 2.6-.82 3.74-1.63 2.85-2.04 6.07-3.52 8.89-5.62 1.29-.95 2.64-1.81 3.96-2.73 2.59-1.81 4.67-4.09 6.22-6.84.35-.85.72-1.7 1.04-2.56.4-1.09.59-2.2.41-3.38-.2-1.28-.71-2.42-1.32-3.54-.18-.24-.36-.48-.55-.71-1.66-1.98-3.7-3.4-6.32-3.56-3.09-.19-5.71 1.04-7.86 3.24-.25.26-.46.55-.77.91-1.07-4.33-1.64-5.41-4.04-7.79A8.577 8.577 0 0 0 10.54.29C9.93.22 9.3.26 8.72.01"
						style={{
							clipRule: "evenodd",
							fill: "none",
						}}
					/>
				</clipPath>
			</defs>
			<g
				style={{
					clipPath: "url(#border_hearts_horizontal_svg__a)",
				}}
			>
				<path
					style={{
						fill,
					}}
					d="M-1-1h37.4v38H-1z"
				/>
			</g>
		</svg>
	);
};

const HeartOffsetLeft: React.FC<IconProps> = ({ fill = "#FFF", ...props }) => {
	return (
		// biome-ignore lint/a11y/noSvgWithoutTitle: Aria hidden is used to hide the svg from screen readers
		<svg
			aria-hidden="true"
			focusable="false"
			viewBox={"0 0 49 50"}
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<defs>
				<clipPath id="border_hearts_vertical_svg__a">
					<path
						d="M36.89 0h1.56c.07.06.14.16.22.16 1.96.07 3.7.8 5.37 1.74.24.16.47.34.72.48 1.84 1.02 3.06 2.5 3.55 4.58.73 3.09.95 6.11.34 9.32-.95 5.02-2.48 9.81-4.39 14.52-1.43 3.54-2.82 7.1-4.3 10.63-1.05 2.52-2.19 5-3.65 7.32-.8 1.28-1.09 1.42-2.53 1.1-1.87-.42-3.61-1.13-5.19-2.26-3.96-2.84-8.43-4.89-12.35-7.8-1.79-1.33-3.67-2.52-5.5-3.79-3.6-2.52-6.49-5.68-8.63-9.5-.48-1.18-1-2.36-1.44-3.56-.55-1.52-.83-3.05-.57-4.7.28-1.78.99-3.36 1.83-4.92.25-.33.5-.67.77-.99C5 9.58 7.84 7.61 11.48 7.39c4.29-.27 7.93 1.44 10.92 4.5.35.35.64.76 1.07 1.26 1.49-6.01 2.28-7.52 5.61-10.82 1.61-1.07 3.37-1.7 5.3-1.93.85-.1 1.72-.05 2.53-.4"
						style={{
							clipRule: "evenodd",
							fill: "none",
						}}
					/>
				</clipPath>
			</defs>
			<g
				style={{
					clipPath: "url(#border_hearts_vertical_svg__a)",
				}}
			>
				<path
					style={{
						fill,
					}}
					d="M-1-1h51v52H-1z"
				/>
			</g>
		</svg>
	);
};

interface HeartOffsetProps extends IconProps {
	className: string;
	offset: "left" | "right";
}
export const HeartOffset: React.FC<HeartOffsetProps> = ({
	offset,
	fill = "#FFF",
	...props
}) => {
	if (offset === "left") return <HeartOffsetLeft fill={fill} {...props} />;
	return <HeartOffsetRight fill={fill} {...props} />;
};
export default HeartOffset;

import React from "react";

interface RotateProps {
	rotation?: number;
	className?: string;
	height?: string;
	width?: string;
}

export const withSafeRotateSvg =
	<P extends object>(SvgComponent: React.FC<P>): React.FC<P & RotateProps> =>
	({ className, height, width, rotation = 0, ...props }: RotateProps) =>
		(
			<div
				className={className}
				style={{
					transform: `rotate(${rotation}deg)`,
					height,
					width,
				}}
			>
				<SvgComponent {...(props as P)} height={height} width={width} />
			</div>
		);

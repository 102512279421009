/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React from "react";
import Button from "../Button";
import * as styles from "./NewsletterForm.module.scss";

export const DEFAULT_TITLE = "Sign up to our newsletter";
export const DEFAULT_BODY =
	"We'll send you monthly emails, packed with info about our work and pupdates about some of our favourite furry friends.";
export interface NewsletterFormProps {
	title: string;
	body: string;
	url: string;
}

export const NewsletterForm: React.FC<NewsletterFormProps> = ({
	title = DEFAULT_TITLE,
	body = DEFAULT_BODY,
	url,
}) => {
	return (
		<div className={styles.newsletterformwrapper}>
			<h2 className={styles.newsletterformtitle}>{title}</h2>
			<p className={styles.newsletterformbody}>{body}</p>
			<div className={styles.signuplink}>
				<Button
					variant="reversetertiary"
					type="submit"
					ariaLabel="submit"
					size="small"
					fullWidth={true}
					url={url}
				>
					Sign Up
				</Button>
			</div>
		</div>
	);
};

export default NewsletterForm;

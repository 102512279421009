import React from "react";

export const MyAccountLogout: React.FC<IconProps> = ({
	height = "40px",
	width = "40px",
	fillPrimary = "#222",
	fillSecondary = "#fff",
	invert,
	...props
}) => {
	return (
		// biome-ignore lint/a11y/noSvgWithoutTitle: Aria hidden is used to hide the svg from screen readers
		<svg
			aria-hidden="true"
			focusable="false"
			width={width}
			height={height}
			viewBox="0 0 98 98"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<g filter="url(#icon_ma_logout_svg__a)">
				<path
					fill={invert ? fillSecondary : fillPrimary}
					fillRule="evenodd"
					d="M47.495 9.186c26.921-2.408 44.023 18.912 41.202 44.491-1.527 17.94-16.61 35.02-35.324 35.316-11.556.147-23.91-1.92-32.513-10.246C10.92 68.57 6.136 52.914 10.794 39.24c4.03-17.391 18.975-28.966 36.7-30.053"
					clipRule="evenodd"
				/>
			</g>
			<path
				fill={invert ? fillPrimary : fillSecondary}
				d="M40.125 67.716H33.88c-.737 0-.906-.152-1.1-.888-.285-1.073-.516-2.151-.604-3.262-.078-.964-.214-1.924-.289-2.887-.088-1.134-.178-2.27-.197-3.408a841.789 841.789 0 0 1-.1-9.679c-.004-.652.084-1.303.13-1.956l-.126-.061c-.462.35-.927.695-1.385 1.05-.504.392-1 .797-1.5 1.193-.354.278-.73.253-1.151-.103-.366-.308-.711-.64-1.069-.96-.59-.527-1.195-1.038-1.77-1.582-.255-.243-.453-.549-.719-.877.583-.469 1.103-.912 1.648-1.322a383.482 383.482 0 0 1 4.723-3.506c1.109-.806 2.253-1.563 3.367-2.362a517.719 517.719 0 0 0 5.07-3.687c1.092-.804 2.165-1.634 3.245-2.454.08-.06.158-.128.217-.207.82-1.106 2.046-1.72 3.09-2.554.345-.274.702-.536 1.068-.78.856-.574 1.731-.568 2.559.034 2.074 1.51 4.335 2.716 6.521 4.045 2.103 1.28 4.19 2.586 6.301 3.85 1.09.654 2.211 1.259 3.332 1.858.965.514 1.951.99 2.926 1.483.801.406 1.59.81 2.313 1.374.855.667 1.839 1.174 2.785 1.718.51.293.737.912.536 1.52-.367 1.11-1.133 1.915-1.966 2.66-.483.43-1.061.752-1.583 1.143-.265.198-.496.164-.748-.006-.77-.521-1.543-1.037-2.316-1.552-.101-.069-.21-.125-.408-.242.044.395.063.682.112.964.23 1.31.395 2.625.515 3.954.199 2.188.198 4.373.163 6.561-.017 1.182-.18 2.361-.282 3.542-.064.739-.115 1.479-.21 2.214a65.551 65.551 0 0 1-.407 2.678c-.112.66-.253 1.314-.388 1.969-.125.608-.13.608-.725.608-.966 0-1.939.066-2.898-.017-1.087-.093-2.202-.047-3.25-.472-.379-.153-.54-.374-.532-.783.02-.9.032-1.8 0-2.7-.035-.937-.129-1.872-.191-2.808-.04-.593-.091-1.186-.098-1.779-.013-1.223-.309-2.384-.685-3.54-.44-1.347-1.002-2.62-1.88-3.746a7.136 7.136 0 0 0-2.961-2.26c-1.301-.535-2.669-.556-4.03-.489-1.483.074-2.928.385-4.27 1.054-2.11 1.053-3.41 2.783-3.962 5.042-.269 1.096-.368 2.235-.515 3.359-.326 2.495-.176 5.005-.195 7.51-.004.488.083.978.136 1.546h-.002Z"
			/>
			<defs>
				<filter
					id="icon_ma_logout_svg__a"
					width={98}
					height={98}
					x={0}
					y={0}
					colorInterpolationFilters="sRGB"
					filterUnits="userSpaceOnUse"
				>
					<feFlood floodOpacity={0} result="BackgroundImageFix" />
					<feColorMatrix
						in="SourceAlpha"
						result="hardAlpha"
						values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
					/>
					<feOffset />
					<feGaussianBlur stdDeviation={4.5} />
					<feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.152234 0" />
					<feBlend
						in2="BackgroundImageFix"
						result="effect1_dropShadow_324_2601"
					/>
					<feBlend
						in="SourceGraphic"
						in2="effect1_dropShadow_324_2601"
						result="shape"
					/>
				</filter>
			</defs>
		</svg>
	);
};

export default MyAccountLogout;

import React from "react";

export const MyAccountFavourites: React.FC<IconProps> = ({
	height = "40px",
	width = "40px",
	fillPrimary = "#222",
	fillSecondary = "#fff",
	invert,
	...props
}) => {
	return (
		// biome-ignore lint/a11y/noSvgWithoutTitle: Aria hidden is used to hide the svg from screen readers
		<svg
			aria-hidden="true"
			focusable="false"
			width={width}
			height={height}
			viewBox="0 0 98 98"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<g filter="url(#icon_ma_favourites_svg__a)">
				<path
					fill={invert ? fillSecondary : fillPrimary}
					fillRule="evenodd"
					d="M13.764 31.62C20.556 15.62 35.221 9 52.063 9 75.963 9 89 26.93 89 49.55 89 69.412 73.246 89 52.876 89c-24.986 0-39.111-9.932-43.73-35.588-.813-6.62 1.904-16.552 4.618-21.792"
					clipRule="evenodd"
				/>
			</g>
			<path
				fill={invert ? fillPrimary : fillSecondary}
				fillRule="evenodd"
				d="M68.747 43.937c.798-2.223 1.42-7.063-.006-8.688-2.429-2.89-3.963-4.635-9.986-4.969-6.608-.417-9.895 7.78-9.918 7.841-.051-.088-.1-.18-.152-.266-.197-.368-.381-.72-.577-1.067-1.372-2.79-5.625-5.893-8.693-5.8-6.75.073-10.624 5.382-10.64 8.899-.045 9.414 5.904 14.237 9.392 18.636 1.8 2.287 5.945 5.917 7.856 8.472.004.113 2.92 2.683 4.34 2.74.86.033 5.098-5.014 6.514-7.226 11.206-14.812 8.13-11.42 11.209-17.105.343-.528.61-1.034.661-1.467"
				clipRule="evenodd"
			/>
			<defs>
				<filter
					id="icon_ma_favourites_svg__a"
					width={98.001}
					height={97.999}
					x={0}
					y={0}
					colorInterpolationFilters="sRGB"
					filterUnits="userSpaceOnUse"
				>
					<feFlood floodOpacity={0} result="BackgroundImageFix" />
					<feColorMatrix
						in="SourceAlpha"
						result="hardAlpha"
						values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
					/>
					<feOffset />
					<feGaussianBlur stdDeviation={4.5} />
					<feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.152234 0" />
					<feBlend
						in2="BackgroundImageFix"
						result="effect1_dropShadow_323_2495"
					/>
					<feBlend
						in="SourceGraphic"
						in2="effect1_dropShadow_323_2495"
						result="shape"
					/>
				</filter>
			</defs>
		</svg>
	);
};

export default MyAccountFavourites;

import React, { HTMLAttributes, useCallback, useEffect, useState } from "react";
import { useFormContext, useFormState } from "react-hook-form";
import { Controller } from "react-hook-form";
import ReactSelect, { ActionMeta, CSSObjectWithLabel } from "react-select";

import cx from "classnames";
import * as styles from "./FormSelect.module.scss";

interface Props<T = HTMLInputElement> extends HTMLAttributes<T> {
	label: string;
	name: string;
}

export interface SelectOption {
	label: string;
	value?: string;
}

export interface SelectedDropdownItem {
	name: string;
	label?: string;
	value: string;
}

const optionStyles = (
	_styles: CSSObjectWithLabel,
	{ isFocused }: { isFocused: boolean },
): CSSObjectWithLabel => ({
	display: "flex",
	fontFamily: "Work Sans",
	alignItems: "center",
	height: 32,
	paddingLeft: 8,
	borderTop: "1px solid #f4f4f4",
	backgroundColor: isFocused ? "#faf5e6" : "white",
	outline: isFocused ? "2px solid blue" : "none",
});

const controlStyles = (
	base: CSSObjectWithLabel,
	{ isFocused }: { isFocused: boolean },
): CSSObjectWithLabel => ({
	...base,
	fontFamily: "Work Sans",
	// border: 0,
	boxShadow: "none",
	boxSizing: "border-box",
	outline: isFocused ? "2px solid blue" : "none",
});

export interface FormSelectProps
	extends Omit<Props, "defaultValue" | "onChange"> {
	defaultValue?: SelectOption | SelectOption[] | null;
	controlledDefault?: SelectOption | SelectOption[] | null;
	label: string;
	options: SelectOption[];
	isMulti?: boolean;
	menuPlacement?: "top" | "bottom";
	onChange?: (
		selectedOption: SelectedDropdownItem | SelectedDropdownItem[],
	) => void;
}

export const FormSelect: React.FC<FormSelectProps> = ({
	label,
	controlledDefault,
	options,
	className,
	onChange = () => {},
	name,
	isMulti = false,
	menuPlacement = "bottom",
	placeholder,
}) => {
	const selectId = label.replace(/\s+/g, "-").toLowerCase();
	const { control } = useFormContext();
	const {
		register,
		formState: { isDirty, dirtyFields },
	} = useFormContext();
	const { errors } = useFormState();
	register(name);
	const [error, setError] = useState(dirtyFields[name]);
	useEffect(() => {
		if (isDirty) setError(errors[name]);
	}, [isDirty, errors, name]);

	// element controls its own value
	const [_value, setValue] = React.useState(controlledDefault);

	const setSelection = useCallback(
		(selectedOption: SelectOption) => {
			if (!selectedOption) {
				return onChange(null);
			}

			if (Array.isArray(selectedOption)) {
				return onChange(selectedOption);
			}

			const { label, value } = selectedOption;
			setValue({ label, value });

			const name = selectId;
			return onChange({ name, label, value });
		},
		[onChange, selectId, setValue],
	);

	return (
		<>
			<Controller
				name={name}
				control={control}
				render={({ field: { onChange: onChangeControl, ...field } }) => (
					<ReactSelect
						{...field}
						styles={{
							control: controlStyles,
							indicatorSeparator: () => null,
							option: optionStyles,
						}}
						className={cx(className, styles.select)}
						inputId={name}
						id={selectId}
						options={options}
						defaultValue={controlledDefault}
						onChange={(
							newValue: SelectOption,
							actionMeta: ActionMeta<SelectOption>,
						) => {
							onChangeControl(newValue, actionMeta);
							setSelection(newValue);
						}}
						isMulti={isMulti}
						placeholder={placeholder}
						menuPlacement={menuPlacement}
						isSearchable={false}
						aria-invalid={!!error}
						aria-describedby={name}
						aria-labelledby={name}
					/>
				)}
			/>
			{!!error && (
				<p id={name} className={styles.errormessage}>
					{error.message}
				</p>
			)}
		</>
	);
};

export default FormSelect;

import React from "react";

export const Likes: React.FC<IconProps> = ({
	height = "88px",
	width = "88px",
	...props
}) => {
	return (
		// biome-ignore lint/a11y/noSvgWithoutTitle: Aria hidden is used to hide the svg from screen readers
		<svg
			aria-hidden="true"
			focusable="false"
			width={width}
			height={height}
			viewBox="0 0 88 88"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<defs>
				<clipPath id="icon_likes_svg__b">
					<path
						className="icon_likes_svg__g"
						d="M42.76 11.15c22.21-1.99 36.32 15.6 33.99 36.71-1.26 14.8-13.7 28.89-29.14 29.14-9.53.12-19.73-1.58-26.82-8.45-8.2-8.4-12.15-21.31-8.3-32.59 3.32-14.35 15.65-23.9 30.28-24.79"
					/>
				</clipPath>
				<clipPath id="icon_likes_svg__c">
					<path
						className="icon_likes_svg__f"
						d="M6.74 7.95h74.12v71.08H6.74z"
					/>
				</clipPath>
				<clipPath id="icon_likes_svg__d">
					<path
						className="icon_likes_svg__f"
						d="M7.75 8.97h72.09v69.05H7.75z"
					/>
				</clipPath>
				<clipPath id="icon_likes_svg__e">
					<path
						className="icon_likes_svg__g"
						d="M63.77 41.13c.81-2.26 1.44-7.17 0-8.82-2.47-2.93-4.02-4.71-10.14-5.05-6.71-.42-10.05 7.9-10.07 7.96l-.15-.27c-.2-.37-.39-.73-.59-1.08-1.39-2.83-5.71-5.98-8.83-5.89-6.85.07-10.79 5.46-10.8 9.04-.04 9.56 6 14.46 9.54 18.92 1.83 2.32 6.04 6.01 7.98 8.6 0 .11 2.96 2.72 4.41 2.78.87.03 5.18-5.09 6.61-7.34 11.38-15.04 8.25-11.6 11.38-17.37.35-.54.62-1.05.67-1.49"
					/>
				</clipPath>
				<style>
					{
						".icon_likes_svg__f,.icon_likes_svg__g{fill:none}.icon_likes_svg__j{fill:#272727}.icon_likes_svg__g{clip-rule:evenodd}"
					}
				</style>
			</defs>
			<g
				style={{
					clipPath: "url(#icon_likes_svg__b)",
				}}
			>
				<g
					style={{
						isolation: "isolate",
					}}
				>
					<g
						style={{
							clipPath: "url(#icon_likes_svg__c)",
						}}
					>
						<g
							style={{
								clipPath: "url(#icon_likes_svg__d)",
							}}
						>
							<path
								style={{
									fill: "#fff",
								}}
								d="M6.74 7.95h74.12v71.08H6.74z"
							/>
							<path
								className="icon_likes_svg__j"
								d="M6.74 7.95h74.12v71.08H6.74z"
							/>
						</g>
					</g>
				</g>
			</g>
			<g
				style={{
					clipPath: "url(#icon_likes_svg__e)",
				}}
			>
				<path
					style={{
						fill: "#f0c62f",
					}}
					d="M22.12 25.82h44.1v42.55h-44.1z"
				/>
			</g>
			<path
				className="icon_likes_svg__j"
				d="M45.1 68.34h-.03c-1.4-.06-4.27-2.05-5.13-3.22l-.05.03c-1-1.33-2.66-3-4.26-4.62-1.45-1.46-2.82-2.84-3.7-3.96-.59-.75-1.27-1.52-1.98-2.33-3.48-3.98-7.82-8.93-7.78-17.22.02-4.05 4.24-9.96 11.81-10.05 3.51-.12 7.84 3.08 9.54 6.05 1.49-2.63 4.83-7.12 10.18-6.77 6.31.35 8.15 2.19 10.85 5.41 1.9 2.16.85 7.77.22 9.71-.08.52-.34 1.08-.81 1.81-.74 1.36-1.11 2.2-1.44 2.93-1.01 2.25-1.43 3.19-9.98 14.5 0 .01-5.41 7.74-7.43 7.74Zm-.21-1.99Zm-3.36-2.39c.52.55 2.56 2.12 3.47 2.33 1.01-.65 4.48-4.67 5.87-6.85 8.46-11.19 8.88-12.12 9.79-14.17.33-.73.73-1.64 1.55-3.14.44-.68.54-.98.56-1.12l.05-.21c.87-2.56 1.13-6.76.19-7.83-2.38-2.83-3.68-4.38-9.43-4.7-5.93-.39-9.04 7.24-9.07 7.32l-.78 1.95-1.3-2.28c-.16-.31-.32-.6-.49-.9-1.22-2.47-5.14-5.45-7.91-5.37-6.52.07-9.81 5.18-9.82 8.02-.03 7.53 3.85 11.96 7.27 15.88.73.83 1.42 1.62 2.04 2.41.82 1.04 2.14 2.37 3.55 3.79 1.65 1.67 3.36 3.39 4.45 4.84l.02.02Z"
			/>
		</svg>
	);
};

export default Likes;

import React, { useEffect, useState } from "react";

/** 'useOneTrust' has been added for accessibility purposes
 *
 * Without this, screen readers on iOS are placed in the footer after closing the cookie notice
 *
 * With this, the page focus is shifted to the top of the page
 */

export const useOneTrust = () => {
	const [isSetUp, setIsSetUp] = useState(false);
	const [hasOneTrustLoaded, setHasOneTrustLoaded] = useState(false);

	// Wait until OneTrust has loaded (so we have access to its properties)
	useEffect(() => {
		const interval = setInterval(function () {
			if (window.OneTrust) {
				setHasOneTrustLoaded(true);
				clearInterval(interval);
			}
		}, 500);
		return () => clearInterval(interval);
	}, []);

	// Focus on our web content wrapper
	useEffect(() => {
		if (!isSetUp && hasOneTrustLoaded) {
			window.OneTrust.OnConsentChanged(() =>
				document.getElementById("gatsby-focus-wrapper").focus(),
			);
			setIsSetUp(true);
		}
	}, [isSetUp, hasOneTrustLoaded]);

	return isSetUp;
};

import cx from "classnames";
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/unbound-method */
import React, { HTMLProps } from "react";
import { useFormContext, useFormState } from "react-hook-form";
import * as styles from "./FormTextInput.module.scss";
export interface FormTextInputProps extends HTMLProps<HTMLInputElement> {
	type?: "text" | "email" | "password";
	placeholder: string;
	ariaLabel?: string;
	IconLeft?: React.FC<IconProps>;
	iconLeftDimensions?: string;
	IconRight?: React.FC<IconProps>;
	iconRightDimensions?: string;
	onIconLeftCallback?: () => void;
	onIconRightCallback?: () => void;
	onEnterCallback?: () => void;
	iconPosition?: "left" | "right";
	errorMessage?: string;
	maxLength?: number;
}

export const FormTextInput: React.FC<FormTextInputProps> = ({
	label,
	name,
	ariaLabel = "text-input",
	type = "text",
	...rest
}) => {
	const { register } = useFormContext();
	const { errors } = useFormState();
	return (
		<div className={styles.textinputwrapper}>
			<TextInput
				id={name}
				type={type}
				ariaLabel={ariaLabel}
				label={label}
				aria-invalid={!!errors[name]}
				aria-describedby={`${ariaLabel}-${name}`}
				{...rest}
				{...register(name)}
			/>

			{!!errors[name] && (
				<p id={`${ariaLabel}-${name}`} className={styles.errormessage}>
					{errors[name].message}
				</p>
			)}
		</div>
	);
};

export default FormTextInput;

export interface TextInputProps extends FormTextInputProps {
	label?: string;
	name: string;
	type?: "text" | "email" | "password";
	placeholder: string;
	ariaLabel?: string;
	IconLeft?: React.FC<IconProps>;
	iconLeftDimensions?: string;
	IconRight?: React.FC<IconProps>;
	iconRightDimensions?: string;
	onIconLeftCallback?: () => void;
	onIconRightCallback?: () => void;
	onEnterCallback?: () => void;
	iconPosition?: "left" | "right";
	errorMessage?: string;
	maxLength?: number;
}

export const TextInput: React.FC<TextInputProps> = ({
	label,
	name,
	IconLeft,
	IconRight,
	onIconLeftCallback,
	onIconRightCallback,
	onEnterCallback,
	className,
	iconLeftDimensions = "25px",
	iconRightDimensions = "25px",
	...rest
}) => {
	const getInputStateStyles = () => {
		return styles.formtextinput;
	};

	return (
		<div className={styles.textinputwrapper}>
			{label && (
				<label htmlFor={name} className={styles.textinputlabel}>
					{label}
				</label>
			)}
			<div className={styles.inputwithicon}>
				{IconLeft && (
					<button
						type="button"
						className={styles.iconleft}
						onClick={onIconLeftCallback}
					>
						{
							<IconLeft
								height={iconLeftDimensions}
								width={iconLeftDimensions}
							/>
						}
					</button>
				)}
				{IconRight && (
					<button
						type="button"
						className={styles.iconright}
						style={{
							cursor: onIconRightCallback ? "pointer" : "auto",
						}}
						onClick={onIconRightCallback}
					>
						{
							<IconRight
								height={iconRightDimensions}
								width={iconRightDimensions}
							/>
						}
					</button>
				)}
				<input
					name={name}
					tabIndex={0}
					id={label}
					autoComplete="off"
					className={cx(className, getInputStateStyles())}
					aria-label={rest.placeholder}
					onKeyDown={(e) =>
						e.key === "Enter" && onEnterCallback && onEnterCallback()
					}
					title={label}
					{...rest}
				/>
			</div>
		</div>
	);
};

// The following functions are adapted from this SO answer https://stackoverflow.com/a/18883819

import CryptoJS from "crypto-js";
import {
	DogSearchQueryVariables,
	SearchFrom,
	SortOption,
} from "./DogSearchApi.types";

export function isNumber(n: string) {
	return !Number.isNaN(parseFloat(n)) && !Number.isNaN(parseFloat(n) - 0);
}

export const encryptData = (text: string) => {
	if (!text) return null;
	const data = CryptoJS.AES.encrypt(
		JSON.stringify(text),
		process.env.GATSBY_ENCRYPT_KEY,
	).toString();

	return data;
};
export const decryptData = (text: string) => {
	if (!text) return null;
	if (typeof text === "object") return JSON.stringify(text);
	const bytes = CryptoJS.AES.decrypt(text, process.env.GATSBY_ENCRYPT_KEY);
	const data = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
	return data;
};

const getUserLocation = (searchFrom: string, userLocation: SearchFrom) => {
	if (userLocation) return userLocation;
	const parsedSearchFrom = JSON.parse(decryptData(searchFrom)) as SearchFrom;
	return parsedSearchFrom;
};
/**
 * Maps the query variables back to the state object
 * @param queryVars the parsed query params to map
 * @returns {(partial) DogSearchApiState}
 */
export const parseQueryVarsToState = (queryVars: DogSearchQueryVariables) => {
	const {
		sort,
		page,
		breed,
		age,
		size,
		gender,
		centres,
		county,
		daysSinceAdded,
		liveWithCats,
		liveWithDogs,
		liveWithPreschool,
		liveWithPrimary,
		liveWithSecondary,
		userLocation,
		searchFrom,
		currentDistance,
		clearPagination,
		isUnderdog,
		noReserved,
	} = queryVars;
	const queryVarsKeys = Object.keys(queryVars);
	const selectedLivesWith: string[] = [];
	if (liveWithCats === "true") selectedLivesWith.push("Cats");
	if (liveWithDogs === "true") selectedLivesWith.push("Dogs");
	if (liveWithPreschool === "true")
		selectedLivesWith.push("Pre-school children");
	if (liveWithPrimary === "true")
		selectedLivesWith.push("Primary school children");
	if (liveWithSecondary === "true")
		selectedLivesWith.push("Secondary school children");
	const locationFromQS = getUserLocation(searchFrom, userLocation);

	return {
		sortBy: (sort as SortOption) || "NEW",
		pagination: parseInt(page) || 0,
		selectedBreeds: breed || [],
		tempSelectedBreeds: breed || [],
		selectedCounty: county || "",
		selectedAges: age || [],
		selectedSizes: size || [],
		selectedGenders: gender || [],
		selectedCentres: centres || [],
		selectedDayRange: daysSinceAdded ? daysSinceAdded.toString() : "Any",
		selectedLivesWith,
		locationSearchTerm: locationFromQS ? locationFromQS.address : "",
		currentDistance: locationFromQS ? parseFloat(currentDistance) : 1000,
		userLocation: locationFromQS
			? ({
					...locationFromQS,
					latitude: parseFloat(locationFromQS.latitude),
					longitude: parseFloat(locationFromQS.longitude),
			  } as Place)
			: undefined,
		clearPagination:
			(queryVarsKeys.length === 1 && queryVarsKeys[0] === "centres") ||
			clearPagination === "true",
		isUnderdog: isUnderdog === "true",
		noReserved: noReserved === "true",
	};
};

import React from "react";

export const SocialFacebook: React.FC<IconProps> = ({
	height = "40px",
	width = "40px",
	fillPrimary = "#FFC800",
	fillSecondary = "#222",
	invert,
	...props
}) => {
	return (
		// biome-ignore lint/a11y/noSvgWithoutTitle: Aria hidden is used to hide the svg from screen readers
		<svg
			aria-hidden="true"
			focusable="false"
			width={width}
			height={height}
			viewBox="0 0 40 40"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<g fill="none" fillRule="evenodd">
				<circle
					fill={invert ? fillSecondary : fillPrimary}
					cx={20}
					cy={20}
					r={20}
				/>
				<path
					d="M21.68 30.5v-8.945h2.851L25 18h-3.32v-2.46c0-.574.104-.99.312-1.25.26-.313.742-.47 1.445-.47h1.875v-3.164c-.703-.104-1.614-.156-2.734-.156-1.38 0-2.487.41-3.32 1.23-.834.82-1.25 1.973-1.25 3.458V18H15v3.555h3.008V30.5h3.672z"
					fill={invert ? fillPrimary : fillSecondary}
					fillRule="nonzero"
				/>
			</g>
		</svg>
	);
};

export default SocialFacebook;

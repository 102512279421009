import React from "react";

export const DownloadPPT: React.FC<IconProps> = ({
	height = "26px",
	width = "26px",
	fill = "#FFF",
	...props
}) => {
	return (
		// biome-ignore lint/a11y/noSvgWithoutTitle: Aria hidden is used to hide the svg from screen readers
		<svg
			aria-hidden="true"
			focusable="false"
			width={width}
			height={height}
			viewBox="0 0 100 100"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<defs>
				<style>
					{`.icon_download_ppt_svg__b{fill:${fill}}.icon_download_ppt_svg__c{fill:#272727}`}
				</style>
			</defs>
			<path
				className="icon_download_ppt_svg__c"
				d="M46.36 57.39v-3.35c-1.44.43-2.5 1.77-2.5 3.35 0 1.93 1.57 3.5 3.5 3.5 1.58 0 2.92-1.05 3.35-2.5h-3.35c-.55 0-1-.45-1-1ZM54.87 40.51c0 .28.22.5.5.5h5.76a2.01 2.01 0 0 0-.36-.48l-5.41-5.42c-.15-.15-.31-.26-.48-.36v5.76Z"
			/>
			<path
				className="icon_download_ppt_svg__c"
				d="M55.37 43.01a2.5 2.5 0 0 1-2.5-2.5V34.5H40.72c-.6 0-1.07.19-1.48.61s-.61.89-.61 1.48V63.4c0 .6.19 1.07.61 1.48.42.42.89.61 1.49.61h18.56c.6 0 1.07-.19 1.48-.61.42-.42.61-.89.61-1.48V43h-6.01Zm-13.49-2h6.98c.55 0 1 .45 1 1s-.45 1-1 1h-6.98c-.55 0-1-.45-1-1s.45-1 1-1Zm5.48 21.88c-3.03 0-5.5-2.47-5.5-5.5s2.47-5.5 5.5-5.5c.55 0 1 .45 1 1v3.5h3.5c.55 0 1 .45 1 1 0 3.03-2.47 5.5-5.5 5.5Zm6.51-8.79h-1.94c-.55 0-1-.45-1-1s.45-1 1-1h1.94c.55 0 1 .45 1 1s-.45 1-1 1Zm.95-5.85H41.88c-.55 0-1-.45-1-1s.45-1 1-1h12.94c.55 0 1 .45 1 1s-.45 1-1 1Zm3.31 5.85h-.64c-.55 0-1-.45-1-1s.45-1 1-1h.64c.55 0 1 .45 1 1s-.45 1-1 1Z"
			/>
			<path
				className="icon_download_ppt_svg__c"
				d="M66.5 22.5h-33c-6.08 0-11 5.13-11 11.46v32.08c0 6.33 4.92 11.46 11 11.46h33c6.08 0 11-5.13 11-11.46V33.96c0-6.33-4.92-11.46-11-11.46Zm-3.13 40.9c0 1.13-.4 2.11-1.2 2.9-.79.79-1.77 1.19-2.9 1.19H40.71c-1.13 0-2.11-.4-2.9-1.2a3.98 3.98 0 0 1-1.2-2.9V36.58c0-1.13.4-2.11 1.2-2.9s1.77-1.2 2.9-1.2h13.15c1.13 0 2.11.4 2.9 1.2l5.41 5.41c.79.79 1.2 1.77 1.2 2.9v21.4Z"
			/>
			<path
				className="icon_download_ppt_svg__b"
				d="M56.77 33.7a3.98 3.98 0 0 0-2.9-1.2H40.72c-1.13 0-2.11.4-2.9 1.2s-1.2 1.77-1.2 2.9v26.81c0 1.13.4 2.11 1.2 2.9.79.79 1.77 1.2 2.9 1.2h18.56c1.13 0 2.11-.4 2.9-1.19a3.98 3.98 0 0 0 1.2-2.9v-21.4c0-1.13-.4-2.11-1.2-2.9l-5.41-5.41Zm4 6.83c.15.15.26.31.36.48h-5.76c-.28 0-.5-.22-.5-.5v-5.76c.17.09.33.21.48.36l5.41 5.42Zm0 24.36c-.42.42-.89.61-1.48.61H40.73c-.6 0-1.07-.19-1.49-.61-.42-.41-.61-.89-.61-1.48V36.6c0-.6.19-1.07.61-1.48s.89-.61 1.48-.61h12.15v6.01a2.5 2.5 0 0 0 2.5 2.5h6.01v20.4c0 .6-.19 1.07-.61 1.48Z"
			/>
			<path
				className="icon_download_ppt_svg__b"
				d="M51.85 56.39h-3.5v-3.5c0-.55-.45-1-1-1-3.03 0-5.5 2.47-5.5 5.5s2.47 5.5 5.5 5.5 5.5-2.47 5.5-5.5c0-.55-.45-1-1-1Zm-4.5 4.5c-1.93 0-3.5-1.57-3.5-3.5 0-1.58 1.05-2.92 2.5-3.35v3.35c0 .55.45 1 1 1h3.35a3.507 3.507 0 0 1-3.35 2.5ZM54.81 46.24H41.87c-.55 0-1 .45-1 1s.45 1 1 1h12.94c.55 0 1-.45 1-1s-.45-1-1-1ZM41.88 43.01h6.98c.55 0 1-.45 1-1s-.45-1-1-1h-6.98c-.55 0-1 .45-1 1s.45 1 1 1ZM53.87 52.1h-1.94c-.55 0-1 .45-1 1s.45 1 1 1h1.94c.55 0 1-.45 1-1s-.45-1-1-1ZM58.12 52.1h-.64c-.55 0-1 .45-1 1s.45 1 1 1h.64c.55 0 1-.45 1-1s-.45-1-1-1Z"
			/>
		</svg>
	);
};

export default DownloadPPT;

import cx from "classnames";
/* eslint-disable @typescript-eslint/unbound-method */
import React, { HTMLAttributes } from "react";

import { useFormContext } from "react-hook-form";
import * as styles from "./FormRadioButton.module.scss";

export interface FormRadioButtonProps extends HTMLAttributes<HTMLElement> {
	label: string;
	value: string;
	name: string;
	defaultChecked?: boolean;
}

export const FormRadioButton: React.FC<FormRadioButtonProps> = ({
	className,
	label,
	name,
	value,
	defaultChecked,
	...rest
}) => {
	const { register } = useFormContext();
	return (
		<div key={value} className={cx([styles.radiobutton, className])}>
			<input
				id={value}
				name={name}
				type="radio"
				value={value}
				defaultChecked={defaultChecked}
				{...register}
				{...rest}
			/>
			<label htmlFor={value} className={styles.radiolabel}>
				{label}
			</label>
		</div>
	);
};

export default FormRadioButton;

import * as styles from "./LinkNoStyle.module.scss";

import { GatsbyLinkProps, Link } from "gatsby";
import React from "react";

import cx from "classnames";

export interface LinkNoStyleProps
	extends Omit<GatsbyLinkProps<Record<string, unknown>>, "ref"> {
	linkId?: string;
	visible?: boolean;
	ariaLabel?: string;
}

export const LinkNoStyle: React.FC<LinkNoStyleProps> = ({
	className,
	style,
	linkId,
	tabIndex = 0,
	visible = true,
	state,
	ariaLabel,
	...props
}) => {
	if (props.to.includes("http://") || props.to.includes("https://")) {
		return (
			<div
				key={linkId}
				className={cx(styles.linkoverride, className)}
				style={style}
			>
				<a
					tabIndex={visible ? tabIndex : -1}
					style={{
						...style,
						visibility: visible ? "visible" : "hidden",
					}}
					href={props.to}
				>
					{props.children}
				</a>
			</div>
		);
	}
	return (
		<div
			key={linkId}
			className={cx(styles.linkoverride, className)}
			style={style}
		>
			<Link
				aria-label={ariaLabel}
				id={linkId}
				style={{ ...style, visibility: visible ? "visible" : "hidden" }}
				tabIndex={visible ? tabIndex : -1}
				state={state}
				{...props}
			/>
		</div>
	);
};

export default LinkNoStyle;

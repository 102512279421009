import React from "react";

export const SocialYoutube: React.FC<IconProps> = ({
	height = "45px",
	width = "45px",
	fillPrimary = "#FFC800",
	fillSecondary = "#222",
	...props
}) => {
	return (
		// biome-ignore lint/a11y/noSvgWithoutTitle: Aria hidden is used to hide the svg from screen readers
		<svg
			aria-hidden="true"
			focusable="false"
			width={width}
			height={height}
			viewBox="0 0 100 100"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<defs>
				<clipPath id="icon_youtube_svg__b">
					<circle className="icon_youtube_svg__d" cx={50} cy={50} r={45} />
				</clipPath>
				<clipPath id="icon_youtube_svg__c">
					<path
						className="icon_youtube_svg__d"
						d="M45.22 58.65V41.46L60.3 50l-15.08 8.65Zm5.91 11.6 9.6-.11c6.75-.21 11.04-.56 12.87-1.05 1.27-.35 2.36-.98 3.27-1.9.91-.91 1.55-1.97 1.9-3.16.49-1.9.84-4.82 1.05-8.75l.11-5.27-.11-5.27c-.21-3.87-.56-6.75-1.05-8.65a7.203 7.203 0 0 0-1.9-3.27c-.91-.91-2-1.55-3.27-1.9-1.83-.49-6.12-.84-12.87-1.05l-9.6-.11-9.6.11c-6.75.21-11.04.56-12.87 1.05-1.27.35-2.36.98-3.27 1.9-.91.91-1.55 2-1.9 3.27-.49 1.9-.84 4.78-1.05 8.65-.07 1.97-.11 3.73-.11 5.27l.11 5.27c.21 3.94.56 6.86 1.05 8.75.35 1.2.98 2.25 1.9 3.16.91.91 2 1.55 3.27 1.9 1.83.49 6.12.84 12.87 1.05l9.6.11Z"
					/>
				</clipPath>
				<style>{".icon_youtube_svg__d{fill:none}"}</style>
			</defs>
			<g
				style={{
					clipPath: "url(#icon_youtube_svg__b)",
				}}
			>
				<path
					style={{
						fill: fillPrimary,
					}}
					d="M2.75 2.75h94.5v94.5H2.75z"
				/>
			</g>
			<g
				style={{
					clipPath: "url(#icon_youtube_svg__c)",
				}}
			>
				<path
					style={{
						fill: fillSecondary,
					}}
					d="M20.08 27.5h62.09v45H20.08z"
				/>
			</g>
		</svg>
	);
};

export default SocialYoutube;

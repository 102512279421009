import React from "react";

export const DownloadSheet: React.FC<IconProps> = ({
	height = "26px",
	width = "26px",
	fill = "#FFF",
	...props
}) => {
	return (
		// biome-ignore lint/a11y/noSvgWithoutTitle: Aria hidden is used to hide the svg from screen readers
		<svg
			aria-hidden="true"
			focusable="false"
			width={width}
			height={height}
			viewBox="0 0 100 100"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<defs>
				<style>
					{`.icon_download_spreadsheet_svg__b{fill:${fill}}.icon_download_spreadsheet_svg__c{fill:#272727}`}
				</style>
			</defs>
			<path
				className="icon_download_spreadsheet_svg__c"
				d="M43.49 50.09H49v3.02h-5.51zM43.49 55.11H49v3.02h-5.51zM54.87 40.51c0 .28.22.5.5.5h5.76a2.01 2.01 0 0 0-.36-.48l-5.42-5.42c-.15-.15-.31-.26-.48-.36v5.75Z"
			/>
			<path
				className="icon_download_spreadsheet_svg__c"
				d="M66.5 22.5h-33c-6.08 0-11 5.13-11 11.46v32.08c0 6.33 4.92 11.46 11 11.46h33c6.08 0 11-5.13 11-11.46V33.96c0-6.33-4.92-11.46-11-11.46Zm-3.13 40.9c0 1.13-.4 2.11-1.2 2.9-.79.79-1.77 1.19-2.9 1.19H40.71c-1.13 0-2.11-.4-2.9-1.2a3.98 3.98 0 0 1-1.2-2.9V36.58c0-1.13.4-2.11 1.2-2.9s1.77-1.2 2.9-1.2h13.15c1.13 0 2.1.4 2.9 1.2l5.41 5.41c.79.79 1.2 1.77 1.2 2.9v21.4Z"
			/>
			<path
				className="icon_download_spreadsheet_svg__c"
				d="M51 50.09h5.51v3.02H51z"
			/>
			<path
				className="icon_download_spreadsheet_svg__c"
				d="M55.37 43.01a2.5 2.5 0 0 1-2.5-2.5V34.5H40.72c-.6 0-1.07.19-1.48.61s-.61.89-.61 1.48V63.4c0 .6.19 1.07.61 1.48.42.42.89.61 1.49.61h18.56c.6 0 1.07-.19 1.48-.61.42-.42.61-.89.61-1.48V43h-6.01Zm3.14 11.11c0 .55-.45 1-1 1H51v4.02c0 .55-.45 1-1 1h-7.51c-.55 0-1-.45-1-1V49.09c0-.55.45-1 1-1H57.5c.55 0 1 .45 1 1v5.02Z"
			/>
			<path
				className="icon_download_spreadsheet_svg__b"
				d="M56.76 33.7a3.98 3.98 0 0 0-2.9-1.2H40.71c-1.13 0-2.11.4-2.9 1.2s-1.2 1.77-1.2 2.9v26.81c0 1.13.4 2.11 1.2 2.9.79.79 1.77 1.2 2.9 1.2h18.56c1.13 0 2.11-.4 2.9-1.19a3.98 3.98 0 0 0 1.2-2.9v-21.4c0-1.13-.4-2.11-1.2-2.9l-5.41-5.41Zm4 6.83c.15.15.26.31.36.48h-5.76c-.28 0-.5-.22-.5-.5v-5.75c.17.09.33.21.48.36l5.42 5.42Zm0 24.36c-.42.42-.89.61-1.48.61H40.72c-.6 0-1.07-.19-1.49-.61-.42-.41-.61-.89-.61-1.48V36.6c0-.6.19-1.07.61-1.48s.89-.61 1.48-.61h12.15v6.01a2.5 2.5 0 0 0 2.5 2.5h6.01v20.4c0 .6-.19 1.07-.61 1.48Z"
			/>
			<path
				className="icon_download_spreadsheet_svg__b"
				d="M57.51 48.09H42.5c-.55 0-1 .45-1 1v10.05c0 .55.45 1 1 1h7.51c.55 0 1-.45 1-1v-4.02h6.51c.55 0 1-.45 1-1V49.1c0-.55-.45-1-1-1ZM49 58.14h-5.51v-3.02H49v3.02Zm0-5.02h-5.51V50.1H49v3.02Zm7.51 0H51V50.1h5.51v3.02Z"
			/>
		</svg>
	);
};

export default DownloadSheet;

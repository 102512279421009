import React from "react";

export const GPS: React.FC<IconProps> = ({
	height = "26px",
	width = "26px",
	fill = "#222",
	...props
}) => {
	return (
		// biome-ignore lint/a11y/noSvgWithoutTitle: Aria hidden is used to hide the svg from screen readers
		<svg
			aria-hidden="true"
			focusable="false"
			width={width}
			height={height}
			viewBox="0 0 26 26"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<defs>
				<path
					d="M13.352 22.25c.116 0 .21-.095.21-.21v-1.217a7.595 7.595 0 007.01-7.01h1.217c.116 0 .211-.095.211-.211v-.704a.211.211 0 00-.211-.21h-1.216a7.595 7.595 0 00-7.01-7.01V4.46a.211.211 0 00-.211-.211h-.704a.211.211 0 00-.21.211v1.216a7.595 7.595 0 00-7.011 7.01H4.211a.211.211 0 00-.211.211v.704c0 .116.095.21.21.21h1.217a7.595 7.595 0 007.01 7.011v1.216c0 .116.095.211.211.211h.704zm.21-2.556v-2.296a.211.211 0 00-.21-.21h-.704a.211.211 0 00-.21.21v2.296a6.466 6.466 0 01-5.882-5.881h2.296c.116 0 .21-.095.21-.211v-.704a.211.211 0 00-.21-.21H6.556a6.466 6.466 0 015.881-5.882v2.296c0 .116.095.21.211.21h.704c.116 0 .21-.094.21-.21V6.806a6.466 6.466 0 015.882 5.882h-2.296a.211.211 0 00-.21.21v.704c0 .116.094.21.21.21h2.296a6.466 6.466 0 01-5.882 5.882zm-.562-5.6a.844.844 0 100-1.688.844.844 0 000 1.688z"
					id="icon_gps_svg__a"
				/>
			</defs>
			<g fill="none" fillRule="evenodd">
				<circle stroke="#DDD" cx={13} cy={13} r={12} />
				<use fill={fill} fillRule="nonzero" xlinkHref="#icon_gps_svg__a" />
			</g>
		</svg>
	);
};

export default GPS;

import "iframe-resizer";
import React, { type ReactNode } from "react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { HelmetProvider } from "react-helmet-async";
import { CmsMapProvider } from "./context/CmsMap/CmsMap.provider";
import { DogSearchApiProvider } from "./context/DogSearchApi/DogSearchApi.provider";
import FavouritesManagerProvider from "./context/FavouritesManager/FavouritesManager.provider";
import { MyAccountProvider } from "./context/MyAccount/MyAccount.provider";
import { PageManagerProvider } from "./context/PageManager/PageManager.provider";
import "./style/theme.scss";

interface Props {
	element: ReactNode;
}

const RootElement: React.FC<Props> = ({ element }) => (
	<GoogleReCaptchaProvider
		reCaptchaKey={process.env.GATSBY_GOOGLE_RECAPTCHA_SITE_KEY}
		useRecaptchaNet={true}
		useEnterprise={true}
		scriptProps={{
			async: false,
			defer: false,
			appendTo: "head",
		}}
	>
		<PageManagerProvider>
			<MyAccountProvider>
				<HelmetProvider>
					<CmsMapProvider>
						<DogSearchApiProvider>
							<FavouritesManagerProvider>{element}</FavouritesManagerProvider>
						</DogSearchApiProvider>
					</CmsMapProvider>
				</HelmetProvider>
			</MyAccountProvider>
		</PageManagerProvider>
	</GoogleReCaptchaProvider>
);

export const wrapRootElement = RootElement;

import React from "react";

export const Sort: React.FC<IconProps> = ({
	height = "30px",
	width = "30px",
	...props
}) => {
	return (
		// biome-ignore lint/a11y/noSvgWithoutTitle: Aria hidden is used to hide the svg from screen readers
		<svg
			aria-hidden="true"
			focusable="false"
			viewBox="0 0 25 25"
			height={height}
			width={width}
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M9.87 2.133c.435-.02.805.382.805.805v10.685l.258-.287 1.272-1.424c.302-.334.834-.281 1.14 0 .334.306.281.826 0 1.14-.121.137-.242.27-.363.406l-2.544 2.847c-.278.31-.862.31-1.14 0l-.362-.407-2.545-2.846c-.297-.338-.306-.805 0-1.14.282-.31.858-.314 1.14 0 .12.137.241.27.362.407l1.171 1.31V2.939c0-.414.312-.73.699-.795zM4.278.233l.18.203.182.203 2.545 2.847c.302.338.31.8 0 1.14-.282.31-.858.313-1.14 0-.12-.138-.241-.27-.362-.408L4.515 2.912V13.6c0 .451-.37.786-.805.806-.401.018-.748-.323-.799-.709l-.006-.097-.001-10.69-.26.293L1.37 4.625c-.298.338-.83.282-1.14 0-.306-.28-.287-.74-.065-1.057l.065-.082.363-.407L3.138.233c.278-.31.862-.31 1.14 0z"
				id="icon_sort_svg__a"
			/>
		</svg>
	);
};

export default Sort;

import { useOneTrust } from "@dogstrust/src/hooks/useOneTrust";
import { Colours } from "@dogstrust/src/utils/colours";
import cx from "classnames";
import React, { useCallback, useEffect, useState } from "react";
import {
	SectionCurvedBorderBottom,
	SectionCurvedBorderBottomWithIcon,
	SectionCurvedBorderTop,
	SectionCurvedBorderTopWithImage,
	SectionFurryBorderTop,
	SectionFurryCurvedBorderBottom,
	SectionFuzzyBorderBottom,
	SectionFuzzyBorderTop,
} from "./PageManager.Borders";
import { PageManagerContext } from "./PageManager.context";
import * as styles from "./PageManager.module.scss";

const NO_SECTION_BREAKS = [
	"SectionQuickLinks",
	"ParagraphTextArea",
	"ParagraphText",
	"SectionTextOnlySignpostCard",
	"SectionSearchSite",
	"SectionDogProfileShare",
	"SectionLargeFeaturedPromo",
	"ParagraphGroupOfExpandableSections",
	"ParagraphMissionStatement",
	"ParagraphCaseStudy",
	"ParagraphKeyFactsFigures",
	"ParagraphQuote",
	"ParagraphTextAndImage",
	"ParagraphDogSizes",
	"SectionBreedCarousel",
	"SectionBreedSearch",
	"SectionBreedSearchFilteredList",
];
const FULLWIDTH_SECTIONS = [
	"SectionRehomingCentreKeyDetails",
	"SectionDogProfileShare",
	"SectionDogSearchSuggestions",
	"SectionLargeFeaturedPromo",
	"ParagraphMissionStatement",
	"ParagraphCaseStudy",
	"ParagraphDogSizes",
];
const XLARGE_SECTIONS = [
	"SectionSpotlightDogs",
	"SectionCentrePromotedDogs",
	"SectionDogBio",
	"SectionQuickLinks",
	"SectionQuickLinksEmbedded",
	"SectionRehomingCentreKeyDetails",
	"SectionSignPostCardCarousel",
	"SectionBreedDogs",
	"SectionStandardSignPostCards",
	"SectionArticleOrEventCards",
	"RehomingCentrePageDetails",
	"SectionOurStoriesFilteredListResults",
	"SectionLoadMoreArticleOrEventCards",
	"SectionDynamicPromotedDogs",
	"SectionUnderdogPromotedDogs",
];

const NO_MARGIN_SECTIONS = ["SectionSearchSite"];
const XXLARGE_SECTIONS = [
	"SectionThreeColCardsDoubleFeature",
	"SectionThreeColCardsOneFeature",
	"SectionSearchSite",
	"SectionOurStoriesFilteredListResultsIntro",
	"SectionOurStoriesListByTopicResults",
	"ParagraphAlignedImageAndText",
	"SectionOurStoriesCarouselByTopic",
	"SectionBreedCarousel",
	"SectionBreedSearch",
	"SectionBreedSearchFilteredList",
];

const HIDE_ON_DESKTOP_SECTIONS = ["SectionDogProfileShare"];
const XXLARGE_SECTIONS_NO_PADDING = ["SectionCmsMap"];
interface PageManagerProviderProps {
	children: React.ReactNode;
}

export const PageManagerProvider: React.FC<PageManagerProviderProps> = ({
	children,
}) => {
	useOneTrust();
	const [showPagination, setShowPagination] = useState<boolean>(false);
	const [pagination, setPagination] = useState<PaginationProps | null>();
	const [contentWidth, setContentWidth] = useState<GridWidth>("DEFAULT");
	const [sectionList, setSectionList] = useState<SectionData[]>([]);

	const nextPage = useCallback(() => {
		if (pagination?.currentPage < pagination?.pages.length) {
			setPagination((currentPagination) => ({
				...currentPagination,
				currentPage: currentPagination.currentPage + 1,
			}));
		}
	}, [pagination]);

	const previousPage = useCallback(() => {
		if (pagination?.currentPage > 0) {
			setPagination((currentPagination) => ({
				...currentPagination,
				currentPage: currentPagination.currentPage - 1,
			}));
		}
	}, [pagination]);

	/**
	 * Registers a section with the page manager
	 * The page manager keeps an array of all the sections on the page with
	 * key data which is used to render borders and other sections
	 * @param sectionType
	 * @param sectionId
	 * @param primaryColour
	 * @param borderImage
	 */
	const registerSection = (
		sectionType: string,
		sectionId: string,
		primaryColour: string,
		borderTopImage?: ImageSourceProps,
		borderBottomImage?: ImageSourceProps,
		customBorder?: string,
	) => {
		setSectionList((currentList) => [
			...currentList,
			{
				sectionType,
				sectionId,
				primaryColour,
				borderTopImage,
				borderBottomImage,
				customBorder,
			},
		]);
	};

	const usePagination = useCallback((newShowPagination: boolean) => {
		setShowPagination(newShowPagination);
	}, []);

	const loadPagination = useCallback(
		(storedPages: string[], totalPages: number, listingPageUrl: string) => {
			setPagination({
				pages: storedPages,
				currentPage: 0,
				totalPages,
				listingPageUrl,
			});
		},
		[],
	);

	const getDogIndex = useCallback(
		(dogUrl: string) => {
			if (!pagination) return;
			const dogIndex = pagination.pages.findIndex((page) => page === dogUrl);
			setPagination((currentPagination) => ({
				...currentPagination,
				currentPage: dogIndex,
			}));
		},
		[pagination],
	);
	const defaultWrapper = styles.sectionwrapper;

	const deregisterPage = useCallback(() => {
		setSectionList([]);
	}, []);

	useEffect(() => {
		window.history.state;
	}, []);

	const getParagraphContentSectionTheme = (sectionId: string): string => {
		const section = sectionList.find(
			(section) => section?.sectionId === sectionId,
		);
		if (section?.sectionType !== "ParagraphContentSection") return null;

		const previousSection =
			sectionList[
				sectionList.findIndex((section) => section?.sectionId === sectionId) - 1
			];

		// If it's the first content section it's always the primary colour
		if (previousSection?.sectionType !== "ParagraphContentSection") {
			return section?.primaryColour;
		}

		const previousSectionTheme = getParagraphContentSectionTheme(
			previousSection?.sectionId,
		);
		if (previousSectionTheme === Colours.WHITE) return section?.primaryColour;

		return Colours.WHITE;
	};
	/**
	 * Gets the inner section wrapper class name - this is the section that
	 * provides the grid width
	 * @param sectionId
	 * @returns
	 */
	const getInnerSectionClassName = (sectionId: string) => {
		const section = sectionList.find(
			(section) => section?.sectionId === sectionId,
		);
		const sectionType = section?.sectionType;

		if (FULLWIDTH_SECTIONS.includes(sectionType)) {
			return styles.fullgridwidth;
		}

		if (XLARGE_SECTIONS.includes(sectionType)) {
			return styles.xlargegridwidth;
		}

		if (XXLARGE_SECTIONS.includes(sectionType)) {
			return styles.xxlargegridwidth;
		}

		if (XXLARGE_SECTIONS_NO_PADDING.includes(sectionType)) {
			return styles.xxlargegridwidthnopadding;
		}

		if (contentWidth === "FULL") {
			return styles.fullgridwidth;
		}

		if (contentWidth === "XLARGE") {
			return styles.xlargegridwidth;
		}

		if (contentWidth === "XXLARGE") {
			return styles.xxlargegridwidth;
		}

		return styles.defaultgridwidth;
	};

	/**
	 * Gets the outer section wrapper class name based on the section's primary colour
	 * The outer section is full width and has a background colour and provides
	 * margins and paddings. The section then sits inside this wrapper
	 * @param sectionId
	 * @returns
	 */
	const getOuterSectionClassName = (sectionId: string, isHomePage: boolean) => {
		const section = sectionList.find(
			(section) => section?.sectionId === sectionId,
		);

		const previousSection =
			sectionList[
				sectionList.findIndex((section) => section?.sectionId === sectionId) - 1
			];
		let spacing = styles.defaultsectionspacing;
		let hideOnDesktop = false;

		if (
			[
				"SectionQuickLinks",
				"SectionQuickLinksEmbedded",
				"ParagraphButton",
				"SectionLargeFeaturedPromo",
				"ParagraphCaseStudy",
				"ParagraphDogSizes",
			].includes(section?.sectionType)
		) {
			spacing = styles.sectionnospacing;
		}
		if (isHomePage && ["SectionQuickLinks"].includes(section?.sectionType)) {
			spacing = styles.sectionspacinghomequicklinks;
		}
		if (["SectionSearchSite"].includes(section?.sectionType)) {
			spacing = styles.sectionnospacingbottom;
		}
		if (["ParagraphMissionStatement"].includes(section?.sectionType)) {
			spacing = isHomePage
				? styles.sectionnospacing
				: styles.sectionnospacingleftright;
		}
		if (HIDE_ON_DESKTOP_SECTIONS.includes(section?.sectionType)) {
			hideOnDesktop = true;
		}
		if (
			[
				"SectionThreeColCardsDoubleFeature",
				"SectionThreeColCardsOneFeature",
			].includes(section?.sectionType)
		) {
			spacing = styles.sectionspacingxxl;
		}
		if (
			section?.sectionType === "ParagraphContentSection" &&
			previousSection?.sectionType === "ParagraphContentSection"
		) {
			const previousSectionTheme = getParagraphContentSectionTheme(
				previousSection?.sectionId,
			);
			if (previousSectionTheme !== Colours.WHITE) {
				return cx(spacing, styles.sectionwrapperlight, {
					[styles.hideondesktop]: hideOnDesktop,
				});
			}
		}
		if (section?.sectionType === "ParagraphJumpLinks") {
			return cx(spacing, styles.higherindex, {
				[styles.hideondesktop]: hideOnDesktop,
			});
		}
		switch (section?.primaryColour) {
			case Colours.SUNSHINE_YELLOW:
				return cx(spacing, styles.sectionwrapperprimary, {
					[styles.hideondesktop]: hideOnDesktop,
				});
			case Colours.OATMEAL:
				return cx(spacing, styles.sectionwrapperoat, {
					[styles.hideondesktop]: hideOnDesktop,
				});
			case Colours.SANDY_FUR:
				return cx(spacing, styles.sectionwrapperalt, {
					[styles.hideondesktop]: hideOnDesktop,
				});
			case Colours.WHITE:
				return cx(spacing, styles.sectionwrapperlight, {
					[styles.hideondesktop]: hideOnDesktop,
				});
			default:
				return cx(spacing, styles.sectionwrapper, {
					[styles.hideondesktop]: hideOnDesktop,
				});
		}
	};

	const getColourBandClassName = (sectionId: string) => {
		const section = sectionList.find(
			(section) => section?.sectionId === sectionId,
		);

		const nextSection =
			sectionList[
				sectionList.findIndex((section) => section?.sectionId === sectionId) + 1
			];

		// It doesn't work to have a colour band when the sections are the same colour
		if (section?.primaryColour === nextSection?.primaryColour)
			return styles.hidden;

		// We currently only support this colour band for this section type
		if (!["ParagraphSupportWidgetPrimary"].includes(section?.sectionType))
			return styles.hidden;

		if (NO_MARGIN_SECTIONS.includes(nextSection?.sectionType)) {
			switch (nextSection?.primaryColour) {
				case Colours.SUNSHINE_YELLOW:
					return cx(styles.sectioncolourband, styles.sectionwrapperprimary);
				case Colours.OATMEAL:
					return cx(styles.sectioncolourband, styles.sectionwrapperoat);
				case Colours.SANDY_FUR:
					return cx(styles.sectioncolourband, styles.sectionwrapperalt);
				case Colours.WHITE:
					return cx(styles.sectioncolourband, styles.sectionwrapperlight);
				default:
					return styles.hidden;
			}
		}
	};

	/**
	 * Returns a function that renders a border component for the given section
	 * borders can have different styles depending on the section type and the
	 * previous section's primary colour, the border could also have an image
	 */
	const getSectionBottomBorder = useCallback(
		(sectionId: string) => {
			const section = sectionList.find(
				(section) => section?.sectionId === sectionId,
			);
			const nextSection =
				sectionList[
					sectionList.findIndex((section) => section?.sectionId === sectionId) +
						1
				];

			if (
				section?.primaryColour === nextSection?.primaryColour &&
				section?.sectionType !== "ParagraphCaseStudy"
			)
				return null;

			if (section?.customBorder === "CurvedBorderBottom")
				return (
					<SectionCurvedBorderBottom
						nextSection={nextSection}
						borderColour={section?.primaryColour}
						image={section?.borderBottomImage}
					/>
				);
			switch (section?.sectionType) {
				case "SectionSignPostCardCarouselCurvedBorderBottom":
				case "ParagraphMediaContentList":
				case "SectionEventKeyDetails":
				case "SectionDogBio":
				case "SectionOurStoriesFilteredList":
					return (
						<SectionCurvedBorderBottom
							nextSection={nextSection}
							borderColour={section?.primaryColour}
							image={section?.borderBottomImage}
						/>
					);
				case "ParagraphChecklist":
					return (
						<SectionCurvedBorderBottomWithIcon
							nextSection={nextSection}
							borderColour={section?.primaryColour}
							image={section?.borderBottomImage}
						/>
					);
				case "SectionQuickLinksEmbedded":
				case "ParagraphCaseStudy":
				case "SectionBreedSearch":
				case "SectionBreedSearchFilteredList":
					return (
						<SectionFuzzyBorderBottom
							currentSection={section}
							nextSection={nextSection}
							borderColour={section?.primaryColour}
							image={section?.borderBottomImage}
							bottomMargin={section?.sectionType === "ParagraphCaseStudy"}
						/>
					);
				case "SectionRehomingCentreKeyDetails":
				case "ParagraphHighlightedTextAreaProps":
					return (
						<SectionFurryCurvedBorderBottom
							nextSection={nextSection}
							borderColour={section?.primaryColour}
							image={section?.borderBottomImage}
						/>
					);

				default:
					return null;
			}
		},
		[sectionList],
	);

	/**
	 * Returns a function that renders a border component for the given section
	 * borders can have different styles depending on the section type and the
	 * previous section's primary colour, the border could also have an image
	 */
	const getSectionTopBorder = useCallback(
		(sectionId: string) => {
			const section = sectionList.find(
				(section) => section?.sectionId === sectionId,
			);
			const previousSection =
				sectionList[
					sectionList.findIndex((section) => section?.sectionId === sectionId) -
						1
				];

			//const isClientSide = useClientSide();

			if (!previousSection) return null;
			if (
				section?.primaryColour === previousSection?.primaryColour &&
				section?.primaryColour !== Colours.WHITE
			)
				return null;
			if (
				// By design some sections have a bottom border - we give that border precedence
				// and don't render a top border
				getSectionBottomBorder(previousSection?.sectionId) !== null ||
				// Or we can specify that a section doesn't have a top border
				["ArticlePageSocialMedia"].includes(section?.sectionType)
			) {
				return null;
			}
			switch (section?.sectionType) {
				case "ParagraphMediaContentList":
				case "ParagraphContactBlock":
				case "ParagraphPopularTopics":
				case "SectionDogSearch":
				case "SectionBehaviourHelplineFormBottom":
					return (
						<SectionCurvedBorderTop
							borderColour={section?.primaryColour}
							previousSection={previousSection}
						/>
					);
				case "SectionBreedDogs":
				case "SectionSpotlightDogs":
				case "SectionUnderdogPromotedDogs":
				case "SectionCentrePromotedDogs":
					return (
						<SectionFurryBorderTop
							previousSection={previousSection}
							borderColour={section?.primaryColour}
						/>
					);

				case "SectionSignPostCardCarousel":
				case "ParagraphSuccessStories":
					return (
						<SectionFuzzyBorderTop
							previousSection={previousSection}
							borderColour={section?.primaryColour}
						/>
					);
				case "ParagraphHighlightedQuotes":
					return (
						<SectionFuzzyBorderTop
							previousSection={previousSection}
							borderColour={section?.primaryColour}
						/>
					);

				case "ParagraphContentSection": {
					let borderColour = section?.primaryColour || Colours.WHITE;
					if (
						previousSection?.sectionType ===
						"SectionOurStoriesListByTopicResults"
					) {
						return null;
					}
					if (section?.sectionType === "ParagraphContentSection") {
						borderColour = getParagraphContentSectionTheme(section?.sectionId);
					}
					if (!section?.borderTopImage)
						return (
							<SectionCurvedBorderTop
								borderColour={borderColour}
								previousSection={previousSection}
								previousSectionTheme={getParagraphContentSectionTheme(
									previousSection?.sectionId,
								)}
							/>
						);

					return (
						<SectionCurvedBorderTopWithImage
							borderColour={borderColour}
							previousSection={previousSection}
							previousSectionTheme={getParagraphContentSectionTheme(
								previousSection?.sectionId,
							)}
							image={section?.borderTopImage}
						/>
					);
				}
				// biome-ignore lint/suspicious/noFallthroughSwitchClause: We want this to fall through
				case "ParagraphButton":
					if (previousSection?.sectionType === "ParagraphButton") return null;

				default: {
					// Ensure there is a more natural flow between text sections
					if (
						section?.sectionType === "SectionTextOnlySignpostCard" &&
						previousSection?.sectionType !== "SectionTextOnlySignpostCard"
					) {
						return (
							<div
								className={cx(
									getInnerSectionClassName(""), // This ensures the border is uniform to the page
									styles.sectionbreakdoublemargin,
								)}
							/>
						);
					}

					// Ensure there is a section break between white sections
					if (
						!!previousSection &&
						section?.sectionType !== "ParagraphButton" &&
						section?.primaryColour === Colours.WHITE &&
						previousSection?.primaryColour === Colours.WHITE &&
						!NO_SECTION_BREAKS.includes(previousSection?.sectionType)
					) {
						return (
							<div
								className={cx(
									getInnerSectionClassName(""), // This ensures the border is uniform to the page
									styles.sectionbreak,
								)}
							/>
						);
					}

					// Some sections require extra spacing above them to help the flow of the page

					const sectionsRequiringNoExtraSpacing = ["ArticlePageSocialMedia"];
					const previousSectionsRequiringNoExtraSpacing = [
						"ParagraphContentSection",
					];
					if (
						!!previousSection &&
						section?.primaryColour === Colours.WHITE &&
						previousSection?.primaryColour !== Colours.WHITE &&
						sectionsRequiringNoExtraSpacing.includes(section?.sectionType) &&
						previousSectionsRequiringNoExtraSpacing.includes(
							previousSection?.sectionType,
						) &&
						!NO_SECTION_BREAKS.includes(previousSection?.sectionType)
					)
						return <div className={styles.extratopmarginsectionspacing} />;
				}
			}
		},
		[
			sectionList,
			getParagraphContentSectionTheme,
			getSectionBottomBorder,
			getInnerSectionClassName,
		],
	);

	return (
		<PageManagerContext.Provider
			value={{
				defaultWrapper,
				sectionList,
				registerSection,
				deregisterPage,
				getInnerSectionClassName,
				getOuterSectionClassName,
				getColourBandClassName,
				getSectionTopBorder,
				getSectionBottomBorder,
				setContentWidth,
				getParagraphContentSectionTheme,
				usePagination,
				loadPagination,
				getDogIndex,
				nextPage,
				previousPage,
				showPagination,
				pagination,
			}}
		>
			{children}
		</PageManagerContext.Provider>
	);
};

import React from "react";
import { Colours } from "../../../utils/colours";

export const Location: React.FC<IconProps> = ({
	height = "30px",
	width = "30px",
	fill = Colours.MIDNIGHT,
	...props
}) => {
	return (
		// biome-ignore lint/a11y/noSvgWithoutTitle: Aria hidden is used to hide the svg from screen readers
		<svg
			aria-hidden="true"
			focusable="false"
			viewBox="0 0 30 30"
			height={height}
			width={width}
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M15 6.25c-2.757 0-5 2.355-5 5.25s2.243 5.25 5 5.25 5-2.355 5-5.25-2.243-5.25-5-5.25zM15 15c-1.838 0-3.333-1.57-3.333-3.5S13.162 8 15 8s3.333 1.57 3.333 3.5S16.838 15 15 15zm0-14C9.477 1 5 5.701 5 11.5c0 4.234 1.405 5.416 8.972 16.935a1.217 1.217 0 002.056 0C23.595 16.915 25 15.734 25 11.5 25 5.701 20.523 1 15 1zm0 25.918C7.745 15.902 6.667 15.027 6.667 11.5c0-2.337.866-4.535 2.44-6.187C10.681 3.66 12.774 2.75 15 2.75s4.319.91 5.893 2.563c1.574 1.652 2.44 3.85 2.44 6.187 0 3.527-1.077 4.4-8.333 15.418z"
				stroke="#000"
				strokeWidth={0.5}
				fill={fill}
				fillRule="nonzero"
			/>
		</svg>
	);
};

export default Location;

import React from "react";

export const DogSizeSmall: React.FC<IconProps> = ({
	height = "1em",
	width = "1em",
	...props
}) => {
	return (
		// biome-ignore lint/a11y/noSvgWithoutTitle: Aria hidden is used to hide the svg from screen readers
		<svg
			aria-hidden="true"
			focusable="false"
			width={width}
			height={height}
			viewBox="0 0 36.8 68.1"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<style>
				{
					".icon_dog_size_small_collar_min_svg__prefix__st0{fill:#ecc82f}.icon_dog_size_small_collar_min_svg__prefix__st1{fill:#090b0b}.icon_dog_size_small_collar_min_svg__prefix__st2{fill:#222221}"
				}
			</style>
			<path
				className="icon_dog_size_small_collar_min_svg__prefix__st0"
				d="M36.1 36c-.5-3.5-2.9-6.6-4.1-9.8-.1.8-2.2 2.3-5.8 2.6.1-.2.2-.4.4-.7.2.2.3.2.5.4.8-.4 1-.9 1.1-1.4.1 0 .2-.1.3-.1.1.1.3.1.4.2.2-.2.4-.4.5-.6 1.1-.5 2.1-1.2 2.8-1.7v.1c.6-1.7 1.1-3.3 1-5.1.4-.7.2-1.4.2-2.1.2-1.2.3-2.2.3-3.5 2.1-1.6.6-4.8.7-7 .4-.8-.3-1.9 0-2.7-2.1-4-3.1-6.8-6.2-1.4-1 .6-.2 2.7-1.2 2.8-1.2-.3-1.9 1-2.9 1-.4-.4-.9-.2-1.3-.2-.9-.1-1.4-1.1-2.3-1.3-.6-.4-.7-1.3-.7-1.9-1.3-.8-1-2.8-2.4-3.5-2.8-1.7-5.1 5.9-4.5 7.8-.7 2.3.5 2-.2 3.5.5.5.7.8.3 1.5-.4 1.7 1.1.9.7 2-.2.5-1.1 1-.5 1.5.1.1.1.5 0 .7-.2 1.3 1 2.5.4 3.8-.1.2.6.5.4 1 .4.5.2 1.3.8 1.9.2.2.3.4.3.5.4.3 1 .6 1.8 1 0 .2.2.5.3.7.2-.1.2-.2.4-.3.2 1.3.5 1.7 1.1 1.6.1.2.3.3.4.5-2.3-.6-4.1-1.4-4.1-1.9v-1.4c-.1.1-.3.3-.6.4-1.2.3-2.5.6-3.7.9-.3.7-1 .5-1.4.6-.4.4-.8.9-1.1 1C4.5 29 3.9 33.8 1 36.3c.2 3.5.2 7 .5 10.6-.2 1.6.6 3.6-.6 5 2.1.6-.7 5.9.1 7.9.2 2.3-2.8 4.8 1 5.4 1.6 0 3.6.5 4.6-1-.3-1 .5-2.4-.5-3.5-.1-4 1.6-2.7.8-7.8 1.6-.7 0-3.9 2-4.1-.1-.8.1-1.3.6-1.8.1.2.2.2.4.3.9 0 .7 1.4 1.6 1.6-1.4 2.6-1.4 5.2-2.5 7.7-1.5 3 .4 4.1-.3 5.7.5.6 1.4 1.2 2.2.7.3 0 .7.4 1.2.2.4-.4.9-.1 1.4-.3.7-.6.8-1.4.5-2.2-.3-.7-.3-3.5.9-3.1-.4 1.1.9 1.2 1.3 2 1.2 2.5 2.9 4.9-.6 6.4.6.4 0 1.4 1 1.6 1.7.6 3.6.4 5.3-.2 1.6.1 1.8-2.1 2-3.3.7-1.2-.5-2.2-.1-3.5.7-1.6-1.5-2.5 1.1-2.6.1-.7-.6-.2-.8-.5-.8-.5-1-1.7-.5-2.3 1.5-1-.5-1.3-.2-2.4 2.1-.4.9-.5 2.7.3.2.9-.5 1.3-1 1.9.9 1.1-.4.7-.8 1.1-.2 1.1 1.7 1.7 1.2 2.6-.8 1-1 4.3-.1 5.3.7.7-.3 1.1-.2 1.8 1.3 0 1.9 1.6 3.2 1.5 1.5-.2 4 .7 4.9-.7 1-1.4-.6-2.5-1.8-3.2-.2-1.5.3-2.7.8-3.9-.1-.5.4-.7.5-1 .1-.7.7-.8 1.1-1.3 0-2 .3-3.1 1.3-4.1-.1-1.3 0-2.6.3-3.9.3-4.1 2.3-8.7.6-13.2zm-10.7-5.6c1.7.1 3 1.4 3.1 3.1.1 1.9-1.3 3.5-3.1 3.6-1.8.1-3.4-1.3-3.5-3.2-.1-1.7 1-3.1 2.5-3.5v-1.1c.2-.2.3-.5.5-.7.2.2.4.4.5.7v1.1zm-5.3-2.3h.2c0 .1.1.2.1.3-.1 0-.3-.1-.4-.1 0-.1.1-.1.1-.2z"
			/>
			<path
				className="icon_dog_size_small_collar_min_svg__prefix__st1"
				d="M19 28c-.1-.2-.3-.3-.4-.5-.6 0-.9-.4-1.1-1.6-.1.1-.2.1-.4.3-.2-.2-.3-.5-.3-.7-.8-.4-1.4-.7-1.8-1 0 0-.1 0-.1-.1v1.7c0 .5 1.8 1.3 4.1 1.9zm1.3.1h-.2s-.1.1-.1.2c.1 0 .3.1.4.1 0-.1-.1-.2-.1-.3z"
			/>
			<path d="M29.3 26.6c-.1.2-.3.4-.5.6-.1-.1-.3-.1-.4-.2-.1 0-.2.1-.3.1-.1.6-.3 1.1-1.1 1.4-.2-.1-.3-.2-.5-.4-.1.2-.3.4-.4.7 3.6-.3 5.7-1.7 5.8-2.6v-.1L32 25v-.1c-.5.6-1.5 1.2-2.7 1.7z" />
			<path
				className="icon_dog_size_small_collar_min_svg__prefix__st1"
				d="M24.4 29.4v1.1c-1.5.4-2.6 1.8-2.5 3.5.1 1.9 1.7 3.3 3.5 3.2 1.8-.1 3.2-1.7 3.1-3.6-.1-1.7-1.5-3-3.1-3.1v-1.1c-.2-.2-.3-.4-.5-.7-.2.2-.3.4-.5.7zm3.5 4.2c.1 1.5-1 2.8-2.5 2.9-1.5.1-2.7-1-2.8-2.5-.1-1.5 1-2.8 2.5-2.9 1.4-.1 2.7 1 2.8 2.5z"
			/>
			<path
				className="icon_dog_size_small_collar_min_svg__prefix__st0"
				d="M25.1 31.1c-1.5.1-2.6 1.4-2.5 2.9.1 1.5 1.4 2.6 2.8 2.5 1.5-.1 2.6-1.4 2.5-2.9-.1-1.5-1.4-2.6-2.8-2.5zm.2 4.4c-.1 0-.1 0 0 0-.4 0-.6-.1-.8-.2-.2-.1-.3-.3-.3-.5v-.2c.1.5.5.7 1.1.9zm-.8-1.1c.1.1.1.2.2.2.1.1.1.2.2.2 0 0 .1 0 .1.1.1.1.1.2.3.2h-.2c-.3-.2-.5-.4-.6-.7zm.4-1c.1 0 .2 0 .2.2v.2h-.2c-.1 0-.2-.1-.2-.1 0-.2.1-.3.2-.3zm1.2 1.1v.2c0 .1-.1.2-.2.2h-.1c-.1 0-.3-.1-.3-.2v-.1c-.1-.1-.1-.2 0-.3s.3-.1.5 0c.1.1.2.1.1.2zm-1.8-.9c.1.2.1.4.1.5 0 .1 0 .2-.1.3.1-.2 0-.4 0-.7-.1 0 0 0 0-.1zm1.7 1.6h-.4c-.1 0-.2 0-.3-.1.2.1.4 0 .5-.2.1.1.1.2.2.1.1 0 .1-.1.1-.2v-.1c.2.2.1.5-.1.5zm-2.7-2.3h.2c.2 0 .3 0 .5-.1.2 0 .3-.1.5-.1.1 0 .2.1.2.2 0 .2-.1.3-.3.5l-.2.2c0 .2-.1.2-.3.2-.2 0-.3-.1-.4-.2-.1-.1-.2-.1-.4-.1H23c-.1 0-.1 0-.1-.2.1-.3.3-.4.4-.4zm1.7-.3c0 .1 0 .1 0 0 0 0 0 .1 0 0 .2-.1.3-.1.5-.1h-.1c.2 0 .3 0 .5.2-.4-.1-.6-.1-.9-.1zm1.3 1.3v-.5c.2.2 0 .4 0 .5zm-.1-.3c0 .1-.1.2-.2.2 0-.1 0-.2-.1-.2 0-.1 0-.2.1-.2h.2s.1.1 0 .2zm1.4-.8c-.1.2-.2.3-.4.3h-.4c-.1 0-.2.1-.3.2l-.1.1s0-.1-.1-.1c0-.2-.1-.3-.2-.5-.1-.1-.1-.1 0-.2h.1c.1 0 .2 0 .2-.1.2-.1.4-.1.5-.1 0 0 .1 0 .1.1.1.1.2.1.4.1s.2.1.2.2z"
			/>
			<path
				className="icon_dog_size_small_collar_min_svg__prefix__st0"
				d="M25.6 34.5h.2c-.1-.1-.1-.1-.2 0z"
			/>
			<path
				className="icon_dog_size_small_collar_min_svg__prefix__st2"
				d="M23.1 33.4s.1 0 0 0c.2 0 .3 0 .4.1.1.1.3.2.4.2.2 0 .2 0 .3-.2 0-.1.1-.1.2-.2.2-.1.3-.3.3-.5 0-.1 0-.2-.2-.2s-.3 0-.5.1c-.2 0-.3.1-.5.1h-.2c-.1.1-.3.2-.3.4s0 .2.1.2zm4.4-.8c-.1 0-.1 0 0 0-.2 0-.3 0-.4-.1 0 0-.1 0-.1-.1-.2-.1-.4-.1-.5.1-.1 0-.1.1-.2.1h-.1c-.1.1-.1.1 0 .2.2.1.2.3.2.5 0 0 0 .1.1.1l.1-.1c.1-.1.1-.2.3-.2h.4c.2 0 .3-.1.4-.3-.1-.1-.1-.2-.2-.2zm-1.9 1.7c-.2.1-.2.2 0 .3v.1c0 .1.2.2.3.2h.1c.1 0 .2 0 .2-.2v-.2c0-.1 0-.2-.1-.2h-.5zm.2.1h-.2.2zm-1.7.1v.2c0 .2.2.4.3.5.2.2.5.3.7.3h.1c-.5-.2-.9-.4-1.1-1zm.6-.8c0 .1.1.1.2.1h.2v-.2c0-.1-.1-.2-.2-.2s-.2.1-.2.3z"
			/>
			<path
				className="icon_dog_size_small_collar_min_svg__prefix__st2"
				d="M26.2 34.8s0 .1 0 0c0 .2 0 .3-.1.3s-.2-.1-.2-.1c-.1.2-.3.2-.5.2.1.1.2.1.3.1h.4c.1-.1.2-.4.1-.5zm-.9.3zm.9-1.7H26c-.1 0-.1.1-.1.2s0 .1.1.2c.1 0 .2-.1.2-.2.1-.1 0-.2 0-.2zm-1.1 1.7h.1c-.1 0-.2-.1-.3-.2l-.1-.1c-.1 0-.2-.1-.2-.2-.1-.1-.1-.2-.2-.2.2.3.4.5.7.7zm-.1-2.4s0-.1 0 0zm.3-.1h.1c-.2 0-.3 0-.5.1.3 0 .6 0 .8.1 0-.2-.2-.2-.4-.2z"
			/>
			<path
				className="icon_dog_size_small_collar_min_svg__prefix__st2"
				d="M24.3 33.8c.1.2.1.4 0 .7.1-.1.2-.2.1-.3 0-.2-.1-.4-.1-.5 0 0-.1 0 0 .1zm2 .1c.1-.1.2-.3.1-.5-.1.2-.1.4-.1.5z"
			/>
		</svg>
	);
};

export default DogSizeSmall;

export function hashCode(o: string) {
	const s = JSON.stringify(Object.values(JSON.parse(o)).flat().sort());
	let hash = 0;
	for (let i = 0; i < s.length; i++) {
		const code = s.charCodeAt(i);
		hash = (hash << 5) - hash + code;
		hash = hash & hash; // Convert to 32bit integer
	}
	return hash;
}

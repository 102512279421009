import { Colours } from "@dogstrust/src/utils/colours";
import * as styles from "./PageManager.module.scss";
export const getBackgroundStyle = (background: string) => {
	switch (background) {
		case Colours.SUNSHINE_YELLOW:
			return styles.backgroundsunshineyellow;
		case Colours.SANDY_FUR:
			return styles.backgroundsandyfur;
		case Colours.WHITE:
			return styles.backgroundwhite;
		case Colours.OATMEAL:
			return styles.backgroundoatmeal;
	}
};

import React from "react";
export const ChangeEmail: React.FC<IconProps> = ({
	height = "26px",
	width = "26px",
	...props
}) => {
	return (
		// biome-ignore lint/a11y/noSvgWithoutTitle: Aria hidden is used to hide the svg from screen readers
		<svg
			aria-hidden="true"
			focusable="false"
			width={width}
			height={height}
			viewBox="0 0 100 100"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<style>
				{
					".icon_change_email_svg__st0{fill:#ffc800}.icon_change_email_svg__st1{fill:#222}.icon_change_email_svg__st2{fill:none}"
				}
			</style>
			<path
				d="M64.7 48.2V29.5c-.4-.3-.8-.4-1.3-.4H27c-1.3 0-2.4 1.1-2.4 2.4V52c0 .6.2 1.1.5 1.5h30.1c2.6-2.6 5.9-4.5 9.5-5.3z"
				className="icon_change_email_svg__st0"
			/>
			<path
				d="M45.2 46.2c-.2 0-.4-.1-.6-.2L24 30.1c-.4-.3-.5-1-.2-1.4.3-.4 1-.5 1.4-.2L45.1 44 65 28.5c.4-.3 1.1-.3 1.4.2.3.4.3 1.1-.2 1.4L45.8 46c-.2.2-.4.2-.6.2z"
				className="icon_change_email_svg__st1"
			/>
			<path
				d="M64.7 29.5c.6.4 1.1 1.2 1.1 2V48c.7-.1 1.4-.2 2.1-.2V31.5c0-2-1.4-3.7-3.2-4.2 0 .2.1.4.1.7v1.5z"
				className="icon_change_email_svg__st1"
			/>
			<path
				d="M64.7 29.5v18.7c.4-.1.7-.1 1.1-.2V31.5c0-.8-.4-1.5-1.1-2zM25.1 53.5c.4.6 1.1.9 1.9.9h27.4c.3-.3.5-.6.8-.9H25.1z"
				className="icon_change_email_svg__st2"
			/>
			<path
				d="M22.5 31.5c0-2.5 2-4.4 4.4-4.4h36.5c.4 0 .8.1 1.2.2-.3-1.4-1.6-2.5-3.1-2.5H23.8c-1.7 0-3.1 1.4-3.1 3.1v22.4c0 1.4.9 2.5 2.1 2.9-.1-.4-.2-.8-.2-1.3V31.5z"
				className="icon_change_email_svg__st0"
			/>
			<path
				d="M27 54.4c-.8 0-1.4-.4-1.9-.9h-1.3c-.4 0-.7-.1-1.1-.2.6 1.8 2.2 3.2 4.2 3.2h25.9l1.5-2.1H27z"
				className="icon_change_email_svg__st1"
			/>
			<path
				d="M25.1 53.5c-.3-.4-.5-.9-.5-1.5V31.5c0-1.3 1.1-2.4 2.4-2.4h36.5c.5 0 .9.1 1.3.4v-1.6c0-.2 0-.5-.1-.7-.4-.1-.8-.2-1.2-.2H27c-2.5 0-4.4 2-4.4 4.4V52c0 .4.1.9.2 1.3.3.1.7.2 1.1.2h1.2zM58.3 68.4c-.4 0-.7-.2-.9-.5L54 62.8c-.3-.5-.2-1.1.3-1.4.5-.3 1.1-.2 1.4.3l2.7 4 3.1-3.7c.4-.4 1-.5 1.5-.1.4.4.5 1 .1 1.5l-4 4.6c-.2.2-.5.4-.8.4zM82.7 68.6c-.3 0-.7-.2-.9-.5l-2.7-4-3.1 3.7c-.4.4-1 .5-1.5.1-.4-.4-.5-1-.1-1.5l3.9-4.7c.2-.2.5-.4.8-.4.3 0 .6.2.8.5l3.4 5.1c.3.5.2 1.1-.3 1.4 0 .2-.1.3-.3.3z"
				className="icon_change_email_svg__st1"
			/>
			<path
				d="M68.8 75.8c-3 0-5.9-1.2-8-3.3-.4-.4-.4-1.1 0-1.5.4-.4 1.1-.4 1.5 0 2.3 2.3 5.6 3.2 8.8 2.5 4.8-1.1 8.1-6.1 7.2-10.8-.1-.6.3-1.1.8-1.2.6-.1 1.1.3 1.2.8 1.1 5.8-2.8 11.7-8.8 13.2-1 .2-1.9.3-2.7.3zM58.3 68.4c-.5 0-.9-.3-1-.8-.8-3-.4-6.2 1.3-8.9 1.7-2.8 4.4-4.8 7.5-5.6 4.1-1 8.4.4 11.2 3.6.4.4.3 1.1-.1 1.5s-1.1.3-1.5-.1c-2.3-2.6-5.8-3.7-9.2-2.9-2.6.6-4.8 2.3-6.2 4.6-1.3 2.2-1.7 4.9-1 7.3.1.5-.2 1.1-.7 1.3-.1-.1-.2 0-.3 0z"
				className="icon_change_email_svg__st1"
			/>
		</svg>
	);
};

export default ChangeEmail;

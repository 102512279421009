import React from "react";
import { Colours } from "../../../utils/colours";

export const OffSite: React.FC<IconProps> = ({
	height = "30px",
	width = "30px",
	fill = Colours.MIDNIGHT,
	...props
}) => {
	return (
		// biome-ignore lint/a11y/noSvgWithoutTitle: Aria hidden is used to hide the svg from screen readers
		<svg
			aria-hidden="true"
			focusable="false"
			viewBox="0 0 50 50"
			height={height}
			width={width}
			xmlns="http://www.w3.org/2000/svg"
			fill={fill}
			fillRule="nonzero"
			{...props}
		>
			<defs>
				<style>{".icon_off_site_svg__cls-1{fill:none}"}</style>
			</defs>
			<path
				className="icon_off_site_svg__cls-1"
				d="M24.42 8.01h1.16v1.82h-1.16zM32.04 26.18c-.53 0-1.07.35-1.11.97.57 0 1.34-.32 1.84-.62-.17-.24-.45-.35-.73-.35ZM20.7 19.84c.92 0 1.53-.69 1.53-1.67s-.61-1.67-1.53-1.67-1.51.69-1.51 1.67.61 1.67 1.51 1.67ZM24.42 39.86h1.16v-4.8h-1.16v4.8Z"
			/>
			<path
				className="icon_off_site_svg__cls-1"
				d="M9.78 32.07h30.43V12.82H9.78v19.25Zm14.2-1.88h-1.86v-5.33h1.86v5.33Zm4.42-1.46h.33v1.46h-.76c-1.7 0-2.31-.71-2.31-1.96V26.2h-.99v-1.35h.99v-1.38h1.86v1.33c.4-.03.79-.08 1.17-.18l.38 1.3c-.47.16-.9.22-1.55.24v1.83c0 .52.29.71.87.71Zm6.23-2.06-1.72.88c-.6.31-1.19.52-1.84.58.14.46.6.65 1.07.65.65 0 .99-.35.99-.95h1.66c0 1.53-1.23 2.47-2.82 2.47-1.8 0-2.77-1.25-2.77-2.81 0-1.96 1.52-2.72 2.9-2.72 1.28 0 2.38.7 2.55 1.91Zm-10.17-10h.86v-.06c0-1.08.53-1.7 2.01-1.7h.66v1.18h-.29c-.5 0-.76.13-.76.5v.08h1.66v-.06c0-1.08.53-1.7 2.01-1.7h.66v1.18h-.29c-.5 0-.76.13-.76.5v.04c.35-.03.68-.07 1.02-.15l.33 1.13c-.41.14-.78.19-1.35.21v3.48H28.6v-3.47h-1.66v3.47h-1.62v-3.47h-.86v-1.17Zm-1.4 7.79c-.66 0-1.12-.46-1.12-1.12s.46-1.1 1.12-1.1 1.1.46 1.1 1.1-.46 1.12-1.1 1.12Zm-2.37-9.56c1.86 0 3.22 1.36 3.22 3.25s-1.36 3.25-3.22 3.25-3.21-1.36-3.21-3.25 1.36-3.25 3.21-3.25Zm-2.85 12.23c-.95-.32-2.18-.74-2.18-2.15s1.3-2.15 2.62-2.15c1.13 0 2.25.52 2.67 1.58l-1.54.77c-.21-.5-.67-.77-1.13-.77-.35 0-.71.18-.71.54 0 .48.63.62 1.41.85 1.29.39 2.35.84 2.35 2.15 0 1.54-1.42 2.35-2.9 2.35-1.58 0-3.22-.93-3.22-2.9h1.9c0 .96.67 1.32 1.41 1.32.56 0 .93-.21.93-.61 0-.47-.52-.62-1.6-.98Z"
			/>
			<path d="M41.72 9.82H28.59V6.5c0-.83-.67-1.5-1.5-1.5h-4.16c-.83 0-1.5.67-1.5 1.5v3.32H8.28c-.83 0-1.5.67-1.5 1.5v22.25c0 .83.67 1.5 1.5 1.5h13.13v4.98c-2.51.26-5 .8-7.41 1.63l-1.16.4c-.78.27-1.2 1.12-.93 1.91.27.78 1.12 1.2 1.91.93l1.16-.4a31.115 31.115 0 0 1 20.04 0l1.16.4c.16.06.33.08.49.08.62 0 1.21-.39 1.42-1.01.27-.78-.15-1.64-.93-1.91l-1.16-.4c-2.41-.82-4.89-1.36-7.41-1.63v-4.98h13.13c.83 0 1.5-.67 1.5-1.5V11.32c0-.83-.67-1.5-1.5-1.5ZM24.42 8h1.16v1.82h-1.16V8Zm1.16 31.86h-1.16v-4.8h1.16v4.8Zm14.63-7.8H9.78V12.82h30.43v19.25Z" />
			<path d="M20.7 21.41c1.86 0 3.22-1.36 3.22-3.25s-1.36-3.25-3.22-3.25-3.21 1.36-3.21 3.25 1.36 3.25 3.21 3.25Zm0-4.92c.92 0 1.53.69 1.53 1.67s-.61 1.67-1.53 1.67-1.51-.69-1.51-1.67.61-1.67 1.51-1.67ZM25.33 21.32h1.62v-3.47h1.66v3.47h1.62v-3.48c.57-.02.95-.07 1.35-.21l-.33-1.13a5.7 5.7 0 0 1-1.02.15v-.04c0-.37.25-.5.76-.5h.29v-1.18h-.66c-1.48 0-2.01.62-2.01 1.7v.06h-1.66v-.08c0-.37.25-.5.76-.5H28v-1.18h-.66c-1.48 0-2.01.62-2.01 1.7v.06h-.86v1.17h.86v3.47ZM18.52 28.73c-.73 0-1.41-.36-1.41-1.32h-1.9c0 1.97 1.64 2.9 3.22 2.9 1.48 0 2.9-.81 2.9-2.35 0-1.31-1.06-1.76-2.35-2.15-.78-.23-1.41-.37-1.41-.85 0-.36.36-.54.71-.54.46 0 .92.27 1.13.77l1.54-.77c-.42-1.07-1.54-1.58-2.67-1.58-1.31 0-2.62.69-2.62 2.15s1.23 1.83 2.18 2.15c1.09.36 1.6.52 1.6.98 0 .4-.37.61-.93.61ZM23.07 22.26c-.66 0-1.12.46-1.12 1.1s.46 1.12 1.12 1.12 1.1-.46 1.1-1.12-.46-1.1-1.1-1.1ZM22.12 24.86h1.86v5.33h-1.86zM29.09 25.95l-.38-1.3c-.38.09-.77.14-1.17.18V23.5h-1.86v1.38h-.99v1.35h.99v2.03c0 1.24.61 1.96 2.31 1.96h.76v-1.46h-.33c-.58 0-.87-.2-.87-.71v-1.83c.65-.02 1.09-.08 1.55-.24ZM29.19 27.49c0 1.56.97 2.81 2.77 2.81 1.59 0 2.82-.94 2.82-2.47h-1.66c0 .6-.34.95-.99.95-.47 0-.92-.2-1.07-.65.65-.06 1.24-.27 1.84-.58l1.72-.88c-.17-1.2-1.26-1.91-2.55-1.91-1.38 0-2.9.77-2.9 2.72Zm2.85-1.31c.29 0 .57.11.73.35-.51.3-1.27.62-1.84.62.04-.62.58-.97 1.11-.97Z" />
		</svg>
	);
};

export default OffSite;

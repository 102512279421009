import React from "react";
import { Colours } from "../../../utils/colours";

export const Wheelchair: React.FC<IconProps> = ({
	height = "30px",
	width = "30px",
	fill = Colours.MIDNIGHT,
	...props
}) => {
	return (
		// biome-ignore lint/a11y/noSvgWithoutTitle: Aria hidden is used to hide the svg from screen readers
		<svg
			aria-hidden="true"
			focusable="false"
			viewBox="0 0 512 512"
			height={height}
			width={width}
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				fill={fill}
				d="M500.1 399.78l10.65 21.494c2.937 5.928.522 13.116-5.399 16.067l-63.278 32.164c-12.134 6.014-26.981.801-32.571-11.723L344.431 312H184.003c-12.03 0-22.203-8.908-23.792-20.833C125.74 32.641 128.263 52.443 128 48c0-27.152 22.544-49.038 49.935-47.962 24.787.974 44.979 21.107 46.021 45.892 1.06 25.208-17.335 46.326-41.405 49.614L192.212 168H340c6.627 0 12 5.373 12 12v24c0 6.627-5.373 12-12 12H198.613l6.4 48H360a23.999 23.999 0 0121.916 14.218l61.233 137.185 40.834-21.029c5.943-2.971 13.168-.547 16.117 5.406zM313.291 360h-11.558C290.467 419.146 238.377 464 176 464c-70.579 0-128-57.421-128-128 0-43.765 22.083-82.463 55.686-105.556l-6.884-51.587C39.428 207.89 0 267.423 0 336c0 97.047 78.953 176 176 176 70.605 0 131.621-41.797 159.636-101.941L313.291 360z"
			/>
		</svg>
	);
};

export default Wheelchair;

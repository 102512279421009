import { getProfile, syncProfile } from "@dogstrust/src/utils/api/my-account";
import React, {
	PropsWithChildren,
	useCallback,
	useEffect,
	useState,
} from "react";
import { MyAccountContext } from "./MyAccount.context";

const UNINITIALISED_PROFILE: Profile = {
	userId: "offline",
	type: "uninitialised",
	favourites: [],
	shouldSync: false,
};

const DEFAULT_PROFILE: Profile = {
	userId: "offline",
	favourites: [],
	type: "offline",
	shouldSync: false,
};

export const MyAccountProvider: React.FC<PropsWithChildren> = ({
	children,
}) => {
	const [authStatus, setAuthStatus] = useState<MyAccountAuthState>("unknown");
	const [profile, setProfile] = useState<Profile>(UNINITIALISED_PROFILE);
	const [shouldMergeProfile, setShouldMergeProfile] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
	/**
	 * Clears the local profile
	 *
	 * This is used to clear the profile in local storage
	 */
	const clearLocalProfile = () => {
		console.log("🚀 Removing local profile");
		localStorage.removeItem("profile");
	};

	/**
	 * Update the local profile
	 * @param profile - The profile to update
	 *
	 * This is used to update the profile in local storage
	 */
	const updateLocalProfile = useCallback((profile: Profile) => {
		console.log("🚀 Updating local profile", profile);
		if (profile.type !== "uninitialised")
			localStorage.setItem(
				"profile",
				JSON.stringify({
					...profile,
					localSyncTime: new Date().getTime(), // We expire the local profile after 4 hours with no updates
				}),
			);
	}, []);

	/**
	 * Get the local profile
	 * @returns The local profile
	 * This is used to get the profile from local storage
	 * If there is no profile, return the default profile
	 */
	const getLocalProfile = useCallback((): Profile => {
		const profileCookieString: string | null = localStorage.getItem("profile");
		if (profileCookieString) {
			const parsedProfile: Profile = JSON.parse(profileCookieString);
			console.log("🚀 Getting local profile", parsedProfile);

			return parsedProfile;
		}
		console.log("🚀 Getting default profile");
		updateLocalProfile({ ...DEFAULT_PROFILE });
		return DEFAULT_PROFILE;
	}, [updateLocalProfile]);

	/**
	 * Fetch the latest profile
	 * @param onLogin - Whether this is being called on login
	 * This is used to fetch the latest profile from the API
	 * If there is no profile, return the default profile
	 * If there is an error, return the default profile
	 * If the user is not authenticated, return the default profile
	 * If the user is authenticated, return the profile
	 * If the user is authenticated and there is a local profile, merge the local profile with the account profile
	 **/
	const fetchLatestProfile = useCallback(
		async (onLogin?: boolean) => {
			await getProfile()
				.then((accountProfile) => {
					if (accountProfile) {
						console.log("🚀 Got profile", accountProfile);
						setProfile({
							...accountProfile,
							type: "account",
							shouldSync: false,
						});
						const localProfile = getLocalProfile();
						console.log(
							"🚀 (fetchLatestProfile) Got local profile",
							localProfile,
						);
						const shouldMergeProfile =
							onLogin && // We only want to merge the account when the user first logs in
							localProfile.favourites.length > 0; // or the user has favourites
						setShouldMergeProfile(shouldMergeProfile);

						if (!shouldMergeProfile) {
							setIsLoading(false);
						}
					} else {
						console.log("🚀 No profile found");
						// TODO - Is this the best way to handle this? - Do we need to tell the user?
						setAuthStatus("unauthorized");
						setIsLoading(false);
					}
				})
				.catch((error) => {
					console.error("An error occurred:", error);
					setAuthStatus("unauthorized");
				});
		},
		[getLocalProfile],
	);

	/**
	 * This useEffect handles the authentication of the user and the fetching of the latest profile
	 */
	useEffect(() => {
		/**
		 * Check if the user is authenticated - and set the state accordingly
		 */
		const checkAuth = async () => {
			const isLoggedIn = await fetch(process.env.GATSBY_AUTH_STATUS_URL, {
				credentials: "include",
				method: "GET",
				redirect: "manual",
			});
			console.log("🚀 checkAuth", isLoggedIn);
			setAuthStatus(isLoggedIn.ok ? "authorized" : "unauthorized");
		};

		// If the user is not authenticated, use the status url to check if they are logged in
		if (authStatus === "unknown") {
			setAuthStatus("authenticating");
			checkAuth();
		} else if (authStatus === "authorized") {
			fetchLatestProfile(true); // If the user's auth status has changed to authorized, fetch the latest profile indicating the user has just logged in.
		} else if (authStatus === "unauthorized") {
			const localProfile = getLocalProfile();
			console.log("🚀 Unauthorized so got local profile", localProfile);
			setProfile(localProfile);
			setIsLoading(false);
		}
	}, [authStatus, fetchLatestProfile, getLocalProfile]);

	/**
	 * Add a dog to the users favourites
	 * If the user is authenticated, add the dog to their account
	 * If the user is not authenticated, add the dog to local storage
	 * @param dog - The dog to add to the favourites
	 */
	const handleAddToFavourites = useCallback(
		async (
			dog: ESDocDog,
			data: {
				card_location: string;
				event: "card_favourite";
				card_state: string;
				card_title: string;
				card_sub_title: string;
			},
		) => {
			setProfile((prevProfile) => {
				const existsInFavourites = prevProfile.favourites?.some(
					(favDog) => favDog?.apiKey === dog?.apiKey,
				);
				if (existsInFavourites) {
					console.log("🚀 Dog already exists in favourites");
					return;
				}
				// ============== Handle tracking ==============
				const currentPageUrl =
					typeof window !== "undefined" ? window.location.pathname : "";
				data.card_location = currentPageUrl;
				data.card_title = dog.name;
				data.card_sub_title = dog.breed;
				data.card_state = "added";
				// biome-ignore  lint/suspicious/noExplicitAny:
				(window as any).dataLayer = (window as any).dataLayer || [];
				// biome-ignore  lint/suspicious/noExplicitAny:
				(window as any).dataLayer.push(data);
				return {
					...prevProfile,
					favourites: [...prevProfile.favourites, dog],
					shouldSync: true,
				};
			});
		},
		[],
	);

	/**
	 * Remove a dog from the users favourites
	 * If the user is authenticated, remove the dog from their account
	 * If the user is not authenticated, remove the dog from local storage
	 * @param dog - The dog to remove from the favourites
	 */
	const handleRemoveFromFavourites = useCallback(
		async (dog: ESDocDog, data: DogDataTracking) => {
			// ============== Handle tracking ==============
			const currentPageUrl =
				typeof window !== "undefined" ? window.location.pathname : "";
			data.card_location = currentPageUrl;
			data.card_title = dog.name;
			data.card_sub_title = dog.breed;
			data.card_state = "removed";
			// biome-ignore  lint/suspicious/noExplicitAny:
			(window as any).dataLayer = (window as any).dataLayer || [];
			// biome-ignore  lint/suspicious/noExplicitAny:
			(window as any).dataLayer.push(data);

			setProfile((prevProfile) => ({
				...prevProfile,
				favourites: prevProfile.favourites.filter(
					(favDog) => favDog?.apiKey !== dog?.apiKey,
				),
				shouldSync: true,
			}));
		},
		[],
	);

	const syncTimeout = React.useRef<NodeJS.Timeout>();
	const syncRetryCount = React.useRef(0);

	useEffect(() => {
		const handleSyncingOfProfile = async () => {
			if (
				authStatus === "authorized" &&
				profile.type !== "uninitialised" &&
				profile.type !== "offline"
			) {
				console.log("🚀 Authed so syncing profile", profile);
				const hasSynced = await syncProfile({ ...profile, shouldSync: false });
				if (!hasSynced && syncRetryCount.current < 5) {
					syncRetryCount.current++;
					syncTimeout.current = setTimeout(handleSyncingOfProfile, 5000);
				} else if (hasSynced) {
					console.log("🚀 Profile has synced", profile);
					localStorage.setItem(
						"syncedProfile",
						JSON.stringify({ ...profile, shouldSync: false }),
					);
					syncRetryCount.current = 0;
				} else {
					// TODO - What should we do if it's not syncing? - Do we need to tell the user?
					// TODO - We could potentially use a different cookie to maintain until we can send the data to the API
				}
			} else if (authStatus === "unauthorized") {
				console.log("🚀 Not authed so updating profile", profile);
				updateLocalProfile(profile);
			}
		};
		handleSyncingOfProfile();

		return () => {
			clearTimeout(syncTimeout.current);
		};
	}, [authStatus, syncRetryCount, syncTimeout, profile, updateLocalProfile]);

	useEffect(() => {
		addEventListener("storage", async (event) => {
			if (event.storageArea !== localStorage) return;
			if (event.key === "syncedProfile") {
				setProfile(JSON.parse(event.newValue));
			}
		});
	}, []);

	// Handle the merging of the local profile and the account profile
	useEffect(() => {
		if (shouldMergeProfile) {
			const localProfile: Profile = getLocalProfile();
			console.log("🚀 Should merge local profile", localProfile);
			setProfile((prevProfile) => {
				const mergedFaves = [
					...localProfile.favourites,
					...prevProfile.favourites,
				];
				console.log("🚀 mergedFaves", mergedFaves);
				const dedeuplicatedFaves: ESDocDog[] = Object.values(
					mergedFaves.reduce((acc, dog) => {
						acc[dog.apiKey] = dog;
						return acc;
					}, {} as Record<string, ESDocDog>),
				);

				console.log("🚀 mergedFaves de-duplicated", dedeuplicatedFaves);

				return {
					...localProfile,
					...prevProfile,
					favourites: dedeuplicatedFaves,
					shouldSync: true,
				};
			});
			setShouldMergeProfile(false);
			setIsLoading(false);
			clearLocalProfile();
		}
	}, [shouldMergeProfile, clearLocalProfile, getLocalProfile]);

	useEffect(() => {
		if (profile.shouldSync)
			// Don't change the profile if we are already syncing
			return;
		if (profile.type !== "account")
			// We only want to add the dog to the interested list if the user is authenticated
			return;
	}, [profile]);

	return (
		<MyAccountContext.Provider
			value={{
				isAuthenticated: authStatus === "authorized",
				authStatus,
				profile,
				isLoading,
				handleAddToFavourites,
				handleRemoveFromFavourites,
			}}
		>
			{children}
		</MyAccountContext.Provider>
	);
};

import React from "react";

import DownloadDoc from "./DownloadDoc";
import DownloadImage from "./DownloadImage";
import DownloadMP3 from "./DownloadMP3";
import DownloadPDF from "./DownloadPDF";
import DownloadPPT from "./DownloadPPT";
import DownloadSheet from "./DownloadSheet";

export type DownloadIconType =
	| "doc"
	| "image"
	| "mp3"
	| "pdf"
	| "ppt"
	| "sheet";
interface DownloadProps extends IconProps {
	type?: DownloadIconType;
}
export const Download: React.FC<DownloadProps> = ({ type, ...props }) => {
	switch (type) {
		case "doc":
			return <DownloadDoc {...props} />;
		case "image":
			return <DownloadImage {...props} />;
		case "mp3":
			return <DownloadMP3 {...props} />;
		case "pdf":
			return <DownloadPDF {...props} />;
		case "ppt":
			return <DownloadPPT {...props} />;
		case "sheet":
			return <DownloadSheet {...props} />;
		default:
			return (
				// biome-ignore lint/a11y/noSvgWithoutTitle: Aria hidden is used to hide the svg from screen readers
				<svg
					aria-hidden="true"
					focusable="false"
					xmlns="http://www.w3.org/2000/svg"
					viewBox="0 0 100 100"
					{...props}
				>
					<path
						d="M69.62 71.09c-.53 0-.99-.19-1.38-.58-.39-.39-.58-.85-.58-1.38s.19-.99.58-1.38c.39-.39.85-.58 1.38-.58s.99.19 1.38.58c.39.39.58.85.58 1.38s-.19.99-.58 1.38c-.39.39-.85.58-1.38.58Zm-7.85 0c-.53 0-.99-.19-1.38-.58-.39-.39-.58-.85-.58-1.38s.19-.99.58-1.38c.39-.39.85-.58 1.38-.58s.99.19 1.38.58c.39.39.58.85.58 1.38s-.19.99-.58 1.38c-.39.39-.85.58-1.38.58Zm10.79 3.92c.82 0 1.51-.29 2.08-.86.57-.57.86-1.27.86-2.08v-9.81c0-.82-.29-1.51-.86-2.08-.57-.57-1.27-.86-2.08-.86H58.68c-.22 0-.43.09-.59.24l-3.93 3.93c-1.19 1.14-2.57 1.72-4.17 1.72s-2.98-.57-4.17-1.72l-3.89-3.92a.824.824 0 0 0-.59-.24H27.43c-.82 0-1.51.29-2.08.86-.57.57-.86 1.27-.86 2.08v9.81c0 .82.29 1.51.86 2.08.57.57 1.27.86 2.08.86h45.12ZM50 61.28c.55 0 1.01-.19 1.38-.58l13.73-13.73c.63-.59.78-1.31.43-2.15-.35-.8-.95-1.2-1.81-1.2h-7.02c-.46 0-.83-.37-.83-.83V26.94c0-.53-.19-.99-.58-1.38s-.85-.58-1.38-.58h-7.85c-.53 0-.99.19-1.38.58s-.58.85-.58 1.38v15.85c0 .46-.37.83-.83.83h-7.02c-.86 0-1.46.4-1.81 1.2-.35.84-.2 1.55.43 2.15L48.61 60.7c.37.39.83.58 1.38.58Z"
						style={{
							fill: props.fill,
						}}
					/>
				</svg>
			);
	}
};

export default Download;

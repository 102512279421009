import React from "react";

export const Search: React.FC<IconProps> = ({
	height = "40px",
	width = "40px",
	fill = "#222",
	...props
}) => {
	return (
		// biome-ignore lint/a11y/noSvgWithoutTitle: Aria hidden is used to hide the svg from screen readers
		<svg
			aria-hidden="true"
			focusable="false"
			width={width}
			height={height}
			viewBox="0 0 32 32"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M27.223 29.5c.169 0 .321-.068.457-.203l1.168-1.117a.743.743 0 00.152-.457.55.55 0 00-.152-.407l-6.196-6.195a.55.55 0 00-.406-.152h-.71a10.91 10.91 0 001.903-3.2c.457-1.184.686-2.42.686-3.706 0-1.93-.474-3.699-1.422-5.307a10.672 10.672 0 00-3.834-3.834C17.261 3.974 15.492 3.5 13.563 3.5c-1.93 0-3.7.474-5.307 1.422a10.672 10.672 0 00-3.834 3.834C3.474 10.364 3 12.133 3 14.063c0 1.93.474 3.698 1.422 5.306a10.672 10.672 0 003.834 3.834c1.608.948 3.377 1.422 5.306 1.422 1.287 0 2.523-.228 3.708-.685a10.91 10.91 0 003.199-1.905v.711c0 .136.05.271.152.406l6.195 6.145a.483.483 0 00.407.203zm-13.66-7.312A7.948 7.948 0 019.5 21.096a8.067 8.067 0 01-2.97-2.97 7.948 7.948 0 01-1.093-4.063c0-1.456.364-2.81 1.092-4.063A8.067 8.067 0 019.5 7.03a7.948 7.948 0 014.063-1.092c1.455 0 2.81.363 4.062 1.091A8.067 8.067 0 0120.595 10a7.948 7.948 0 011.093 4.063c0 1.455-.364 2.81-1.092 4.062a8.067 8.067 0 01-2.971 2.97 7.948 7.948 0 01-4.063 1.093z"
				fill={fill}
				fillRule="nonzero"
			/>
		</svg>
	);
};

export default Search;

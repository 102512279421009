import { yupResolver } from "@hookform/resolvers/yup";
import React, { HTMLAttributes } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import * as yup from "yup";

export interface FormProps<T extends object>
	extends HTMLAttributes<HTMLElement> {
	validationSchema: Record<string, YupTypes>;
	onSubmitFn: SubmitHandler<T>;
	onResetFn?: () => void;
}

type YupTypes =
	| yup.StringSchema<string>
	| yup.ObjectSchema<Record<string, unknown>>;

export function Form<T extends object>({
	children,
	className,
	onSubmitFn,
	validationSchema,
	...rest
}: FormProps<T>): React.ReactElement {
	const wrappedValidationSchema = yup.object().shape(validationSchema);
	const methods = useForm({
		resolver: yupResolver(wrappedValidationSchema),
		reValidateMode: "onChange",
	});
	// make sure formState is read before render to enable the Proxy
	const _isDirty = methods.formState.isDirty;
	return (
		<FormProvider {...methods}>
			<form
				onSubmit={methods.handleSubmit(onSubmitFn)}
				className={className}
				{...rest}
			>
				{children}
			</form>
		</FormProvider>
	);
}

import React from "react";

export const DownloadImage: React.FC<IconProps> = ({
	height = "26px",
	width = "26px",
	fill = "#FFF",
	...props
}) => {
	return (
		// biome-ignore lint/a11y/noSvgWithoutTitle: Aria hidden is used to hide the svg from screen readers
		<svg
			aria-hidden="true"
			focusable="false"
			width={width}
			height={height}
			viewBox="0 0 100 100"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<defs>
				<style>
					{`.icon_download_Image_svg__b{fill:${fill}}.icon_download_Image_svg__c{fill:#272727}`}
				</style>
			</defs>
			<path
				className="icon_download_Image_svg__c"
				d="m56.71 50.81-3.78 3.77 8.44 8.45v-7.56l-4.66-4.66zM44.48 43.09c.86 0 1.57-.7 1.57-1.56s-.7-1.56-1.57-1.56-1.56.7-1.56 1.56.7 1.56 1.56 1.56ZM54.87 40.51c0 .28.22.5.5.5h5.76a2.01 2.01 0 0 0-.36-.48l-5.41-5.42c-.15-.15-.31-.26-.48-.36v5.76ZM45.73 50.21l-7.1 7.1v6.09c0 .6.19 1.07.61 1.48.42.42.89.61 1.49.61h18.56c.51 0 .92-.15 1.29-.45L45.74 50.2Z"
			/>
			<path
				className="icon_download_Image_svg__c"
				d="M45.02 48.09a.996.996 0 0 1 1.41 0l5.08 5.08 4.48-4.48a.996.996 0 0 1 1.41 0l3.96 3.96v-9.63h-6.01a2.5 2.5 0 0 1-2.5-2.5v-6.01H40.7c-.6 0-1.07.19-1.48.61s-.61.89-.61 1.48v17.89l6.4-6.4Zm-.54-10.13c1.97 0 3.57 1.6 3.57 3.56s-1.6 3.56-3.57 3.56-3.56-1.6-3.56-3.56 1.6-3.56 3.56-3.56Z"
			/>
			<path
				className="icon_download_Image_svg__c"
				d="M66.5 22.5h-33c-6.08 0-11 5.13-11 11.46v32.08c0 6.33 4.92 11.46 11 11.46h33c6.08 0 11-5.13 11-11.46V33.96c0-6.33-4.92-11.46-11-11.46Zm-3.13 40.9c0 1.13-.4 2.11-1.2 2.9-.79.79-1.77 1.19-2.9 1.19H40.71c-1.13 0-2.11-.4-2.9-1.2a3.98 3.98 0 0 1-1.2-2.9V36.58c0-1.13.4-2.11 1.2-2.9s1.77-1.2 2.9-1.2h13.15c1.13 0 2.11.4 2.9 1.2l5.41 5.41c.79.79 1.2 1.77 1.2 2.9v21.4Z"
			/>
			<path
				className="icon_download_Image_svg__b"
				d="M56.77 33.7a3.98 3.98 0 0 0-2.9-1.2H40.72c-1.13 0-2.11.4-2.9 1.2s-1.2 1.77-1.2 2.9v26.81c0 1.13.4 2.11 1.2 2.9.79.79 1.77 1.2 2.9 1.2h18.56c1.13 0 2.11-.4 2.9-1.19a3.98 3.98 0 0 0 1.2-2.9v-21.4c0-1.13-.4-2.11-1.2-2.9l-5.41-5.41Zm-3.83 20.89 3.78-3.78 4.66 4.66v7.56l-8.44-8.44Zm7.83-14.06c.15.15.26.31.36.48h-5.76c-.28 0-.5-.22-.5-.5v-5.76c.17.09.33.21.48.36l5.41 5.42Zm-21.53-5.42c.42-.42.89-.61 1.48-.61h12.15v6.01a2.5 2.5 0 0 0 2.5 2.5h6.01v9.63l-3.96-3.96a.996.996 0 0 0-1.41 0l-4.48 4.48-5.08-5.08a.996.996 0 0 0-1.41 0l-6.4 6.4V36.59c0-.6.19-1.07.61-1.48Zm1.48 30.39c-.6 0-1.07-.19-1.49-.61-.42-.41-.61-.89-.61-1.48v-6.09l7.1-7.1 14.84 14.84c-.37.3-.78.45-1.29.45H40.71Z"
			/>
			<path
				className="icon_download_Image_svg__b"
				d="M44.48 45.09c1.97 0 3.57-1.6 3.57-3.56s-1.6-3.56-3.57-3.56-3.56 1.6-3.56 3.56 1.6 3.56 3.56 3.56Zm0-5.13c.86 0 1.57.7 1.57 1.56s-.7 1.56-1.57 1.56-1.56-.7-1.56-1.56.7-1.56 1.56-1.56Z"
			/>
		</svg>
	);
};

export default DownloadImage;

import React from "react";

export const BurgerMenu: React.FC<IconProps> = ({
	height = "40px",
	width = "40px",
	...props
}) => {
	return (
		// biome-ignore lint/a11y/noSvgWithoutTitle: Aria hidden is used to hide the svg from screen readers
		<svg
			aria-hidden="true"
			focusable="false"
			width={width}
			height={height}
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 50 51"
			{...props}
		>
			<defs>
				<filter
					x="-1%"
					y="-.2%"
					width="102%"
					height="100.4%"
					filterUnits="objectBoundingBox"
					id="burger_svg__a"
				>
					<feOffset dy={2} in="SourceAlpha" result="shadowOffsetOuter1" />
					<feGaussianBlur
						stdDeviation={4}
						in="shadowOffsetOuter1"
						result="shadowBlurOuter1"
					/>
					<feColorMatrix
						values="0 0 0 0 0.156862745 0 0 0 0 0.156862745 0 0 0 0 0.156862745 0 0 0 0.16 0"
						in="shadowBlurOuter1"
					/>
				</filter>
				<filter
					x="-8.9%"
					y="-15.9%"
					width="117.9%"
					height="133.6%"
					filterUnits="objectBoundingBox"
					id="burger_svg__d"
				>
					<feOffset dy={2} in="SourceAlpha" result="shadowOffsetOuter1" />
					<feGaussianBlur
						stdDeviation={12}
						in="shadowOffsetOuter1"
						result="shadowBlurOuter1"
					/>
					<feColorMatrix
						values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
						in="shadowBlurOuter1"
					/>
				</filter>
				<filter
					x="-1.7%"
					y="-20.8%"
					width="103.4%"
					height="158.3%"
					filterUnits="objectBoundingBox"
					id="burger_svg__f"
				>
					<feOffset dy={2} in="SourceAlpha" result="shadowOffsetOuter1" />
					<feGaussianBlur
						stdDeviation={2}
						in="shadowOffsetOuter1"
						result="shadowBlurOuter1"
					/>
					<feColorMatrix
						values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.140652316 0"
						in="shadowBlurOuter1"
					/>
				</filter>
				<path
					d="M69 0h1300a8 8 0 018 8v6295a8 8 0 01-8 8H69a8 8 0 01-8-8V8a8 8 0 018-8z"
					id="burger_svg__b"
				/>
				<path id="burger_svg__g" d="M0 64h414v24H0z" />
				<path
					d="M23 16a1 1 0 010 2H1a1 1 0 010-2h22zm0-8a1 1 0 010 2H1a1 1 0 110-2h22zm0-8a1 1 0 010 2H1a1 1 0 110-2h22z"
					id="burger_svg__h"
				/>
				<rect id="burger_svg__c" x={0} y={0} width={414} height={220} rx={8} />
			</defs>
			<g fill="none" fillRule="evenodd">
				<path fill="#FBFBFB" d="M-792-6055H648v9725H-792z" />
				<g transform="translate(-792 -5392)">
					<use
						fill="#000"
						filter="url(#burger_svg__a)"
						xlinkHref="#burger_svg__b"
					/>
					<use fill="#FFF" xlinkHref="#burger_svg__b" />
				</g>
				<g transform="translate(-361 -18.76)">
					<mask id="burger_svg__e" fill="#fff">
						<use xlinkHref="#burger_svg__c" />
					</mask>
					<use
						fill="#000"
						filter="url(#burger_svg__d)"
						xlinkHref="#burger_svg__c"
					/>
					<use fill="#F5F5F5" xlinkHref="#burger_svg__c" />
					<g mask="url(#burger_svg__e)">
						<use
							fill="#000"
							filter="url(#burger_svg__f)"
							xlinkHref="#burger_svg__g"
						/>
						<use fill="#FFF" xlinkHref="#burger_svg__g" />
						<path fill="#FFF" d="M0 88h414V0H0z" />
						<g transform="translate(374 35)">
							<mask id="burger_svg__i" fill="#fff">
								<use xlinkHref="#burger_svg__h" />
							</mask>
							<use fill="#3F3F3F" xlinkHref="#burger_svg__h" />
							<path
								fill="#000"
								mask="url(#burger_svg__i)"
								d="M-13-16h50v50h-50z"
							/>
						</g>
					</g>
				</g>
			</g>
		</svg>
	);
};

export default BurgerMenu;

import * as styles from "./SocialMediaIcons.module.scss";

import {
	ShareEmail,
	ShareFacebook,
	ShareX,
	ShareWhatsApp,
	SocialInstagram,
	SocialYoutube,
} from "../Icons";

import cx from "classnames";
import React from "react";
import SocialTikTok from "../Icons/SocialTikTok";

export interface SocialMediaIconsProps {
	className?: string;
	invert?: boolean;
	iconSize?: string;
	xUrl?: string;
	facebookUrl?: string;
	linkedInUrl?: string;
	instagramUrl?: string;
	tikTokUrl?: string;
	mailToUrl?: string;
	whatsAppUrl?: string;
	youtubeUrl?: string;
}
export const SocialMediaIcons: React.FC<SocialMediaIconsProps> = ({
	className,
	invert,
	xUrl,
	facebookUrl,
	instagramUrl,
	mailToUrl,
	whatsAppUrl,
	tikTokUrl,
	iconSize,
	youtubeUrl,
}) => {
	let size = {};
	if (iconSize) {
		size = { height: iconSize, width: iconSize };
	}
	return (
		<div className={cx(styles.socialmediaicons, className)}>
			{!!xUrl && (
				<a href={xUrl} target="_blank" rel="noreferrer" aria-label="Share on X">
					<ShareX invert={invert} {...size} />
					Share on X
				</a>
			)}
			{!!facebookUrl && (
				<a
					href={facebookUrl}
					target="_blank"
					rel="noreferrer"
					aria-label="Share on Facebook"
				>
					<ShareFacebook invert={invert} {...size} />
					Share on Facebook
				</a>
			)}
			{mailToUrl && (
				<a
					href={mailToUrl}
					target="_blank"
					rel="noreferrer"
					aria-label="Share by Email"
				>
					<ShareEmail invert={invert} {...size} />
					Share by Email
				</a>
			)}
			{instagramUrl && (
				<a
					href={instagramUrl}
					target="_blank"
					rel="noreferrer"
					aria-label="Share on Instagram"
				>
					<SocialInstagram invert={invert} {...size} />
					Share on Instagram
				</a>
			)}
			{whatsAppUrl && (
				<a
					href={whatsAppUrl}
					target="_blank"
					rel="noreferrer"
					aria-label="Share on WhatsApp"
				>
					<ShareWhatsApp invert={invert} {...size} />
					Share on WhatsApp
				</a>
			)}
			{youtubeUrl && (
				<a
					href={youtubeUrl}
					target="_blank"
					rel="noreferrer"
					aria-label="Share on Youtube"
				>
					<SocialYoutube invert={invert} />
					Share on Youtube
				</a>
			)}
			{tikTokUrl && (
				<a
					href={tikTokUrl}
					target="_blank"
					rel="noreferrer"
					aria-label="Share on TikTok"
				>
					<SocialTikTok invert={invert} />
					Share on TikTok
				</a>
			)}
		</div>
	);
};

export default SocialMediaIcons;

import React from "react";

export const Add: React.FC<IconProps> = ({
	height = "26px",
	width = "26px",
	fill = "#222",
	...props
}) => {
	return (
		// biome-ignore lint/a11y/noSvgWithoutTitle: Aria hidden is used to hide the svg from screen readers
		<svg
			aria-hidden="true"
			focusable="false"
			width={width}
			height={height}
			viewBox="0 0 26 26"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<defs>
				<path
					d="M13 7a1 1 0 011 1v4h4a1 1 0 010 2h-4v4a1 1 0 01-2 0v-4.001L8 14a1 1 0 010-2l4-.001V8a1 1 0 011-1z"
					id="icon_add_svg__a"
				/>
			</defs>
			<g fill="none" fillRule="evenodd">
				<circle stroke="#DDD" cx={13} cy={13} r={12} />
				<use fill={fill} xlinkHref="#icon_add_svg__a" />
			</g>
		</svg>
	);
};

export default Add;

import SSRSuspense from "@dogstrust/src/components/SSRSuspense/SSRSuspense";
import React, { useEffect } from "react";
import Modal from "react-modal";
import AddFavourite from "../AddFavourite/AddFavourite";
import RemoveDog from "../RemoveDog/RemoveDog";
export interface FavouritesModalProps {
	modalState: string;
	favouriteDog: ESDocDog;
	onClickCancelRemoveDog: () => void;
	onClickRemoveDog: () => void;
	onClickAddDog: () => void;
}

export const FavouritesModal: React.FC<FavouritesModalProps> = ({
	modalState,
	favouriteDog,
	onClickCancelRemoveDog,
	onClickRemoveDog,
	onClickAddDog,
}) => {
	const customStyles = {
		content: {
			padding: "50px 30px",
			top: "50%",
			left: "50%",
			right: "auto",
			bottom: "auto",
			marginRight: "-50%",
			transform: "translate(-50%, -50%)",
			border: "0",
			borderRadius: "0",
			boxShadow: "0px 4px 34px 0px rgba(0, 0, 0, 0.25)",
			maxHeight: "80vh",
			maxWidth: "75vw",
		},
		overlay: {
			zIndex: "9999",
		},
	};

	useEffect(() => {
		if (modalState !== "closed") {
			document.getElementsByTagName("body")[0].style.overflow = "hidden";
		} else {
			document.getElementsByTagName("body")[0].style.overflow = "scroll";
		}
		return () => {
			document.getElementsByTagName("body")[0].style.overflow = "scroll";
		};
	}, [modalState]);

	return (
		<SSRSuspense>
			<Modal
				isOpen={modalState !== "closed"}
				style={customStyles}
				preventScroll={true}
				shouldCloseOnEsc={true}
				shouldCloseOnOverlayClick={true}
				shouldFocusAfterRender={true}
				shouldReturnFocusAfterClose={true}
				ariaHideApp={false}
			>
				{modalState === "remove" && (
					<RemoveDog
						type="favourite"
						dogName={favouriteDog?.name}
						onClickCancel={onClickCancelRemoveDog}
						onClickConfirm={onClickRemoveDog}
					/>
				)}
				{modalState === "add" && (
					<AddFavourite onClick={onClickAddDog} dog={favouriteDog} />
				)}
			</Modal>
		</SSRSuspense>
	);
};

export default FavouritesModal;

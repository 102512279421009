import React from "react";

export const StepHome: React.FC<IconProps> = ({
	height = "48px",
	width = "48px",
	fillPrimary = "#fff",
	fillSecondary = "#222",
	invert,
	...props
}) => {
	return (
		// biome-ignore lint/a11y/noSvgWithoutTitle: Aria hidden is used to hide the svg from screen readers
		<svg
			aria-hidden="true"
			focusable="false"
			width={width}
			height={height}
			viewBox="0 0 48 48"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				fill={invert ? fillSecondary : fillPrimary}
				fillRule="evenodd"
				d="M23.097.112C39.25-1.333 49.51 11.459 47.818 26.806c-.916 10.764-9.966 21.013-21.194 21.19-6.934.088-14.346-1.153-19.508-6.148-5.965-6.106-8.834-15.5-6.04-23.704C3.494 7.708 12.462.764 23.096.111"
				clipRule="evenodd"
			/>
			<path
				fill={invert ? fillPrimary : fillSecondary}
				d="M36.893 24.375c0 .809-.674 1.438-1.438 1.438h-1.437L34.062 33V34.078a1.774 1.774 0 0 1-1.796 1.797H28.672c-1.033 0-1.797-.764-1.797-1.797V30.125c0-.764-.674-1.438-1.438-1.438h-2.875c-.808 0-1.437.674-1.437 1.438V34.078a1.774 1.774 0 0 1-1.797 1.797h-3.594c-1.033 0-1.796-.764-1.796-1.797v-8.266H12.5a1.42 1.42 0 0 1-1.438-1.437c0-.404.135-.764.45-1.078l11.5-10.063c.314-.314.674-.359.988-.359.314 0 .674.09.943.315l11.455 10.107c.36.314.54.674.495 1.078Z"
			/>
		</svg>
	);
};

export default StepHome;

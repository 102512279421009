import React from "react";
import { Colours } from "../../../utils/colours";

export const Microchip: React.FC<IconProps> = ({
	height = "30px",
	width = "30px",
	fill = Colours.MIDNIGHT,
	...props
}) => {
	return (
		// biome-ignore lint/a11y/noSvgWithoutTitle: Aria hidden is used to hide the svg from screen readers
		<svg
			aria-hidden="true"
			focusable="false"
			viewBox="0 0 30 30"
			height={height}
			width={width}
			xmlns="http://www.w3.org/2000/svg"
			fill={fill}
			fillRule="nonzero"
			{...props}
		>
			<path d="M27.8 7.7C26.1 6 23 6 21.2 7.7L7.5 21.4c-.9.9-1.4 2.1-1.4 3.3s.5 2.4 1.4 3.3 2.1 1.4 3.3 1.4 2.4-.5 3.3-1.4l13.6-13.6c.9-.9 1.4-2.1 1.4-3.3.1-1.3-.4-2.5-1.3-3.4M12.9 26.8c-1.1 1.1-3 1.1-4.1 0-.5-.5-.8-1.3-.8-2 0-.8.3-1.5.8-2l5.5-5.5 4.1 4.1zm13.7-13.7-6.8 6.8-1.5-1.5 6.7-6.7-1.3-1.3-6.7 6.7-1.3-1.3L22.5 9c.5-.5 1.3-.8 2-.8.8 0 1.5.3 2 .8s.8 1.3.8 2c.1.9-.2 1.6-.7 2.1M16 2.7c.5 0 .9-.4.9-.9s-.4-1-.9-1C7.6.8.7 7.7.7 16.2c0 .5.4.9.9.9s.9-.4.9-.9C2.5 8.7 8.6 2.7 16 2.7" />
			<path d="M6.6 16.1c0-2.5 1-4.9 2.7-6.6 1.8-1.8 4.1-2.8 6.7-2.8.5 0 .9-.4.9-.9s-.4-.9-.9-.9c-3 0-5.8 1.2-8 3.3-2.1 2.1-3.3 4.9-3.3 7.9 0 .5.4.9.9.9s1-.4 1-.9" />
			<path d="M15.9 8.9c-3.9 0-7.1 3.2-7.1 7.1 0 .5.4.9.9.9s.9-.4.9-.9c0-2.9 2.4-5.3 5.3-5.3.5 0 .9-.4.9-.9s-.4-.9-.9-.9" />
		</svg>
	);
};

export default Microchip;

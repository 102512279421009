import * as styles from "./Button.module.scss";

import React, {
	type HTMLAttributes,
	useEffect,
	useMemo,
	useState,
} from "react";

import { pushToDataLayer } from "@dogstrust/src/utils/google";
import cx from "classnames";
import LinkNoStyle from "../LinkNoStyle/LinkNoStyle";

export type ButtonVariant =
	| "primary"
	| "secondary"
	| "tertiary"
	| "reverseprimary"
	| "reversetertiary"
	| "filter"
	| "filterselected";

export type ButtonType = "button" | "submit" | "reset";
export type ButtonSize = "standard" | "small";
export interface ButtonProps extends HTMLAttributes<HTMLButtonElement> {
	ariaLabel?: string;
	ariaPressed?: boolean;
	onClick?: () => void;
	type?: ButtonType;
	fullWidth?: boolean;
	variant?: ButtonVariant;
	size?: ButtonSize;
	IconLeft?: React.FC<IconProps>;
	IconRight?: React.FC<IconProps>;
	disabled?: boolean;
	/**
	 * - Given an absolute path `url` renders an `<a>` tag with no target specified.
	 * - Given a relative path `url` renders a `<Link>` component.
	 * */
	url?: string;
	/** Used to render an `<a>` tag with the attribute `target="_blank"` */
	externalUrl?: string;
	isLoading?: boolean;
}

const LoadingButton: React.FC<ButtonProps> = ({
	ariaLabel,
	type = "button",
	variant = "primary",
	size = "standard",
	fullWidth = false,
	tabIndex,
	IconLeft,
	IconRight,
	className,
	disabled,
}) => {
	const [loadingText, setLoadingText] = useState("Loading");

	useEffect(() => {
		const updateLoadingText = () => {
			if (loadingText.length < 15)
				return setLoadingText((prevText) => `${prevText}.`);
			return setLoadingText("Loading");
		};
		const timeout = setTimeout(updateLoadingText, 300);

		return () => clearTimeout(timeout);
	}, [loadingText]);

	return (
		<button
			style={{
				width: fullWidth ? "100%" : "auto",
				justifyContent: IconRight ? "space-between" : "space-evenly",
			}}
			type={type}
			aria-label={ariaLabel}
			className={cx(className, styles[`${variant}`], styles[`${size}`])}
			onClick={() => {}}
			disabled={disabled}
			tabIndex={tabIndex}
		>
			{IconLeft && (
				<div className={styles.iconleft}>
					{<IconLeft height={"25px"} width={"25px"} />}
				</div>
			)}
			<span className={styles.buttontext}>{loadingText}</span>
			{IconRight && (
				<div className={styles.iconright}>
					{<IconRight height={"25px"} width={"25px"} />}
				</div>
			)}
		</button>
	);
};

export const Button: React.FC<ButtonProps> = ({
	ariaLabel,
	ariaPressed,
	children,
	type = "button",
	onClick,
	url,
	externalUrl,
	variant = "primary",
	size = "standard",
	disabled = false,
	fullWidth = false,
	tabIndex,
	IconLeft,
	IconRight,
	className,
	isLoading,
	onKeyDown,
}) => {
	// if externalUrl is set, then the a tag will have target="_blank" set
	// if url is set but contains http(s) (i.e. it's an absolute path), it will *NOT* have target="_blank" set
	// if url is a relative path, this logic is unimportant - it will be an internal link
	const externalOrMailtoUrl = useMemo(() => {
		if (externalUrl) return externalUrl;
		if (url?.includes("mailto:")) return url;
		if (url?.includes("http")) return url;
		return null;
	}, [url, externalUrl]);

	if (isLoading)
		return (
			<LoadingButton
				{...{
					ariaLabel,
					children,
					type,
					onClick,
					url,
					externalUrl,
					variant,
					size,
					disabled,
					fullWidth,
					tabIndex,
					IconLeft,
					IconRight,
					className,
					isLoading,
				}}
			/>
		);

	if (externalOrMailtoUrl)
		return (
			// biome-ignore lint/a11y/useValidAnchor: <explanation>
			<a
				style={{
					width: fullWidth ? "100%" : "auto",
					textDecoration: "none",
				}}
				href={externalOrMailtoUrl}
				aria-label={ariaLabel}
				tabIndex={tabIndex}
				onClick={(e) => {
					e.preventDefault();
					pushToDataLayer({
						event: "button_click",
						button_clicked: children.toString(),
						button_url: url,
						button_location: window.location.href,
					});

					// If it's not on the same domain or sub-domain as the current page, open in a new tab
					const openInNewTab = !externalOrMailtoUrl.includes(
						window.location.hostname,
					);
					window.open(externalOrMailtoUrl, openInNewTab && "_blank");
				}}
			>
				<div
					style={{
						width: fullWidth ? "100%" : "auto",
						justifyContent:
							!!IconRight || !!IconLeft ? "space-between" : "space-evenly",
					}}
					className={cx(
						className,
						styles[`${variant}`],
						styles[`${size}`],
						styles.link,
					)}
				>
					{IconLeft && (
						<div className={styles.iconleft}>
							{<IconLeft height={"25px"} width={"25px"} />}
						</div>
					)}
					<span className={styles.buttontext}>{children}</span>
					{IconRight && (
						<div className={styles.iconright}>
							{<IconRight height={"25px"} width={"25px"} />}
						</div>
					)}
				</div>
			</a>
		);

	if (url)
		return (
			<LinkNoStyle
				style={{
					width: fullWidth ? "100%" : "auto",
				}}
				to={url}
				onClick={() =>
					pushToDataLayer({
						event: "button_click",
						button_clicked: children.toString(),
						button_url: url,
						button_location: window.location.href,
					})
				}
				ariaLabel={ariaLabel}
			>
				<div
					style={{
						width: fullWidth ? "100%" : "auto",
						justifyContent:
							!!IconRight || !!IconLeft ? "space-between" : "space-evenly",
					}}
					className={cx(
						className,
						styles[`${variant}`],
						styles[`${size}`],
						styles.link,
					)}
				>
					{IconLeft && (
						<div className={styles.iconleft}>
							{<IconLeft height={"25px"} width={"25px"} />}
						</div>
					)}
					<span className={styles.buttontext}>{children}</span>
					{IconRight && (
						<div className={styles.iconright}>
							{<IconRight height={"25px"} width={"25px"} />}
						</div>
					)}
				</div>
			</LinkNoStyle>
		);

	if (onClick || type === "submit")
		return (
			<button
				style={{
					width: fullWidth ? "100%" : "auto",
					justifyContent: IconRight ? "space-between" : "space-evenly",
				}}
				type={type}
				aria-label={ariaLabel}
				aria-pressed={ariaPressed}
				className={cx(className, styles[`${variant}`], styles[`${size}`])}
				onClick={() => {
					pushToDataLayer({
						event: "button_click",
						button_clicked: children.toString(),
						button_url: url,
						button_location: window.location.href,
					});
					onClick?.();
				}}
				onKeyDown={onKeyDown}
				disabled={disabled}
				tabIndex={tabIndex}
			>
				{IconLeft && (
					<div className={styles.iconleft}>
						{<IconLeft height={"25px"} width={"25px"} />}
					</div>
				)}
				<span className={styles.buttontext}>{children}</span>
				{IconRight && (
					<div className={styles.iconright}>
						{<IconRight height={"25px"} width={"25px"} />}
					</div>
				)}
			</button>
		);

	// Adding noise - we can turn this on for debugging.
	// console.log("Button has no URL or onClick event ", JSON.stringify(children));
	return null;
};

export default Button;

import React, { useCallback, useReducer, useRef } from "react";
import {
	onLoadCmsMapAction,
	onLoadMoreAction,
	onSelectCmsMapAction,
	onUpdateLocationAction,
	onUpdateSortOptionAction,
	onUpdateTravelDistanceAction,
} from "./CmsMap.actions";
import {
	cmsMapLocationsDefaultState,
	cmsMapLocationsReducer,
} from "./CmsMap.reducer";
import { CmsMapSortOption } from "./CmsMap.types";

import { CmsMapContext } from "./CmsMap.context";
import { DISTANCE_OPTIONS } from "./CmsMap.fixtures";

export const CmsMapProvider: React.FC<PropsWithChildren> = ({ children }) => {
	const [
		{
			pagination,
			sortBy,
			userLocation,
			currentDistance,
			cmsMapLocationsLocations,
			...rest
		},
		dispatch,
	] = useReducer(cmsMapLocationsReducer, cmsMapLocationsDefaultState);

	// ============================================ REDUCER ACTIONS =====================================================

	const onLoadCmsMap = useCallback(
		(allCmsMap: Queries.CmsMapCardFragment[]) =>
			dispatch(onLoadCmsMapAction(allCmsMap)),
		[],
	);
	const onSelectCmsMap = useCallback(
		(cmsMapLocationName: string) =>
			dispatch(onSelectCmsMapAction(cmsMapLocationName)),
		[],
	);
	const onUpdateTravelDistance = useCallback(
		(distance: number) => dispatch(onUpdateTravelDistanceAction(distance)),
		[],
	);

	const onUpdateLocation = useCallback(
		(userLocation: Place, distance: number) =>
			dispatch(onUpdateLocationAction(userLocation, distance)),
		[],
	);

	const onUpdateSort = useCallback(
		(selectedOption: CmsMapSortOption) =>
			dispatch(onUpdateSortOptionAction(selectedOption)),
		[],
	);

	// ============================================ FUNCTIONS =====================================================

	const distanceThrottle = useRef(null);
	/**
	 * On Change event for the distance slider. Only sets state when it's landed on expected value.
	 * Stops state from changing during slide event
	 * @param value The new value of the slider
	 */
	const safelyChangeDistance = useCallback(
		(value: string) => {
			if (DISTANCE_OPTIONS.includes(parseInt(value))) {
				// Stop changes on inbetween vals
				distanceThrottle.current = setTimeout(() => {
					onUpdateTravelDistance(parseInt(value));
				}, 300);
			}
		},
		[onUpdateTravelDistance],
	);

	const onLoadMore = useCallback(() => dispatch(onLoadMoreAction()), []);

	return (
		<CmsMapContext.Provider
			value={{
				...rest,
				cmsMapLocationsLocations,
				sortBy,
				pagination,
				currentDistance,
				userLocation,
				onLoadCmsMap,
				onSelectCmsMap,
				safelyChangeDistance,
				onUpdateTravelDistance,
				onUpdateLocation,
				onUpdateSort,
				onLoadMore,
			}}
		>
			{children}
		</CmsMapContext.Provider>
	);
};

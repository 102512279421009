import React from "react";

export const ShareWhatsApp: React.FC<IconProps> = ({
	height = "40px",
	width = "40px",
	fillPrimary = "#FFC800",
	fillSecondary = "#222",
	invert,
	...props
}) => {
	return (
		// biome-ignore lint/a11y/noSvgWithoutTitle: Aria hidden is used to hide the svg from screen readers
		<svg
			aria-hidden="true"
			focusable="false"
			width={width}
			height={height}
			viewBox="0 0 40 40"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<g fill="none" fillRule="evenodd">
				<path
					d="M19.247.093C32.708-1.111 41.26 9.549 39.848 22.339c-.763 8.97-8.305 17.51-17.661 17.657-5.779.074-11.955-.96-16.257-5.122C.96 29.785-1.432 21.957.897 15.12 2.912 6.424 10.385.636 19.247.093"
					fill={invert ? fillSecondary : fillPrimary}
				/>
				<path
					d="M10 30.5l5.531-1.453a10.13 10.13 0 004.969 1.265c1.875 0 3.617-.468 5.227-1.406a10.658 10.658 0 003.843-3.797A9.954 9.954 0 0031 19.906a9.884 9.884 0 00-.82-3.96 10.87 10.87 0 00-2.32-3.4c-1-.968-2.133-1.718-3.4-2.25A10.134 10.134 0 0020.5 9.5c-1.875 0-3.61.469-5.203 1.406a10.53 10.53 0 00-3.797 3.797 10.08 10.08 0 00-1.406 5.203c0 1.875.468 3.61 1.406 5.203L10 30.5zm10.5-1.922a8.358 8.358 0 01-4.406-1.219l-.328-.187-3.282.844.891-3.188-.188-.328a8.315 8.315 0 01-1.359-4.594A8.4 8.4 0 0113 15.57a8.775 8.775 0 013.164-3.164 8.4 8.4 0 014.336-1.172c1.156 0 2.258.22 3.305.657a8.686 8.686 0 012.812 1.898 9.271 9.271 0 011.945 2.836 8.05 8.05 0 01.704 3.281 8.275 8.275 0 01-1.196 4.336 8.904 8.904 0 01-3.21 3.164 8.525 8.525 0 01-4.36 1.172zm2.062-3.703c.407.063.829.063 1.266 0 .281-.031.617-.18 1.008-.445.39-.266.64-.532.75-.797.11-.266.172-.524.187-.774.016-.25.008-.406-.023-.468-.031-.063-.156-.141-.375-.235l-.14-.093c-.938-.47-1.532-.75-1.782-.844a1.377 1.377 0 00-.328-.047c-.094 0-.172.062-.234.187a16.17 16.17 0 01-.844 1.032c-.125.156-.313.172-.563.047-.875-.438-1.53-.829-1.968-1.172-.594-.47-1.11-1.11-1.547-1.922-.094-.094-.125-.18-.094-.258.031-.078.11-.18.234-.305.25-.25.453-.531.61-.843.062-.125.047-.282-.047-.47l-.797-1.921c-.063-.188-.133-.305-.211-.352a.586.586 0 00-.305-.07h-.562c-.25 0-.485.11-.703.328l-.047.047a2.964 2.964 0 00-.844 2.11c0 .812.344 1.703 1.031 2.671l.094.094c1.313 1.875 2.766 3.156 4.36 3.844.78.344 1.406.562 1.874.656z"
					fill={invert ? fillPrimary : fillSecondary}
					fillRule="nonzero"
				/>
			</g>
		</svg>
	);
};

export default ShareWhatsApp;

import React from "react";

export const Dislikes: React.FC<IconProps> = ({
	height = "88px",
	width = "88px",
	fill = "#f0c62f",
	...props
}) => {
	return (
		// biome-ignore lint/a11y/noSvgWithoutTitle: Aria hidden is used to hide the svg from screen readers
		<svg
			aria-hidden="true"
			focusable="false"
			width={width}
			height={height}
			viewBox="0 0 88 88"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				fill="#222"
				d="M42.8 11.6c21.9-2 35.8 15.4 33.5 36.2C75 62.4 62.8 76.2 47.6 76.5c-9.4.1-19.4-1.6-26.4-8.3-8.1-8.3-12-21-8.2-32.1 3.2-14.2 15.4-23.6 29.8-24.5"
			/>
			<path
				d="M53.7 22.5c1.8.6 2.8 2.9 2.9 5 0 .3 0 .5.1.8.5 3.7-1.5 7.3-4.8 7.9-2.5.4-5.2-.9-6.7-3.3-1.3-2.1-1.2-4.7-.8-7.1 0-.2.1-.4.2-.6.3-.8.8-1.4 1.5-1.9.2-.1.3-.2.5-.3 2.2-1.1 4.7-1.3 7.1-.5zm-14.8 1.4c1.8.6 2.8 2.9 2.9 5 0 .3 0 .5.1.8.5 3.7-1.5 7.3-4.8 7.9-2.5.4-5.2-.9-6.7-3.3-1.4-2.1-1.2-4.7-.8-7.1 0-.2.1-.4.2-.6.3-.8.8-1.4 1.5-1.9.2-.1.3-.2.5-.3 2.2-1.1 4.7-1.3 7.1-.5zm26.8 9.5c1.8.5 2.6 3.2 2.7 5 0 .2 0 .5.1.7.4 3.3-1.6 6.4-4.9 7-2.5.4-5.2-.7-6.7-2.8-1.3-1.9-1.1-4.1-.7-6.2 0-.2.1-.4.2-.5.3-.7 1.1-2 1.7-2.4.2-.1.3-.2.5-.3 2.3-1 4.8-1.2 7.1-.5zm-36.4 4.2c1.8.5 2.8 2.5 2.9 4.3 0 .2 0 .5.1.7.4 3.3-1.6 6.4-4.9 7-2.5.4-5.1-.7-6.7-2.8-1.3-1.9-1.3-5.1-.9-7.2 0-.2.1-.4.2-.5.3-.7.9-1.3 1.5-1.7.2-.1.3-.2.5-.3 2.2-1 4.9-.3 7.3.5zm19.6 1c2.4.7 3.7 3.4 3.7 5.9 0 .6.2.8.3 1 .5.7 1.2 1.5 2.5 1.7 1.1 0 2.3.2 3.4.6 2.5.7 3.8 3.4 4 6 0 .3.1.6.1 1 .6 4.5-2.2 8.8-6.8 9.6-2.4.4-4.9-.2-6.9-1.6-.6-.2-.8-.3-1.6-.4-2.2-.2-5.1 1.3-5.8 1.9-1.4 1.1-2.3 1.6-3.8 1.8-3.3.5-6.7-.9-8.7-3.7-1.7-2.4-1.4-5.4-.9-8.1.1-.2.1-.5.3-.7.4-.9 1.1-1.7 2-2.2.2-.1.4-.3.7-.4.5-.2.9-.4 1.4-.5.4-.1.7-.3.9-.5 0 0 .9-.8 1.3-1.5.2-.4.6-1.1 1-2.1l.2-.4c.1-.2.1-.4.2-.5.1-.9.2-1.8.4-2.7.1-.2.2-.5.3-.7.4-.9 1.1-1.7 2-2.2.2-.1.4-.3.7-.4 2.7-1.5 6-1.9 9.1-.9z"
				style={{
					fill,
				}}
			/>
		</svg>
	);
};

export default Dislikes;

import { pushToDataLayer } from "@dogstrust/src/utils/google";
import image from "../../static/img/dogComingSoon.svg";
import { Button } from "../common";
import { Cross } from "../common/Icons";
import ProcessedImage from "../common/ProcessedImage/ProcessedImage";
import * as styles from "./AddFavourite.module.scss";

import React from "react";

export interface AddFavouriteProps {
	dog: ESDocDog;
	onClick: () => void;
}

export const AddFavourite: React.FC<AddFavouriteProps> = ({ dog, onClick }) => {
	const heroImage =
		dog.media?.images?.find((img) => img.isHero) ||
		dog.media?.images?.[0] ||
		false;

	return (
		<>
			<div
				className={styles.iconwrapper}
				onClick={onClick}
				onKeyDown={(e) => {
					if (e.key === "Enter" || e.key === "Space") onClick();
				}}
			>
				<Cross />
			</div>
			<div className={styles.container}>
				<div>
					<h3>Yay, i'm your favourite!</h3>
					<p>
						Why not create an account to save your list of favourite dogs for as
						long as you like and access from any device.
					</p>
					<ProcessedImage
						className={styles.dogcardimage}
						src={heroImage ? heroImage.src : image}
						alt={heroImage ? heroImage.alt : ""}
						aria-hidden={heroImage ? "false" : "true"}
						dimensions="400x300"
						width={368}
						height={276}
						isDogImage
						noProcess={heroImage ? false : true}
						loading={"lazy"}
					/>
				</div>
				<Button
					fullWidth={true}
					variant="primary"
					onClick={() => {
						onClick();
						pushToDataLayer({
							event: "button_navigation",
							button_clicked: "create an account - modal",
						});
						window.location.href = `${process.env.GATSBY_PROFILE_SIGN_IN_URL}?ptrt=${window.location.pathname}`;
					}}
				>
					Create an account
				</Button>
				<Button onClick={onClick} fullWidth={true} variant="secondary">
					Not just yet
				</Button>
				<p className={styles.login}>
					Already have an account?{" "}
					<button
						className={styles.loginlink}
						type="button"
						onClick={() => {
							onClick();
							pushToDataLayer({
								event: "button_navigation",
								button_clicked: "log in to account - modal",
							});
							window.location.href = `${process.env.GATSBY_PROFILE_SIGN_IN_URL}?ptrt=${window.location.pathname}`;
						}}
					>
						Log in here
					</button>
				</p>
			</div>
		</>
	);
};

export default AddFavourite;

import * as styles from "./ListWithCount.module.scss";

import {
	ListWithCountItem,
	ListWithCountItemProps,
} from "../ListWithCountItem";

import React from "react";

export interface ListWithCountProps {
	items: ListWithCountItemProps[];
	disabled?: boolean;
}

export const ListWithCount: React.FC<ListWithCountProps> = ({
	items,
	disabled = false,
}) => {
	return (
		<div className={styles.listwithcount}>
			{items.map((item) => (
				<ListWithCountItem key={item.title} {...item} disabled={disabled} />
			))}
		</div>
	);
};

export default ListWithCount;

import React from "react";

export const MyAccountManage: React.FC<IconProps> = ({
	height = "40px",
	width = "40px",
	fillPrimary = "#222",
	fillSecondary = "#fff",
	invert,
	...props
}) => {
	return (
		// biome-ignore lint/a11y/noSvgWithoutTitle: Aria hidden is used to hide the svg from screen readers
		<svg
			aria-hidden="true"
			focusable="false"
			width={width}
			height={height}
			viewBox="0 0 98 98"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<g filter="url(#icon_ma_manage_svg__a)">
				<path
					fill={invert ? fillSecondary : fillPrimary}
					fillRule="evenodd"
					d="M47.495 9.186c26.921-2.408 44.023 18.912 41.202 44.491-1.527 17.94-16.61 35.02-35.324 35.316-11.556.147-23.91-1.92-32.513-10.246C10.92 68.57 6.136 52.914 10.794 39.24c4.03-17.391 18.975-28.966 36.7-30.053"
					clipRule="evenodd"
				/>
			</g>
			<path
				fill={invert ? fillPrimary : fillSecondary}
				fillRule="evenodd"
				d="M62.198 33.325c.466 3.75-1.47 7.291-4.828 7.905-2.5.435-5.17-.892-6.72-3.314-1.346-2.145-1.158-4.731-.768-7.078a4.354 4.354 0 0 1 1.681-2.532c.165-.12.338-.229.517-.326 2.237-1.08 4.755-1.319 7.118-.457 1.813.62 2.821 2.895 2.928 4.99.025.233.044.465.063.706v.001l.009.105Zm-19.673 9.312c3.358-.615 5.294-4.157 4.828-7.906l-.01-.115c-.019-.237-.038-.467-.062-.697-.106-2.095-1.115-4.37-2.928-4.99-2.363-.862-4.88-.623-7.117.457-.18.098-.353.207-.517.327a4.354 4.354 0 0 0-1.682 2.531c-.39 2.347-.578 4.934.769 7.078 1.549 2.422 4.22 3.75 6.719 3.315Zm-9.72 11.997c3.363-.578 5.338-3.713 4.916-7.001l-.006-.085c-.017-.215-.034-.422-.056-.629-.082-1.84-1.063-3.827-2.866-4.35-2.35-.73-5.063-1.43-7.31-.455-.18.088-.354.186-.52.294-.663.428-1.194 1.012-1.511 1.704-.08.173-.146.353-.198.539-.418 2.067-.442 5.279.879 7.148 1.519 2.11 4.17 3.246 6.673 2.835Zm36.223-3.431c3.362-.579 5.337-3.713 4.916-7.002l-.007-.083c-.017-.215-.034-.422-.055-.63-.083-1.84-.85-4.53-2.653-5.053-2.35-.731-4.868-.493-7.114.482a4.792 4.792 0 0 0-.52.293c-.663.43-1.409 1.717-1.726 2.409-.08.172-.146.352-.198.538-.418 2.067-.636 4.342.684 6.21 1.52 2.11 4.171 3.247 6.673 2.836Zm-10.867-1.33c-.016-.201-.033-.4-.054-.599-.108-2.402-1.389-4.998-3.744-5.68-3.068-.955-6.357-.643-9.29.63a6.178 6.178 0 0 0-.68.383c-.866.56-1.56 1.322-1.974 2.226-.104.226-.19.461-.258.704-.176.871-.325 1.77-.389 2.672-.463 1.208-1.074 2.727-1.338 3.043-.293.351-1.446 1.345-2.217 1.998-.463.149-.92.321-1.368.516a6.255 6.255 0 0 0-.68.383c-.865.56-1.558 1.322-1.973 2.226-.104.226-.19.46-.258.704-.547 2.699-.831 5.67.893 8.11 1.984 2.755 5.447 4.238 8.714 3.703a7.626 7.626 0 0 0 3.821-1.826c.116-.077 3.628-2.41 5.79-1.744a5.728 5.728 0 0 0 1.525.255c2.004 1.375 4.498 1.997 6.893 1.606 4.652-.797 7.39-5.088 6.811-9.586l-.01-.14c-.023-.285-.045-.56-.075-.836-.11-2.516-1.465-5.234-3.959-5.946a13.256 13.256 0 0 0-3.36-.568c-1.423-.58-2.287-1.417-2.82-2.233Z"
				clipRule="evenodd"
			/>
			<defs>
				<filter
					id="icon_ma_manage_svg__a"
					width={98}
					height={98}
					x={0}
					y={0}
					colorInterpolationFilters="sRGB"
					filterUnits="userSpaceOnUse"
				>
					<feFlood floodOpacity={0} result="BackgroundImageFix" />
					<feColorMatrix
						in="SourceAlpha"
						result="hardAlpha"
						values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
					/>
					<feOffset />
					<feGaussianBlur stdDeviation={4.5} />
					<feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.152234 0" />
					<feBlend
						in2="BackgroundImageFix"
						result="effect1_dropShadow_323_2525"
					/>
					<feBlend
						in="SourceGraphic"
						in2="effect1_dropShadow_323_2525"
						result="shape"
					/>
				</filter>
			</defs>
		</svg>
	);
};

export default MyAccountManage;

import React, { useEffect, useState } from "react";

export const useClientSide = () => {
	const [isClientSide, setIsClientSide] = useState(false);

	useEffect(() => {
		setIsClientSide(true);
	}, []);

	return isClientSide;
};

import React from "react";

export const StepUser: React.FC<IconProps> = ({
	height = "48px",
	width = "48px",
	fillPrimary = "#fff",
	fillSecondary = "#222",
	invert,
	...props
}) => {
	return (
		// biome-ignore lint/a11y/noSvgWithoutTitle: Aria hidden is used to hide the svg from screen readers
		<svg
			aria-hidden="true"
			focusable="false"
			width={width}
			height={height}
			viewBox="0 0 48 48"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				fill={invert ? fillSecondary : fillPrimary}
				fillRule="evenodd"
				d="M23.097.112C39.25-1.333 49.51 11.459 47.818 26.806c-.916 10.764-9.966 21.013-21.194 21.19-6.934.088-14.346-1.153-19.508-6.148-5.965-6.106-8.834-15.5-6.04-23.704C3.494 7.708 12.462.764 23.096.111"
				clipRule="evenodd"
			/>
			<path
				fill={invert ? fillPrimary : fillSecondary}
				d="M24 24.375a5.698 5.698 0 0 0 4.941-2.875c1.034-1.752 1.034-3.953 0-5.75-1.033-1.752-2.92-2.875-4.941-2.875-2.066 0-3.953 1.123-4.986 2.875-1.034 1.797-1.034 3.998 0 5.75A5.716 5.716 0 0 0 24 24.375Zm-2.066 2.156c-4.448 0-7.997 3.594-7.997 8.041 0 .719.585 1.303 1.303 1.303h17.475c.719 0 1.347-.584 1.347-1.303a8.033 8.033 0 0 0-8.04-8.04h-4.088Z"
			/>
		</svg>
	);
};

export default StepUser;

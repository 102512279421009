const conversionFactor = 0.62137119224;
export function haversineDistance(
	placeA?: Place,
	placeB?: Place,
	inMetres?: boolean,
): number {
	if (!placeA || !placeB) return NaN;
	const { latitude: aLat, longitude: aLng } = placeA;
	const { latitude: bLat, longitude: bLng } = placeB;

	const p = 0.017453292519943295; // Math.PI / 180
	const c = Math.cos;
	const a =
		0.5 -
		c((bLat - aLat) * p) / 2 +
		(c(aLat * p) * c(bLat * p) * (1 - c((bLng - aLng) * p))) / 2;

	const distance = 12742 * Math.asin(Math.sqrt(a)); // 2 * R; R = 6371 km
	return inMetres ? distance : distance * conversionFactor;
}

export function isInRangeFilter(
	location: Place,
	destination: Place,
	distance: number,
): boolean {
	const distanceBetween = haversineDistance(location, destination, true);
	return !Number.isNaN(distanceBetween) && distanceBetween < distance;
}

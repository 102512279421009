export const Colours = {
	SANDY_FUR: "#FAF5E6",
	WHITE: "#FFFFFF",
	DAWN: "#7e7e7e",
	MIDNIGHT: "#222222",
	SUNSHINE_YELLOW: "#ffc800",
	OATMEAL: "#D2C8B9",
	OATMEAL_LIGHT: "#EDEAE4",
	BLUE_SKY_DARK: "#6296A1",
	DAWN_AA: "#737373",
	TEAL: "#00B2A9",
	transparent: "transparent",
};

import React from "react";

export const QuoteMarks: React.FC<IconProps> = ({
	height = "21px",
	width = "17px",
	fill = "#222",
	...props
}) => {
	return (
		// biome-ignore lint/a11y/noSvgWithoutTitle: Aria hidden is used to hide the svg from screen readers
		<svg
			aria-hidden="true"
			focusable="false"
			width={width}
			height={height}
			viewBox="0 0 211.3 169.98"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<g id="icon_quotes_svg__b">
				<path
					fill={fill}
					className="icon_quotes_svg__c"
					d="M115.04 0h90.15l6.11 169.98h-64.33L115.04 0zM0 0h90.15l6.11 169.98H31.93L0 0z"
				/>
			</g>
		</svg>
	);
};

export default QuoteMarks;

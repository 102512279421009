import { CmsMapSortOption } from "./CmsMap.types";

// ========================== LOAD_ALL_CMS_MAP_LOCATIONS ===================================
export const LOAD_ALL_CMS_MAP_LOCATIONS = "LOAD_ALL_CMS_MAP_LOCATIONS";
interface LoadCmsMapAction {
	type: typeof LOAD_ALL_CMS_MAP_LOCATIONS;
	allCmsMap: Queries.CmsMapCardFragment[];
}

export const onLoadCmsMapAction = (
	allCmsMap: Queries.CmsMapCardFragment[],
): LoadCmsMapAction => ({
	type: LOAD_ALL_CMS_MAP_LOCATIONS,
	allCmsMap,
});

// ========================== SELECT_CMS_MAP_LOCATION ===================================
export const SELECT_CMS_MAP_LOCATION = "SELECT_CMS_MAP_LOCATION";
interface SelectCmsMapAction {
	type: typeof SELECT_CMS_MAP_LOCATION;
	cmsMapLocationName: string;
}

export const onSelectCmsMapAction = (
	cmsMapLocationName: string,
): SelectCmsMapAction => ({
	type: SELECT_CMS_MAP_LOCATION,
	cmsMapLocationName,
});

// ========================== UPDATE_SORT_OPTION ===================================
export const UPDATE_SORT_OPTION = "UPDATE_SORT_OPTION";
interface UpdateSortOptionAction {
	type: typeof UPDATE_SORT_OPTION;
	selectedOption: CmsMapSortOption;
}

export const onUpdateSortOptionAction = (
	selectedOption: CmsMapSortOption,
): UpdateSortOptionAction => ({
	type: UPDATE_SORT_OPTION,
	selectedOption,
});

// ========================== LOAD_MORE ==================================
export const LOAD_MORE = "LOAD_MORE";
interface LoadMoreAction {
	type: typeof LOAD_MORE;
}
export const onLoadMoreAction = (): LoadMoreAction => ({
	type: LOAD_MORE,
});

// ========================== UPDATE_TRAVEL_DISTANCE =========================
export const UPDATE_TRAVEL_DISTANCE = "UPDATE_TRAVEL_DISTANCE";
interface UpdateTravelDistanceAction {
	type: typeof UPDATE_TRAVEL_DISTANCE;
	distance: number;
}
export const onUpdateTravelDistanceAction = (
	distance: number,
): UpdateTravelDistanceAction => ({
	type: UPDATE_TRAVEL_DISTANCE,
	distance,
});

// ========================== UPDATE_LOCATION_SUGGESTIONS ====================
export const UPDATE_LOCATION_SUGGESTIONS = "UPDATE_LOCATION_SUGGESTIONS";
interface UpdateLocationSuggestionsAction {
	type: typeof UPDATE_LOCATION_SUGGESTIONS;
	locations: string[];
}
export const onUpdateLocationSuggestionsAction = (
	locations: string[],
): UpdateLocationSuggestionsAction => {
	return {
		type: UPDATE_LOCATION_SUGGESTIONS,
		locations,
	};
};

// ========================== UPDATE_LOCATION ==========================
export const UPDATE_LOCATION = "UPDATE_LOCATION";
interface UpdateLocationAction {
	type: typeof UPDATE_LOCATION;
	userLocation: Place | null;
	distance: number;
}

export const onUpdateLocationAction = (
	userLocation: Place | null,
	distance: number,
): UpdateLocationAction => ({ type: UPDATE_LOCATION, userLocation, distance });

// ========================== UPDATE_CMS_MAP_LOCATIONS_IN_RANGE ==========================
export const UPDATE_CMS_MAP_LOCATIONS_IN_RANGE =
	"UPDATE_CMS_MAP_LOCATIONS_IN_RANGE";
interface UpdateCmsMapInRangeAction {
	type: typeof UPDATE_CMS_MAP_LOCATIONS_IN_RANGE;
	cmsMapLocationsInRange: Queries.CmsMapCardFragment[];
	distanceToCmsMap: { [key: string]: number };
}

export const onUpdateCmsMapInRangeAction = (
	cmsMapLocationsInRange: Queries.CmsMapCardFragment[],
	distanceToCmsMap: { [key: string]: number },
): UpdateCmsMapInRangeAction => ({
	type: UPDATE_CMS_MAP_LOCATIONS_IN_RANGE,
	cmsMapLocationsInRange,
	distanceToCmsMap,
});

export type CmsMapAction =
	| LoadCmsMapAction
	| SelectCmsMapAction
	| UpdateSortOptionAction
	| LoadMoreAction
	| UpdateTravelDistanceAction
	| UpdateLocationSuggestionsAction
	| UpdateLocationAction
	| UpdateCmsMapInRangeAction;

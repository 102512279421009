import React from "react";

export const StepCheck: React.FC<IconProps> = ({
	height = "48px",
	width = "48px",
	fillPrimary = "#FFC800",
	fillSecondary = "#222",
	...props
}) => {
	return (
		// biome-ignore lint/a11y/noSvgWithoutTitle: Aria hidden is used to hide the svg from screen readers
		<svg
			aria-hidden="true"
			focusable="false"
			width={width}
			height={height}
			viewBox="0 0 48 48"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				fill={fillSecondary}
				fillRule="evenodd"
				d="M23.097.112C39.25-1.333 49.51 11.459 47.818 26.806c-.916 10.764-9.966 21.013-21.194 21.19-6.934.088-14.346-1.153-19.508-6.148-5.965-6.106-8.834-15.5-6.04-23.704C3.494 7.708 12.462.764 23.096.111"
				clipRule="evenodd"
			/>
			<path
				fill={fillPrimary}
				d="M31.941 18.309a1.144 1.144 0 0 1 0 1.67l-9.5 9.5a1.144 1.144 0 0 1-1.67 0l-4.75-4.75a1.144 1.144 0 0 1 0-1.67 1.144 1.144 0 0 1 1.67 0l3.934 3.896 8.646-8.646a1.144 1.144 0 0 1 1.67 0Z"
			/>
		</svg>
	);
};

export default StepCheck;

import React from "react";

export const UserLoggedIn: React.FC<IconProps> = ({
	height = "32px",
	width = "32px",
	...props
}) => {
	return (
		// biome-ignore lint/a11y/noSvgWithoutTitle: Aria hidden is used to hide the svg from screen readers
		<svg
			aria-hidden="true"
			focusable="false"
			width={width}
			height={height}
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 32 32"
			{...props}
		>
			<path
				fill="#222"
				fillRule="evenodd"
				d="M16 32c2.88 0 5.55-.72 8-2.16 2.45-1.44 4.4-3.39 5.84-5.84 1.44-2.45 2.16-5.12 2.16-8s-.72-5.55-2.16-8C28.4 5.55 26.45 3.6 24 2.16 21.55.72 18.88 0 16 0s-5.55.72-8 2.16C5.55 3.6 3.6 5.55 2.16 8 .72 10.45 0 13.12 0 16s.72 5.55 2.16 8C3.6 26.45 5.55 28.4 8 29.84c2.45 1.44 5.12 2.16 8 2.16Zm10.26-6.58a6.323 6.323 0 0 0-2.1-3.77c-1.14-1.01-2.48-1.52-4.03-1.52-.39 0-.9.11-1.55.32-.9.26-1.76.39-2.58.39-.82 0-1.68-.13-2.58-.39-.65-.21-1.16-.32-1.55-.32-1.55 0-2.89.51-4.03 1.52a6.352 6.352 0 0 0-2.1 3.77 13.792 13.792 0 0 1-2.71-4.32 13.64 13.64 0 0 1-.97-5.1c0-2.54.62-4.87 1.87-7s2.94-3.82 5.06-5.06c2.13-1.25 4.46-1.87 7-1.87s4.87.62 7 1.87S26.81 6.88 28.05 9c1.25 2.13 1.87 4.46 1.87 7 0 1.76-.32 3.46-.97 5.1-.65 1.63-1.55 3.08-2.71 4.32h.02ZM20 16.9c-1.12 1.12-2.45 1.68-4 1.68-1.55 0-2.88-.56-4-1.68-1.12-1.12-1.68-2.45-1.68-4 0-1.55.56-2.88 1.68-4 1.12-1.12 2.45-1.68 4-1.68 1.55 0 2.88.56 4 1.68 1.12 1.12 1.68 2.45 1.68 4 0 1.55-.56 2.88-1.68 4Z"
				clipRule="evenodd"
			/>
		</svg>
	);
};

export default UserLoggedIn;

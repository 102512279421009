import { Colours } from "@dogstrust/src/utils/colours";
import React from "react";
import { withSafeRotateSvg } from "../../../hoc/withSafeRotateSvg";

export interface IconProps {
	height?: string;
	width?: string;
	withBorder?: boolean;
	fill?: string;
	line?: string;
}
const ArrowCircle: React.FC<IconProps> = ({
	height = "40px",
	width = "40px",
	withBorder = true,
	fill = Colours.SUNSHINE_YELLOW,
	line = Colours.MIDNIGHT,
	...props
}) => {
	if (withBorder) {
		return (
			// biome-ignore lint/a11y/noSvgWithoutTitle: Aria hidden is used to hide the svg from screen readers
			<svg
				aria-hidden="true"
				focusable="false"
				width={width}
				height={height}
				viewBox="0 0 48 48"
				xmlns="http://www.w3.org/2000/svg"
				{...props}
			>
				<g transform="matrix(-1 0 0 1 48 0)" fill="none" fillRule="evenodd">
					<circle cx={24} cy={24} r={23} fill={fill} />
					<path
						d="M39 24.018c0-.761-.578-1.378-1.292-1.378H16.574l5.251-5.222c.357-.331.516-.843.415-1.336a1.346 1.346 0 00-.901-1.027 1.237 1.237 0 00-1.277.35l-7.651 7.603c-.262.26-.411.626-.411 1.008s.149.747.41 1.007l7.652 7.606c.522.52 1.34.49 1.826-.067a1.444 1.444 0 00-.063-1.948l-5.251-5.219h21.134c.714 0 1.292-.616 1.292-1.377z"
						fill={line}
						fillRule="nonzero"
					/>
				</g>
			</svg>
		);
	}
	return (
		// biome-ignore lint/a11y/noSvgWithoutTitle: Aria hidden is used to hide the svg from screen readers
		<svg
			{...props}
			aria-hidden="true"
			width={width}
			height={height}
			viewBox="0 0 16 10"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M15.68 5.01a.724.724 0 00-.712-.735H3.321l2.894-2.786a.749.749 0 00.228-.712.726.726 0 00-.496-.548.697.697 0 00-.704.187L1.026 4.471a.746.746 0 00-.226.537c0 .204.082.399.226.538l4.217 4.056a.697.697 0 001.007-.036.752.752 0 00-.035-1.038L3.32 5.744h11.647a.724.724 0 00.712-.735z"
				fill={fill}
				fillRule="nonzero"
			/>
		</svg>
	);
};

export default withSafeRotateSvg<IconProps>(ArrowCircle);

import React from "react";

export const Medical: React.FC<IconProps> = ({
	height = "40px",
	width = "40px",
	...props
}) => {
	return (
		// biome-ignore lint/a11y/noSvgWithoutTitle: Aria hidden is used to hide the svg from screen readers
		<svg
			aria-hidden="true"
			focusable="false"
			viewBox="0 0 50 50"
			height={height}
			width={width}
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<defs>
				<style>{".icon_medical_svg__cls-1{fill:none}"}</style>
			</defs>
			<path
				className="icon_medical_svg__cls-1"
				d="M38.92 17.23a8.284 8.284 0 0 0-5.86-5.29c-2.44-.56-4.88-.05-7.24 1.52l-.83.55-.83-.55c-1.62-1.07-4.21-2.21-7.28-1.52a8.179 8.179 0 0 0-5.78 5.27c-1.02 2.82-1.6 8.42 5.67 14.83h.09l.37.39 6.43 5.34c.72.64 1.71.68 2.45.15l.13-.12c.51-.39 1.92-1.56 6.58-5.42 7.63-6.51 7.09-12.29 6.08-15.15Zm-6.06 8.84c0 1.21-.98 2.19-2.19 2.19h-1.39v1.39c0 1.21-.98 2.19-2.19 2.19h-4.16a2.19 2.19 0 0 1-2.19-2.19v-1.39h-1.39a2.19 2.19 0 0 1-2.19-2.19v-4.15c0-1.21.98-2.19 2.19-2.19h1.39v-1.39c0-1.21.98-2.19 2.19-2.19h4.16c1.21 0 2.19.98 2.19 2.19v1.39h1.39c1.21 0 2.19.98 2.19 2.19v4.15Z"
			/>
			<path
				className="icon_medical_svg__cls-1"
				d="M26.28 19.14h-2.54v3.59h-3.59v2.53h3.59v3.59h2.54v-3.59h3.58v-2.53h-3.58v-3.59z"
			/>
			<path d="M41.75 16.24c-1.25-3.58-4.31-6.34-8-7.23-2.95-.68-5.96-.18-8.76 1.43-2.12-1.21-5.16-2.24-8.78-1.43-3.68.88-6.72 3.65-7.93 7.19-1.3 3.58-2.12 10.73 6.95 18.44l.37.39h.11l6.01 4.99c.91.81 2.04 1.24 3.28 1.24 1.07 0 2.08-.33 2.94-.97l.16-.09.12-.14c.63-.49 2.12-1.73 6.55-5.41 9.01-7.69 8.24-14.85 6.98-18.43Zm-8.91 16.14c-4.65 3.87-6.07 5.03-6.58 5.42l-.13.12c-.74.53-1.73.49-2.45-.15l-6.43-5.34-.37-.39h-.09c-7.28-6.4-6.7-12-5.67-14.83a8.179 8.179 0 0 1 5.78-5.27c3.07-.69 5.66.45 7.28 1.52l.83.55.83-.55c2.36-1.57 4.8-2.08 7.24-1.52 2.7.65 4.94 2.67 5.86 5.29 1.01 2.86 1.55 8.64-6.08 15.15Z" />
			<path d="M30.67 19.73h-1.39v-1.39c0-1.21-.98-2.19-2.19-2.19h-4.16c-1.21 0-2.19.98-2.19 2.19v1.39h-1.39c-1.21 0-2.19.98-2.19 2.19v4.15c0 1.21.98 2.19 2.19 2.19h1.39v1.39c0 1.21.98 2.19 2.19 2.19h4.16c1.21 0 2.19-.98 2.19-2.19v-1.39h1.39c1.21 0 2.19-.98 2.19-2.19v-4.15c0-1.21-.98-2.19-2.19-2.19Zm-.81 5.54h-3.59v3.59h-2.54v-3.59h-3.59v-2.54h3.59v-3.59h2.54v3.59h3.59v2.54Z" />
		</svg>
	);
};

export default Medical;

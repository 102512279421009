export const POSTCODES_BASE_URL = "https://api.postcodes.io/postcodes";
export const POSTCODES_ENDPOINT = "/autocomplete";
export const DISTANCE_OPTIONS = [10, 30, 50, 70, 90, 110, 130, 150];

export const COUNTY_OPTIONS = [
	{ county: "All Counties", centres: ["LEN", "CON", "MUN", "ALL"] },
	{ county: "Carlow", centres: ["LEN", "ALL"] },
	{ county: "Cavan", centres: ["CON", "ALL"] },
	{ county: "Clare", centres: ["MUN", "ALL"] },
	{ county: "Cork", centres: ["MUN", "ALL"] },
	{ county: "Donegal", centres: ["CON", "ALL"] },
	{ county: "Dublin", centres: ["LEN", "DUB", "ALL"] },
	{ county: "Galway", centres: ["CON", "ALL"] },
	{ county: "Kerry", centres: ["MUN", "ALL"] },
	{ county: "Kildare", centres: ["LEN", "ALL"] },
	{ county: "Kilkenny", centres: ["LEN", "ALL"] },
	{ county: "Laois", centres: ["LEN", "ALL"] },
	{ county: "Leitrim", centres: ["CON", "ALL"] },
	{ county: "Limerick", centres: ["MUN", "ALL"] },
	{ county: "Longford", centres: ["LEN", "ALL"] },
	{ county: "Louth", centres: ["LEN", "ALL"] },
	{ county: "Mayo", centres: ["CON", "ALL"] },
	{ county: "Meath", centres: ["LEN", "ALL"] },
	{ county: "Monaghan", centres: ["CON", "ALL"] },
	{ county: "Offaly", centres: ["LEN", "ALL"] },
	{ county: "Roscommon", centres: ["MUN", "CON", "ALL"] },
	{ county: "Sligo", centres: ["CON", "ALL"] },
	{ county: "Tipperary", centres: ["LEN", "MUN", "ALL"] },
	{ county: "Waterford", centres: ["LEN", "MUN", "ALL"] },
	{ county: "Westmeath", centres: ["LEN", "ALL"] },
	{ county: "Wexford", centres: ["LEN", "ALL"] },
	{ county: "Wicklow", centres: ["LEN", "ALL"] },
];

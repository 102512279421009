import React from "react";

export const MyAccountRehoming: React.FC<IconProps> = ({
	height = "40px",
	width = "40px",
	fillPrimary = "#222",
	fillSecondary = "#fff",
	invert,
	...props
}) => {
	return (
		// biome-ignore lint/a11y/noSvgWithoutTitle: Aria hidden is used to hide the svg from screen readers
		<svg
			aria-hidden="true"
			focusable="false"
			width={width}
			height={height}
			viewBox="0 0 98 98"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<g filter="url(#icon_ma_rehoming_svg__a)">
				<path
					fill={invert ? fillSecondary : fillPrimary}
					fillRule="evenodd"
					d="M47.495 9.186c26.921-2.408 44.023 18.912 41.202 44.491-1.527 17.94-16.61 35.02-35.324 35.316-11.556.147-23.91-1.92-32.513-10.246C10.92 68.57 6.136 52.914 10.794 39.24c4.03-17.391 18.975-28.966 36.7-30.053Z"
					clipRule="evenodd"
				/>
			</g>
			<path
				fill={invert ? fillPrimary : fillSecondary}
				fillRule="evenodd"
				d="M41.847 36.706s.658 1.055.253 1.507c-.405.453-8.5 2.664-8.5 2.664s-3.037 1.256-3.441 0c-.188-.583-.289-1.383-.378-2.093-.104-.82-.192-1.518-.381-1.626-.068-.038-.283-.053-.602-.074-1.351-.091-4.56-.308-6.28-2.991-.91-1.357-1.518-2.966-1.518-3.116 0-.15.405-1.206 1.214-1.307.18-.022.782-.119 1.616-.253 2.925-.47 8.712-1.4 9.263-1.204-.405-.452-1.012-3.82-.96-4.07.05-.252 1.72-.201 2.782.402s3.946 2.362 6.932 12.16ZM30.209 45.45a1.45 1.45 0 0 0-1.455-1.444A1.45 1.45 0 0 0 27.3 45.45a1.45 1.45 0 0 0 1.454 1.445 1.45 1.45 0 0 0 1.455-1.445Zm1.407-1.759c-.77.352-.243 1.759-.243 1.759s6.122 26.082 6.477 27.439c.354 1.357 1.568 2.21 3.288 1.608 1.376-.483 1.231-1.64 1.133-2.418a3.239 3.239 0 0 1-.04-.497c.03-.653 0-12.413 0-12.413l1.387-.15s8.197-.704 14.37-1.005c1.67 3.819 6.224 5.628 6.224 5.628l.455 9.146s.658 2.161 2.53 2.211c1.872.05 2.074-2.06 2.074-2.06l1.013-10.353s1.113-12.864 1.518-13.82c6.881-3.517 6.375-9.497 6.021-11.357-.354-1.859-1.366-.826-1.366 0 0 .12.005.24.01.362.032.729.068 1.54-.972 2.678-1.619 2.739-5.869 3.946-6.932 3.895-1.062-.05-14.572-.854-18.822-1.395-4.118-.523-4.959-1.364-5.56-1.964l-.057-.058c-.607-.603-1.467-.502-1.893-.402-.424.1-9.846 2.814-10.615 3.166Z"
				clipRule="evenodd"
			/>
			<path
				fill={invert ? fillPrimary : fillSecondary}
				fillRule="evenodd"
				d="M41.847 36.706s.658 1.055.253 1.507c-.405.453-8.5 2.664-8.5 2.664s-3.037 1.256-3.441 0c-.405-1.256-.405-3.518-.76-3.719-.354-.2-4.756.251-6.881-3.065-.91-1.357-1.518-2.966-1.518-3.116 0-.15.405-1.206 1.214-1.307.81-.1 10.17-1.708 10.88-1.457-.406-.452-1.013-3.82-.962-4.07.05-.252 1.72-.201 2.783.402 1.062.603 3.946 2.362 6.932 12.16M30.21 45.45a1.45 1.45 0 0 0-1.456-1.444A1.45 1.45 0 0 0 27.3 45.45a1.45 1.45 0 0 0 1.454 1.445 1.45 1.45 0 0 0 1.455-1.445M31.373 45.45s-.526-1.407.243-1.759c.77-.351 10.19-3.065 10.616-3.165.425-.101 1.285-.202 1.892.401.607.604 1.366 1.482 5.617 2.023 4.25.54 17.76 1.344 18.822 1.394 1.063.051 5.313-1.156 6.932-3.895 1.215-1.33.962-2.213.962-3.04 0-.826 1.012-1.858 1.366 0 .354 1.86.86 7.84-6.021 11.358-.405.955-1.518 13.82-1.518 13.82L69.27 72.939s-.202 2.11-2.074 2.06c-1.872-.05-2.53-2.211-2.53-2.211l-.455-9.146s-4.554-1.809-6.224-5.628c-6.173.3-14.37 1.005-14.37 1.005l-1.386.15s.03 11.76 0 12.413c-.031.654.627 2.312-1.094 2.915-1.72.603-2.934-.251-3.288-1.608-.355-1.357-6.477-27.439-6.477-27.439Z"
				clipRule="evenodd"
			/>
			<defs>
				<filter
					id="icon_ma_rehoming_svg__a"
					width={98}
					height={98}
					x={0}
					y={0}
					colorInterpolationFilters="sRGB"
					filterUnits="userSpaceOnUse"
				>
					<feFlood floodOpacity={0} result="BackgroundImageFix" />
					<feColorMatrix
						in="SourceAlpha"
						result="hardAlpha"
						values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
					/>
					<feOffset />
					<feGaussianBlur stdDeviation={4.5} />
					<feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.152234 0" />
					<feBlend
						in2="BackgroundImageFix"
						result="effect1_dropShadow_323_2512"
					/>
					<feBlend
						in="SourceGraphic"
						in2="effect1_dropShadow_323_2512"
						result="shape"
					/>
				</filter>
			</defs>
		</svg>
	);
};

export default MyAccountRehoming;

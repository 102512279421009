import React from "react";
import { withSafeRotateSvg } from "../../hoc/withSafeRotateSvg";

const CurvedBorderThick: React.FC<CurveProps> = ({ fill = "#FFC800" }) => {
	return (
		// biome-ignore lint/a11y/noSvgWithoutTitle: aria-hidden="true" is used to hide the svg from screen readers
		<svg
			aria-hidden="true"
			focusable="false"
			xmlns="http://www.w3.org/2000/svg"
			viewBox="-783.467 -103.904 1920 484"
		>
			<path
				d="M1136.533-103.904c-50.12 6.74-112.734 24.88-187.374 56.686C527.632 132.288 324.774 203.113-622.15-4.863c-53.499-11.745-107.417-20.844-161.316-27.584v412.543h1920v-484Z"
				fill={fill}
				fillRule="evenodd"
			/>
		</svg>
	);
};

export default withSafeRotateSvg<CurveProps>(CurvedBorderThick);

import React from "react";
import { Add } from "./Add";
import { Age } from "./Age";
import { default as Arrow } from "./Arrow";
import { default as ArrowCircle } from "./ArrowCircle";
import { Breed } from "./Breed";
import { BurgerMenu } from "./BurgerMenu";
import { Camera } from "./Camera";
import { ChangeEmail } from "./ChangeEmail";
import { default as Chevron } from "./Chevron";
import { Clock } from "./Clock";
import { Cross } from "./Cross";
import { Dislikes } from "./Dislikes";
import { DogSizeGiant } from "./DogSizeGiant";
import { DogSizeLarge } from "./DogSizeLarge";
import { DogSizeMedium } from "./DogSizeMedium";
import { DogSizeSmall } from "./DogSizeSmall";
import { DogSizesAll } from "./DogSizesAll";
import { Download } from "./Download";
import { DropDown } from "./DropDown";
import { Envelope } from "./Envelope";
import { Expand } from "./Expand";
import { Filter } from "./Filter";
import { FundraisingRegulator } from "./FundraisingRegulator";
import { GPS } from "./GPS";
import { HearingLoop } from "./HearingLoop";
import { Heart } from "./Heart";
import { HeartFilled } from "./HeartFilled";
import { HeartLarge } from "./HeartLarge";
import { HeartOffset } from "./HeartOffset";
import { Home } from "./Home";
import { Info } from "./Info";
import { Likes } from "./Likes";
import { Location } from "./Location";
import { LogoWagForDogLovers } from "./LogoWagForDogLovers";
import { Measurements } from "./Measurements";
import { Medical } from "./Medical";
import { Microchip } from "./Microchip";
import { MyAccountFavourites } from "./MyAccountFavourites";
import { MyAccountLogout } from "./MyAccountLogout";
import { MyAccountManage } from "./MyAccountManage";
import { MyAccountRehoming } from "./MyAccountRehoming";
import { MyAccountSad } from "./MyAccountSad";
import { OffSite } from "./OffSite";
import { Phone } from "./Phone";
import { PrimaryLogo } from "./PrimaryLogo";
import { PrimaryLogoReduced } from "./PrimaryLogoReduced";
import { Profile } from "./Profile";
import { QuoteMarks } from "./QuoteMarks";
import { Reference } from "./Reference";
import { ResetPassword } from "./ResetPassword";
import { RotateDevice } from "./RotateDevice";
import { Search } from "./Search";
import { Sex } from "./Sex";
import { ShareEmail } from "./ShareEmail";
import { ShareFacebook } from "./ShareFacebook";
import { ShareWhatsApp } from "./ShareWhatsApp";
import { ShareX } from "./ShareX";
import { Shedding } from "./Shedding";
import { Shrink } from "./Shrink";
import { SocialFacebook } from "./SocialFacebook";
import { SocialInstagram } from "./SocialInstagram";
import { SocialYoutube } from "./SocialYoutube";
import { Sort } from "./Sort";
import { Star } from "./Star";
import { StepCheck } from "./StepCheck";
import { StepDog } from "./StepDog";
import { StepHome } from "./StepHome";
import { StepLiving } from "./StepLiving";
import { StepPrivacy } from "./StepPrivacy";
import { StepUser } from "./StepUser";
import { Tag } from "./Tag";
import { UserLoggedIn } from "./UserLoggedIn";
import { UserLoggedOut } from "./UserLoggedOut";
import { Video } from "./Video";
import { Wheelchair } from "./Wheelchair";

export const Icons = {
	Add,
	Age,
	Arrow,
	ArrowCircle,
	Breed,
	BurgerMenu,
	Camera,
	ChangeEmail,
	Chevron,
	Clock,
	Cross,
	Dislikes,
	DogSizeGiant,
	DogSizeLarge,
	DogSizeMedium,
	DogSizeSmall,
	DogSizesAll,
	Download,
	DropDown,
	Envelope,
	Expand,
	Filter,
	FundraisingRegulator,
	GPS,
	HearingLoop,
	Heart,
	HeartLarge,
	HeartFilled,
	HeartOffset,
	Home,
	Info,
	Likes,
	Location,
	Medical,
	Measurements,
	Microchip,
	MyAccountFavourites,
	MyAccountLogout,
	MyAccountManage,
	MyAccountRehoming,
	MyAccountSad,
	OffSite,
	Phone,
	Profile,
	PrimaryLogo,
	PrimaryLogoReduced,
	QuoteMarks,
	Reference,
	ResetPassword,
	RotateDevice,
	Search,
	Sex,
	ShareEmail,
	ShareFacebook,
	ShareX,
	Shrink,
	ShareWhatsApp,
	Shedding,
	SocialFacebook,
	SocialInstagram,
	SocialYoutube,
	Sort,
	Star,
	StepCheck,
	StepDog,
	StepHome,
	StepLiving,
	StepPrivacy,
	StepUser,
	Tag,
	UserLoggedOut,
	UserLoggedIn,
	Video,
	Wheelchair,
};

export type IconName = keyof typeof Icons;
export {
	Icons as default,
	Add,
	Age,
	Arrow,
	ArrowCircle,
	Breed,
	BurgerMenu,
	Camera,
	ChangeEmail,
	Chevron,
	Clock,
	Cross,
	Dislikes,
	DogSizeGiant,
	DogSizeLarge,
	DogSizeMedium,
	DogSizeSmall,
	DogSizesAll,
	Download,
	DropDown,
	Envelope,
	Expand,
	Filter,
	FundraisingRegulator,
	GPS,
	HearingLoop,
	Heart,
	HeartLarge,
	HeartFilled,
	HeartOffset,
	Home,
	Info,
	Likes,
	Location,
	Medical,
	Microchip,
	MyAccountFavourites,
	MyAccountLogout,
	MyAccountManage,
	MyAccountRehoming,
	MyAccountSad,
	LogoWagForDogLovers,
	Measurements,
	OffSite,
	Phone,
	Profile,
	PrimaryLogo,
	PrimaryLogoReduced,
	QuoteMarks,
	Reference,
	ResetPassword,
	RotateDevice,
	Search,
	Sex,
	ShareEmail,
	ShareFacebook,
	ShareWhatsApp,
	ShareX,
	Shedding,
	Shrink,
	SocialFacebook,
	SocialInstagram,
	SocialYoutube,
	Sort,
	Star,
	StepCheck,
	StepDog,
	StepHome,
	StepLiving,
	StepPrivacy,
	StepUser,
	Tag,
	UserLoggedOut,
	UserLoggedIn,
	Video,
	Wheelchair,
};
